// Libs
import React from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

// Comps
import { mobileScreen, screens, colors } from '.'

// For Screens Greater Than Medium Width.
export const forScreensGreaterThanMobileWidth = (
  CSS: FlattenSimpleInterpolation,
) => css`
  @media screen and (min-width: ${screens.mobile}) {
    ${CSS}
  }
`

// Radial Gradient.
export const withRadialGradientBackground = css`
  background: radial-gradient(
    39.71% 38.67% at 50% 61.33%,
    rgba(236, 61, 164, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 100% 900px;
  background-position: 0 700px;
  background-repeat: no-repeat;
`

export const backgroundImage = css`
  position: absolute;
  width: auto;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  object-fit: cover;
`

export const backgroundElement = css`
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
`

export const px_par = (
  containerWidth: number,
  childWidth: number,
  extraPx?: any,
) => {
  return `calc( ( (100% / ${containerWidth}) * ${childWidth} ) + ${
    extraPx || '0px'
  } )`
}

export const px = (pixels) => {
  return `${pixels / 16}rem`
}

export const clearButtonStyles = css`
  /** Not destructive to accessibility */
  padding: 0px;
  border: 0px;
  outline: 0px;
  background: transparent;
  text-align: inherit;
  cursor: pointer;
`

export const MinPageHeight = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;

  /**
    * on mobile keep under items close together so that users 
    * don't have to scroll 1x screen height 
  */
  &.fit-on-mobile {
    ${mobileScreen(css`
      min-height: auto;
    `)}
  }

  &.with-bottom-padding-65 {
    box-sizing: border-box;
    padding-bottom: 65px;

    ${mobileScreen(css`
      padding-bottom: 35px;
    `)}
  }

  &.with-bottom-padding-90-no-mobile {
    box-sizing: border-box;
    padding-bottom: 90px;

    ${mobileScreen(css`
      padding-bottom: 90px;
    `)}
  }
`

export const genericCheckBox = css`
  box-sizing: border-box;
  position: relative;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid #e5e6eb;
  border-radius: 3px;

  /* Prevent getting squished on IOS */
  flex-shrink: 0;
  flex-grow: 0;

  transition: border 0.3s, box-shadow 0.3s;

  &:focus {
    outline: none;
    box-shadow: 0px 0px 7.875px rgba(0, 0, 0, 0.1);
  }

  &:after {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    padding-top: 100%;

    background: url('/icons/icon-checked.svg');
    background-size: contain;
    background-position: center;
    opacity: 0;

    transition: opacity 0.3s;
  }

  &:checked {
    border: 2px solid ${colors.purple};

    &:after {
      opacity: 1;
    }
  }
`
