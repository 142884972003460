// Dependencies.
import styled from 'styled-components'

import { Hyperlink } from './Hyperlink'

// Img.
const Img = styled.img`
  height: 36px;
`

// Props.
interface Props {}

// Logo.
export const Logo: React.FC<Props> = (props) => {
  // ..
  return <Img className="logo" src="/images/klykRegular.svg" />
}
