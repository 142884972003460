import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { UtmParas } from '../types'
import { SESSIONSTORAGE } from '../utils/sessionStorage'
import { amplitudeApi } from '../utils/amplitude'

const amplitude = amplitudeApi
export const useUTMParser = () => {
  const router = useRouter()
  const { query } = router
  const { utm_source, utm_medium, utm_campaign, utm_content, utm_term } = query

  useEffect(() => {
    if (
      (utm_source && utm_source !== '') ||
      (utm_medium && utm_medium !== '') ||
      (utm_campaign && utm_campaign !== '') ||
      (utm_content && utm_content !== '') ||
      (utm_term && utm_term !== '')
    ) {
      const uSource = utm_source as string
      const uMedium = utm_medium as string
      const uCampaign = utm_campaign as string
      const uContent = utm_content as string
      const uTerm = utm_term as string

      const utm: UtmParas = {
        utm_source: uSource ? uSource : '',
        utm_medium: uMedium ? uMedium : '',
        utm_campaign: uCampaign ? uCampaign : '',
        utm_content: uContent ? uContent : '',
        utm_term: uTerm ? uTerm : '',
      }

      SESSIONSTORAGE.setUTMParameters(JSON.stringify(utm))
      amplitude.sendUtm(utm)
    }
  }, [utm_source, utm_medium, utm_campaign, utm_content, utm_term])
}
