import styled from 'styled-components'
import { useContext } from 'react'

import { ToastItem } from './ToastItem'
import { ToastContext } from '../../context'

const Container = styled.div`
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 999999;
  top: 60px;
  right: 0px;
`

export const ToastNotification: React.FC = () => {
  const { toasts } = useContext(ToastContext)
  return (
    <Container>
      {toasts.map((toast) => {
        return <ToastItem key={toast.id} toast={toast}></ToastItem>
      })}
    </Container>
  )
}
