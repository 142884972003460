// Dependencies.
import { AppProps } from 'next/app'
import Head from 'next/head'
import NProgress from 'nprogress'
import Router from 'next/router'
import { ParallaxProvider } from 'react-scroll-parallax'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { appWithTranslation } from 'next-i18next'
import Script from 'next/script'
import { useMemo } from 'react'

import '../styles/global.css'
import { config } from '../utils'
import { NavigationBar } from '../components/NavigationBar/NavigationBar'
import { Provider } from '../context'
import { GlobalStyle } from '../components/GlobalStyle'
import { Footer } from '../components/Footer'
import { useGoogleAnalytics } from '../hooks/google'
import { useFacebookPixel } from '../hooks/facebook'
import { useIntercom } from '../hooks/intercom'
import 'react-datepicker/dist/react-datepicker.css'
import { ToastNotification } from '../components/ToastNotification'
import { useFullStory } from '../hooks/fullstory'
import { useUTMParser } from '../hooks/utmParser'
import { OnLoadScrollTop } from '../components/OnLoadScrollTop'

// Router Events.
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

// Styles
import 'react-alice-carousel/lib/alice-carousel.css'
import { FinishSettingUpYourEventToast } from '../components/FinishSettingUpYourEventToast'

// Props.
type Props = AppProps & { err: any }

// Application.
const Application: React.FC<Props> = (props) => {
  // Props.
  const { Component, pageProps, err } = props

  const stripePromise = useMemo(
    () =>
      loadStripe(
        process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY ||
          'pk_test_51H9mUfEVzgUqYc5Djzg4rz6B8B7u4uUO7Y7WTfGZr7JJJq2DwcK56oQo4cVgWEkxK37NGE7HIl7tuEOjFZn8Ukz400VVHthoFk',
      ),
    [],
  )
  useUTMParser()

  // Use Google Analytics.
  useGoogleAnalytics('UA-178405287-1')

  // Use Facebook Pixel.
  useFacebookPixel()

  useIntercom(true)

  useFullStory()

  // ..
  return (
    <>
      {/* Head. */}
      <Head>
        {/* Favicon */}
        <link rel="shortcut icon" href="/favicon.svg" />

        {/* Viewport. */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charSet="utf-8" />

        {/* SEO. */}

        {/* Open Graph. */}
        <title>{config.name}</title>
        <meta property="og:title" content={config.name} />
        {/* <meta property="og:description" content=".."/> */}
        {/* <meta property="og:image" content="/SEO/default.jpg"/> */}

        {/* Facebook Pixel. */}
        <meta
          name="facebook-domain-verification"
          content="lh2yhmtfvn7ckwlph6btxz6webllcz"
          key="facebook-domain-verification"
        />
        {/* <noscript>
        <img
          height="1"
          src="https://www.facebook.com/tr?id={INSERT_PIXEL_ID_HERE}&ev=PageView&noscript=1"
          style={{display: 'none'}}
          width="1"
        />
      </noscript> */}
        {/* End Facebook Pixel. */}

        <meta
          name="google-site-verification"
          content="DzTXFmzn1RJPyfbQP2Q0gmY-wCYWaGxUxELTpYPUzL0"
        />
      </Head>

      {/* Google Analytics. */}
      <Script
        // eslint-disable-next-line i18next/no-literal-string
        strategy="afterInteractive"
        // eslint-disable-next-line i18next/no-literal-string
        src="https://www.googletagmanager.com/gtag/js?id=UA-165854303-1"
      />

      <OnLoadScrollTop />

      <Provider>
        {/* Parallax Provider */}
        <ParallaxProvider>
          {/* Global Style. */}
          <GlobalStyle />

          {/* Navigation Bar. */}
          <NavigationBar />

          <Elements stripe={stripePromise}>
            {/* Component. */}
            <Component {...pageProps} err={err} />
          </Elements>

          {/* Footer. */}
          <Footer />
          <ToastNotification />

          <FinishSettingUpYourEventToast />
        </ParallaxProvider>
      </Provider>
    </>
  )
}

// Export.
export default appWithTranslation(Application)
