import { useTranslation } from 'next-i18next'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { ToastContext } from '../context'
import { Toast, ToastType } from '../types'
import { useXendit } from '../hooks/useXendit'
import { CTA } from './CTA'
import { usePaymentProvider } from '../hooks/usePaymentProvider'
import { EPaymentProvider } from '../entities'
import { useIsAuthorized } from '../hooks/useIsAuthorized'

const XenditFinishSettingUpYourEventToast: React.FC = () => {
  const { addToast } = useContext(ToastContext)
  const { t } = useTranslation('common')
  const router = useRouter()
  const isAuthorized = useIsAuthorized()

  const { isSuggestionToRedirectToCreateEventRequired, clearRedirectUrl } =
    useXendit()

  const onClose = useCallback(() => {
    clearRedirectUrl()
  }, [clearRedirectUrl])

  const [isShowed, setIsShowed] = useState(false)
  const onClick = useCallback(() => {
    // TODO use redirect here
    router.push('/events/create')
  }, [router])

  useEffect(() => {
    if (
      isAuthorized &&
      !isShowed &&
      isSuggestionToRedirectToCreateEventRequired
    ) {
      setIsShowed(true)
      addToast({
        id: `finish-setting-up-your-event`,
        toastType: ToastType.Info,
        title: t(
          'CreateEventThirdPage1036491157',
          'Finish setting up your event',
        ),
        content: t(
          'onboarding-20220326134046855951408',
          'Your payment set up is completed. Continue setting up your event.',
        ),
        action: (
          <CTA className="purple extra-padding-20" onClick={onClick}>
            {t('Finish creating event', 'Finish creating event')}{' '}
          </CTA>
        ),
        onClose: onClose,
      } as Toast)
    }
  }, [
    addToast,
    isShowed,
    isSuggestionToRedirectToCreateEventRequired,
    onClose,
    t,
    onClick,
  ])

  return null
}

export const FinishSettingUpYourEventToast: React.FC = () => {
  const { provider } = usePaymentProvider()
  if (provider === EPaymentProvider.xendit) {
    return <XenditFinishSettingUpYourEventToast />
  } else {
    return null
  }
}
