// Dependencies.
import styled from 'styled-components'

import { colors } from '../../utils'

// Link.
export const Link = styled.span`
  color: ${colors.offBlack};
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: -0.5px;
`
