// Dependencies.
import axios, { AxiosResponse } from 'axios'

import { LOCALSTORAGE } from '../utils/localStorage'

// Globals.
const NEXT_PUBLIC_BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL
const URLFromPathname = (pathname: string) => {
  // configure API URL & fix extra space on windows CLI
  const origin = NEXT_PUBLIC_BACKEND_URL
    ? `${NEXT_PUBLIC_BACKEND_URL}`.trim()
    : 'http://localhost:8000'
  // const origin = NEXT_PUBLIC_BACKEND_URL || 'http://192.168.0.4:8000'
  return `${origin}${pathname}`
}

// Types.
type DeleteFunc = <T>(
  pathname: string,
  params?: { [key: string]: any },
) => Promise<AxiosResponse<T>>
type GetFunc = <T>(pathname: string) => Promise<AxiosResponse<T>>
type CustomGetFunc = <T>(
  pathname: string,
  token: string,
) => Promise<AxiosResponse<T>>
type PostFunc = <T>(
  pathname: string,
  params: { [key: string]: any },
) => Promise<AxiosResponse<T>>
type PatchFunc = <T>(
  pathname: string,
  params: { [key: string]: any },
) => Promise<AxiosResponse<T>>
type CustomPostFunc = <T>(
  pathname: string,
  params: { [key: string]: any },
  token: string,
) => Promise<AxiosResponse<T>>
type PostFormDataFunc = <T>(
  pathname: string,
  params: { [key: string]: any },
) => Promise<AxiosResponse<T>>
type PutFunc = <T>(
  pathname: string,
  params: { [key: string]: any },
) => Promise<AxiosResponse<T>>
type UnSplashGetFunc = <T>(
  pathname: string,
  config?: { [key: string]: any },
) => Promise<AxiosResponse<T>>

// API.
export class API {
  // Delete.
  static delete: DeleteFunc = async (pathname, params) => {
    const headers = API.deriveHeaders()
    return await axios.delete(URLFromPathname(pathname), {
      headers: headers,
      data: params,
    })
  }

  // Derive Headers.
  static deriveHeaders = (options?: {
    includeToken?: boolean
    customToken?: string
  }) => {
    const headers = { 'Content-Type': 'application/json' }
    if (options?.includeToken !== false) {
      const token = LOCALSTORAGE.getUserToken()
      if (token) headers['Authorization'] = `token ${token}`
      if (options?.customToken)
        headers['Authorization'] = `token ${options?.customToken}`
    }
    return headers
  }

  // Get.
  static get: GetFunc = async (pathname) => {
    const headers = API.deriveHeaders()
    return await axios.get(URLFromPathname(pathname), { headers })
  }

  // Post.
  static post: PostFunc = async (pathname, params) => {
    const headers = API.deriveHeaders()
    return await axios.post(URLFromPathname(pathname), params, { headers })
  }

  static patch: PatchFunc = async (pathname, params) => {
    const headers = API.deriveHeaders()
    return await axios.patch(URLFromPathname(pathname), params, { headers })
  }

  static unAuthPost: PostFunc = async (pathname, params) => {
    const headers = API.deriveHeaders({
      includeToken: false,
    })
    return await axios.post(URLFromPathname(pathname), params, { headers })
  }

  static customTokenGet: CustomGetFunc = async (pathname, customToken) => {
    const headers = API.deriveHeaders({
      customToken: customToken || '',
    })
    return await axios.get(URLFromPathname(pathname), { headers })
  }

  static customTokenPost: CustomPostFunc = async (
    pathname,
    params,
    customToken,
  ) => {
    const headers = API.deriveHeaders({
      customToken: customToken || '',
    })
    return await axios.post(URLFromPathname(pathname), params, { headers })
  }

  static customTokenDelete: CustomPostFunc = async (
    pathname,
    params,
    customToken,
  ) => {
    const headers = API.deriveHeaders({
      customToken: customToken || '',
    })
    return await axios.delete(URLFromPathname(pathname), {
      headers: headers,
      data: params,
    })
  }

  // Post File.
  static postFormData: PostFormDataFunc = async (pathname, params) => {
    const headers = { 'Content-Type': 'multipart/form-data' }
    const token = LOCALSTORAGE.getUserToken()
    if (token) headers['Authorization'] = `token ${token}`
    return await axios.post(URLFromPathname(pathname), params, { headers })
  }

  // PUT
  static put: PutFunc = async (pathname, params) => {
    const headers = API.deriveHeaders()
    return await axios.put(URLFromPathname(pathname), params, { headers })
  }

  // UnSplash GET
  static unSplashGet: UnSplashGetFunc = async (pathname, config) => {
    return await axios.get(pathname, config)
  }
}

// Not in use anywhere yet but may come in handy later
export class SyntheticNetworkError extends Error {
  response: {
    data: Record<string, unknown>
  }

  constructor(value: Record<string, unknown>) {
    super('message')
    this.name = 'SyntheticNetworkError'
    this.response = {
      data: value,
    }
  }
}
