// Contexts.
export { Context as EventsContext } from './Events'
export { Context as UserContext } from './User'
export { Context as CategoriesContext } from './Categories'
export { Context as GroupContext } from './Group'
export { Context as StripeContext } from './Stripe'
export { Context as ToastContext } from './Toast'
export { Context as AnalyticsContext } from './Analytics'

// Providers.
import { Provider as EventsProvider } from './Events'
import { Provider as UserProvider } from './User'
import { Provider as CategoriesProvider } from './Categories'
import { Provider as GroupProvider } from './Group'
import { Provider as StripeProvider } from './Stripe'
import { Provider as ModalsProvider } from './Modals'
import { Provider as ToastProvider } from './Toast'
import { Provider as AnalyticsProvider } from './Analytics'
// export { Provider as AnalyticsProvider} from './Analytics'

// State Resets
export { EmptyEventsList } from './Events'

// Provider.
export const Provider: React.FC = (props) => (
  <>
    <UserProvider>
      <ToastProvider>
        <CategoriesProvider>
          <GroupProvider>
            <EventsProvider>
              <StripeProvider>
                <ModalsProvider>
                  <AnalyticsProvider>{props.children}</AnalyticsProvider>
                </ModalsProvider>
              </StripeProvider>
            </EventsProvider>
          </GroupProvider>
        </CategoriesProvider>
      </ToastProvider>
    </UserProvider>
  </>
)
