import { EventFrequency } from '../../entities'
import { NookEvent } from '../../types'
import { addDailyRecurringDetails } from './addDailyRecurringDetails'
import { addMonthlyRecurringDetails } from './addMonthlyRecurringDetails'
import { addWeeklyRecurringDetails } from './addWeeklyRecurringDetails'
import { addYearlyRecurringDetails } from './addYearlyRecurringDetails'

export function addRecurringDetais<T extends { frequency: EventFrequency }>(
  event: T,
): Partial<NookEvent> {
  const { frequency } = event
  switch (frequency) {
    case EventFrequency.DAILY:
      return addDailyRecurringDetails(event)
    case EventFrequency.WEEKLY:
      return addWeeklyRecurringDetails(event)
    case EventFrequency.MONTHLY:
      return addMonthlyRecurringDetails(event)
    case EventFrequency.YEARLY:
      return addYearlyRecurringDetails(event)
    default:
      return event
  }
}
