// Dependencies.
import styled from 'styled-components'

import { colors } from '../../../utils'

// Div.
const Div = styled.div`
  background-image: url(/images/avatar.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${colors.grey};
  border-radius: 100%;
  height: 40px;
  width: 40px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

// Props.
interface Props {
  image: string | null
}

export const UserPane: React.FC<Props> = (props) => {
  const { image } = props
  // ..
  return <Div className="userPane">{image && <img src={image}></img>}</Div>
}
