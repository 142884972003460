// Dependencies.
import { createContext, useEffect, useState, useCallback } from 'react'

import {
  FetchCategoriesFunc,
  Category,
  Toast,
  AddToastFunc,
  RemoveToastFunc,
  UnsplashImage,
  FetchUnsplashFunc,
  UnsplashResponse,
  FetchUnsplashImageFunc,
} from '../types'
import { API } from '../API'

// Context.
export const Context = createContext({
  // Values.
  categories: [] as Category[],
  // toasts: [] as Toast[],
  unsplashImages: [] as UnsplashImage[],

  // Functions.
  fetchCategories: (async () => ({})) as FetchCategoriesFunc,
  // addToast: (()=>({} as boolean)) as AddToastFunc,
  // removeToast: (()=>({} as boolean)) as RemoveToastFunc,
  searchUnsplashImage: (async () =>
    ({} as UnsplashImage[])) as FetchUnsplashFunc,
  fetchUnSplashImage: (async () => ({} as string)) as FetchUnsplashImageFunc,
})

// Provider.
export const Provider: React.FC = (props) => {
  // Values.
  const [categories, setCategories] = useState<Category[]>([])
  // const [toasts, setToasts] = useState<Toast[]>([])
  //Unsplash
  const [unsplashImages, setUnsplashImages] = useState<UnsplashImage[]>([])
  const values = { categories, unsplashImages }

  // Did Mount.
  useEffect(() => {
    fetchCategories()
  }, [])

  // Fetch Categories.
  const fetchCategories: FetchCategoriesFunc = async () => {
    // Response.
    const response = await API.get<Category[]>('/categories/')

    // Categories.
    const categories = response.data
    setCategories(categories)
    return categories
  }

  // const addToast = useCallback(
  //   toast => {
  //     setToasts(toasts => [
  //       ...toasts,toast
  //     ]);
  //   },
  //   [setToasts]
  // );

  // const removeToast = useCallback(
  //   id => {
  //     setToasts(toasts => toasts.filter(t => t.id !== id));
  //   },
  //   [setToasts]
  // );

  // Unsplash
  const searchUnsplashImage: FetchUnsplashFunc = async (params: string) => {
    return await API.get<UnsplashResponse>(`/unsplash/${params}`)
      .then((res) => {
        const images: UnsplashImage[] = res.data.photos.map<UnsplashImage>(
          (img) => ({
            thumb: img.thumb,
            image: img.regular,
            user: img.user,
            userUrl: img.user_url,
            descr: img.descr,
            download: img.download_location,
          }),
        )

        setUnsplashImages(images)
        return images
      })
      .catch((error) => {
        throw error
      })
  }

  // Retrieve
  const fetchUnSplashImage: FetchUnsplashImageFunc = async (url: string) => {
    return await API.unSplashGet<any>(`${url}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  // Functions.
  const functions = { fetchCategories, fetchUnSplashImage, searchUnsplashImage }

  // Value.
  const value = { ...values, ...functions }

  // ..
  return <Context.Provider value={value}>{props.children}</Context.Provider>
}
