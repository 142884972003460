import { createContext, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useAsyncSetState } from 'use-async-setstate'

const MODAL_SEARCH_STRING = 'modal-with-bg-for-Modals'
export const Context = createContext({
  modalSearchClass: MODAL_SEARCH_STRING,
  areModalsOpen: false,
})

export const Provider: React.FC = (props) => {
  // Global State
  const router = useRouter()

  // Local State
  const [interval, updateInterval] = useAsyncSetState(null)
  const [areModalsOpen, setAreModalsOpen] = useState(false)

  // On frame look to see if modal is open
  // If open blur body and lock scroll
  // If closed then allow scroll and body
  useEffect(() => {
    updateInterval(
      setInterval(() => {
        const modals = document.getElementsByClassName(MODAL_SEARCH_STRING)
        if (modals.length > 0) {
          setAreModalsOpen(true)
        } else {
          setAreModalsOpen(false)
        }
      }, 1000 / 30 / 2),
    )

    // Clean Up
    return clearInterval(interval)
  }, [])

  // On Load and When modal open hide body scroll bar
  useEffect(() => {
    if (document && document.body) {
      // if not SSR
      const nextRoot = document.getElementById('__next')
      // mobile and open hide body scroll
      if (areModalsOpen === true) {
        document.body.style.overflow = 'hidden'
        document.head.style.overflow = 'hidden' // IOS is dub and needs both body and head
        nextRoot.style.filter = 'blur(1px)'
        nextRoot.style.overflow = 'auto'
      } else {
        document.body.style.overflow = 'auto'
        document.head.style.overflow = 'auto' // IOS is dub and needs both body and head
        nextRoot.style.filter = 'none'
        nextRoot.style.overflow = 'unset'
      }
    }
  }, [areModalsOpen])

  // On Page change auto handle reset on page change
  useEffect(() => {
    setAreModalsOpen(false)
  }, [router, router.pathname, router.query, router.asPath])

  const value = {
    modalSearchClass: MODAL_SEARCH_STRING,
    areModalsOpen: areModalsOpen,
  }

  return <Context.Provider value={value}>{props.children}</Context.Provider>
}
