// Dependencies.
import { createContext, useState, useEffect, useCallback } from 'react'
import { useAsyncSetState, useGetState } from 'use-async-setstate'
import { useRouter } from 'next/router'

import {
  FetchCurrentUserFunc,
  LogInFunc,
  LogOutFunc,
  ResetPasswordFunc,
  SignUpFunc,
  User,
  UnAuthZoomFunc,
  UploadProfileImageFunc,
  UpdateProfileDetailFunc,
  UpdateUserDetailParams,
  UpdateUserPasswordFunc,
  UpdateUserPasswordParams,
  DeleteMeFunc,
  ExportDataFunc,
  RecoverPasswordWithEmailFunc,
  GoogleLoginFunc,
  FacebookLoginFunc,
  RetrieveGoogleContactsFunc,
  SocialContact,
  AuthType,
  SendAmplitudeEventParams,
  SendAmplitudeEventFunc,
  UpdateSocialContactsFunc,
  FetchFollowedGroupsFunc,
  Group,
  retrieveTimezonesFunc,
  Timezone,
  OAuthState,
  createOAuthStateFunc,
} from '../types'
import { API } from '../API'
import { LOCALSTORAGE } from '../utils/localStorage'
import { amplitudeApi } from '../utils/amplitude'
import { useAnalyticsEvent } from '../hooks/analyticsEvent'

import { AnalyticsContext } from '.'

// Context.
export const Context = createContext({
  // Values.
  currentUser: null as User,
  followedGroups: [] as Group[],
  socialContacts: [] as SocialContact[],
  timezones: [] as Timezone[],
  loaded: true,

  // Functions.
  deleteAccount: (async () => {}) as DeleteMeFunc,
  exportData: (async () => {}) as ExportDataFunc,
  fetchCurrentUser: (async () => ({})) as FetchCurrentUserFunc,
  fetchFollowedGroups: (async () => ({})) as FetchFollowedGroupsFunc,
  logIn: (async () => {}) as LogInFunc,
  logOut: (() => {}) as LogOutFunc,
  recoverPasswordWithEmail: (async () => {}) as RecoverPasswordWithEmailFunc,
  resetPassword: (async () => {}) as ResetPasswordFunc,
  signUp: (async () => {}) as SignUpFunc,
  uploadProfileImage: (async (params) => ({})) as UploadProfileImageFunc,
  updateProfileDetail: (async (params) => ({})) as UpdateProfileDetailFunc,
  updateUserPassword: (async (params) => ({})) as UpdateUserPasswordFunc,
  loginWithGoogle: (async () => {}) as GoogleLoginFunc,
  loginWithFacebook: (async () => {}) as FacebookLoginFunc,
  retrieveGoogleContacts: (async (
    params,
  ) => ({})) as RetrieveGoogleContactsFunc,
  sendAmplitudeEvent: (async (params) => {}) as SendAmplitudeEventFunc,
  updateSocialContacts: (() => {}) as UpdateSocialContactsFunc,
  unAuthZoom: (() => {}) as UnAuthZoomFunc,
  retrieveTimezones: (async () => ({} as Timezone[])) as retrieveTimezonesFunc,
  createOAuthState: (async () => ({} as OAuthState)) as createOAuthStateFunc,
})

const amplitude = amplitudeApi
// Provider.
export const Provider: React.FC = (props) => {
  // Values.
  /** currentUser is set up to use useAsyncSetState because the UserContext waits for state
   * to update before a tracking event is fired.
   * We use await setCurrentUser(newState) so that we know the state is settled before sending events
   */
  const [currentUser, setCurrentUser] = useAsyncSetState<User>(null)
  const [followedGroups, setFollowedGroups] = useState<Group[]>([])
  const [socialContacts, setSocialContacts] = useState<SocialContact[]>([])
  const [timezones, setTimezones] = useState<Timezone[]>(null)
  const values = { currentUser, socialContacts, followedGroups, timezones }
  const [loaded, setLoaded] = useState(false)

  const analyticsEvent = useAnalyticsEvent()

  // Fetch Current User.
  const fetchCurrentUser: FetchCurrentUserFunc = useCallback(async () => {
    try {
      // Response.
      const response = await API.get<User>('/rest-auth/user/')

      // TODO: COVER CASE WHERE AXIOS TOKEN IS INVALID.

      // User.
      const user = response.data
      if (!user.host_bio) user.host_bio = ''
      if (!user.image) user.image = '/images/avatar.png'
      await setCurrentUser(user)
      setLoaded(true)
      return user as User
    } catch (error) {
      localStorage.clear()
      await setCurrentUser(null)
      setLoaded(true)
    }
  }, [setCurrentUser])

  const retrieveTimezones: retrieveTimezonesFunc = useCallback(async () => {
    if (timezones) {
      return timezones
    }

    const result = await API.get<Timezone[]>('/timezones/')
      .then((res) => {
        setTimezones(res.data)
        return res.data
      })
      .catch((error) => {
        console.log('error retrieve timezones')
      })
    return result as Timezone[]
  }, [timezones])
  // Did Mount.
  useEffect(() => {
    const token = LOCALSTORAGE.getUserToken()
    if (token) {
      fetchCurrentUser()
    } else {
      setLoaded(true)
    }
    retrieveTimezones()
  }, [fetchCurrentUser, retrieveTimezones])

  useEffect(() => {
    if (currentUser) {
      amplitude.setUserId(currentUser.email)
    } else {
      amplitude.setUserId(null)
    }
  }, [currentUser])

  const fetchFollowedGroups: FetchFollowedGroupsFunc = useCallback(async () => {
    const isAuthorized = Boolean(LOCALSTORAGE.getUserToken() || currentUser)
    if (isAuthorized) {
      try {
        // response
        const response = await API.get<Group[]>('/my-following-groups/')
        setFollowedGroups(response.data)
        return response.data
        // groups
      } catch (error) {
        console.log(error)
        throw error
      }
    } else {
      return []
    }
  }, [currentUser])

  // Log In.
  const logIn: LogInFunc = async (params) => {
    // Validate Params.
    if (!params.email) throw new Error('!Email')
    if (!params.password) throw new Error('!Password')

    const response = await API.post<{ key: string }>(
      '/rest-auth/login/',
      params,
    )

    // Key.
    const { key } = response.data
    if (!key) throw new Error('!Key')
    LOCALSTORAGE.setUserToken(key)
    LOCALSTORAGE.setAuthType(AuthType.UsernamePassword)

    // Fetch Current User.
    const user = await fetchCurrentUser()
    if (user) {
      //analyticsEvent.accountSignIn('Email')
    }
  }

  // Log Out.
  const logOut = () => {
    LOCALSTORAGE.clear()
    setCurrentUser(null)
  }

  // Recover Password With Email.
  const recoverPasswordWithEmail: RecoverPasswordWithEmailFunc = async (
    email,
  ) => {
    const url = `${window.location.origin}/password/reset`
    const response = await API.post<{ email: string; url: string }>(
      '/user/password/forget/',
      { email, url },
    )
  }

  // Reset Password.
  const resetPassword: ResetPasswordFunc = async (params) => {
    const { password, token } = params
    const response = await API.post(`/user/password/reset/?token=${token}`, {
      password,
    })
  }

  // Sign Up.
  const signUp: SignUpFunc = async (params) => {
    // console.log(params.time_zone)
    // Validate Params.
    if (!params.email) throw new Error('!Email')
    if (!params.password1) throw new Error('!Password1')
    if (!params.password2) throw new Error('!Password2')

    // Response.
    const response = await API.post<{ key: string }>(
      '/rest-auth/registration/',
      params,
    )

    // Key.
    const { key } = response.data
    if (!key) throw new Error('!Key')

    // Up Convert any express users
    // Not a big deal if it fails here - the user has to
    // have a successful convert on the profile page as well
    // so we don't need to worry if this fails here
    try {
      await API.get('/rest-auth/registration/convert/')
    } catch (e) {
      console.log(e)
    }

    LOCALSTORAGE.setUserToken(key)
    LOCALSTORAGE.setAuthType(AuthType.UsernamePassword)

    // Fetch Current User.
    const user = await fetchCurrentUser()
    if (user) {
      analyticsEvent.signupCompleted('email')
    }
  }

  // Upload Profile Image.
  const uploadProfileImage: UploadProfileImageFunc = async (file: File) => {
    const formData = new FormData()
    formData.append('image', file)

    // Response.
    const response = await API.postFormData<User>('/user/image/', formData)
    const user = response.data
    setCurrentUser(user)
    return user
  }

  // Update Profile Detail.
  const updateProfileDetail: UpdateProfileDetailFunc = async (
    profile: UpdateUserDetailParams,
  ) => {
    // Set Up patch obj
    const request: UpdateUserDetailParams = {}

    if (profile.email) request.email = profile.email
    if (profile.full_name) request.full_name = profile.full_name
    if (profile.nickname) request.nickname = profile.nickname
    if (profile.time_zone) request.time_zone = profile.time_zone
    if (profile.host_bio) request.host_bio = profile.host_bio

    // Run call async with no call catch
    // Not entirely sure why this is not exposing the errors
    // but it was that way when I found it
    try {
      const res = await API.put<User>('/rest-auth/user/', request)
      if (res.data && res.data.id) {
        await setCurrentUser(res.data)
        return true
      }
    } catch (e) {
      return false
    }
    return false
  }

  // Update Profile Password.
  const updateUserPassword: UpdateUserPasswordFunc = async (
    password: UpdateUserPasswordParams,
  ) => {
    return await API.post('/rest-auth/password/change/', password)
      .then((res) => {
        if (res.data) {
          return ''
        }
      })
      .catch((error) => {
        throw error
      })
  }

  // Delete Account
  const deleteAccount: DeleteMeFunc = async () => {
    return await API.delete('/user/destroyaccount/')
      .then((res) => {
        if (res.status === 204) {
          logOut()
        }
      })
      .catch((error) => {
        throw error
      })
  }

  // Export Data
  const exportData: ExportDataFunc = async () => {
    return await API.get('/user/export_data/').then((res) => {
      if (res.status === 200) {
        return
      }
    })
  }

  // UnAuthorize Zoom
  const unAuthZoom: UnAuthZoomFunc = async () => {
    return await API.delete('/user/clear-zoom-information/')
      .then((res) => {
        if (res.status === 204) {
          fetchCurrentUser()
        }
      })
      .catch((error) => {
        throw error
      })
  }

  const loginWithGoogle: GoogleLoginFunc = async (params) => {
    try {
      const response = await API.post<{ key: string }>(
        '/rest-auth/google/',
        params,
      )

      // Key.
      const { key } = response.data
      if (!key) throw new Error('!Key')
      LOCALSTORAGE.setUserToken(key)
      LOCALSTORAGE.setAuthType(AuthType.Google)

      // Fetch Current User.
      const user = await fetchCurrentUser()
      if (user) {
        analyticsEvent.signupCompleted('social')
        //amplitude.setUserType('social')
        //analyticsEvent.accountSignIn('Google')
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data)
      }
    }
  }

  const loginWithFacebook: FacebookLoginFunc = async (params) => {
    try {
      // Repsonse
      const response = await API.post<{ key: string }>(
        '/rest-auth/facebook/',
        params,
      )

      // Key.
      const { key } = response.data
      if (!key) throw new Error('!Key')
      LOCALSTORAGE.setUserToken(key)
      LOCALSTORAGE.setAuthType(AuthType.Facebook)

      // Fetch Current User.
      const user = await fetchCurrentUser()
      if (user) {
        analyticsEvent.signupCompleted('social')
        //amplitude.setUserType('social')
        //analyticsEvent.accountSignIn('Facebook')
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data)
      }
    }
  }

  const retrieveGoogleContacts = async (params) => {
    const { peopleApi, current, pageToken } = params

    try {
      const {
        result: { connections, nextPageToken, totalItems },
      } = await peopleApi.connections.list({
        resourceName: 'people/me',
        pageSize: 200,
        personFields: 'emailAddresses,names',
        ...(pageToken ? { pageToken } : {}),
      })
      // merge contacts from this request with data from your previous requests

      const contacts = [...current, ...(connections ? connections : [])]

      if (nextPageToken && contacts.length < totalItems) {
        return retrieveGoogleContacts({
          peopleApi,
          current: contacts,
          pageToken: nextPageToken,
        })
      }

      const scList: SocialContact[] = []
      contacts.forEach((person: gapi.client.people.Person) => {
        if (person.names) {
          const primary = person.names.find(
            (n) => n.metadata && n.metadata.primary === true,
          )
          if (primary) {
            if (person.emailAddresses) {
              person.emailAddresses.forEach(
                (email: gapi.client.people.EmailAddress) => {
                  if (email.value) {
                    const found = scList.some((el) => el.email === email.value)
                    if (!found) {
                      scList.push({
                        name: primary.displayName,
                        email: email.value,
                      })
                    }
                  }
                },
              )
            }
          }
        }
      })
      return scList.sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
    } catch (error) {
      console.log('error retrieveGoogleContacts: ' + JSON.stringify(error))
      return Promise.reject(error)
    }
  }

  const sendAmplitudeEvent: SendAmplitudeEventFunc = async (
    params: SendAmplitudeEventParams,
  ) => {
    API.post('/amplitude/events', params).catch((error) => {
      console.log('error sending amplitude event')
    })
  }

  const updateSocialContacts: UpdateSocialContactsFunc = (
    sc: SocialContact[],
  ) => {
    if (sc) setSocialContacts(sc)
  }

  // Create OAuth State.
  const createOAuthState: createOAuthStateFunc = async (
    redirectUri: string,
  ) => {
    // Validate Params.
    if (!redirectUri) throw new Error('redirectUri can not be empty')

    try {
      const response = await API.post<OAuthState>('/oauth-state/', {
        redirect_uri: redirectUri,
      })
      return response.data
    } catch (error) {
      console.log('error create OAuthState')
    }
    return null
  }

  // Functions.
  const functions = {
    fetchCurrentUser,
    logIn,
    logOut,
    unAuthZoom,
    recoverPasswordWithEmail,
    resetPassword,
    signUp,
    uploadProfileImage,
    updateProfileDetail,
    updateUserPassword,
    deleteAccount,
    exportData,
    loginWithGoogle,
    loginWithFacebook,
    retrieveGoogleContacts,
    sendAmplitudeEvent,
    updateSocialContacts,
    fetchFollowedGroups,
    retrieveTimezones,
    createOAuthState,
    loaded,
  }

  // Value.
  const value = { ...values, ...functions }

  // ..
  return <Context.Provider value={value}>{props.children}</Context.Provider>
}
