import { useTranslation } from 'next-i18next'
// Dependencies.
import styled, { css } from 'styled-components'

import { P } from '../P'
import { Hyperlink } from '../Hyperlink'
import { colors, forScreensGreaterThanMobileWidth } from '../../utils'

// Div.
const Div = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  white-space: nowrap;

  p {
    margin-right: 15px;
  }

  .social img {
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
    width: 32px;
  }

  .social + .social {
    margin-left: 20px;
  }

  .followAndLikeUs {
    margin-bottom: 20px;
  }

  /* Desktop. */
  ${forScreensGreaterThanMobileWidth(css`
    flex-direction: row;
    margin: 0;

    .followAndLikeUs {
      margin: 0;
      margin-right: 20px;
    }
  `)}
`

// Socials.
export const Socials: React.FC = () => {
  const { t } = useTranslation('common')

  // Social Icons.
  const socialIcons = [
    {
      href: 'https://www.facebook.com/Klyk-111020447283636/',
      imageURL: '/icons/facebook.png',
    }, // Facebook.
    { href: 'https://twitter.com/ourklyk', imageURL: '/icons/twitter.png' }, // Twitter.
    {
      href: 'https://www.instagram.com/ourklyk/',
      imageURL: '/icons/instagram.png',
    }, // Instagram.
    {
      href: 'https://www.linkedin.com/company/ourklyk/',
      imageURL: '/icons/linkedIn.png',
    }, // LinkedIn.
  ]

  // ..
  return (
    <Div>
      {/* Follow & Like. */}
      <P className="followAndLikeUs detail" style={{ color: colors.grey }}>
        {t(`Follow and like us:`, `Follow and like us:`)}
      </P>

      {/* Social Icons. */}
      <div>
        {socialIcons.map(({ href, imageURL }) => (
          <Hyperlink className="social" href={href} key={href}>
            <img src={imageURL} />
          </Hyperlink>
        ))}
      </div>
    </Div>
  )
}
