import { useTranslation } from 'next-i18next'
// Dependencies.
import { useContext } from 'react'
import styled, { css } from 'styled-components'
import { useRouter } from 'next/router'

import { UserContext } from '../context'
import { CTA } from './CTA'
import { Hyperlink } from './Hyperlink'
import { forScreensGreaterThanMobileWidth } from '../utils/styles'
import { useAnalyticsEvent } from '../hooks/analyticsEvent'

const StyledHyperlink = styled(Hyperlink)`
  ${forScreensGreaterThanMobileWidth(css`
    margin-right: 20px;
    margin-left: 37px;
  `)}
`

// Props.
interface Props {
  className?: string
  closeNav?: () => void
}

// Sign Up CTA.
export const MyEventsCTA: React.FC<Props> = ({ className, closeNav }) => {
  const { t } = useTranslation('common')

  // Router.
  const { pathname } = useRouter()

  const analyticsEvent = useAnalyticsEvent()

  const { currentUser } = useContext(UserContext)
  // console.log("PATHNAME", pathname)

  // Determine CTA class
  const ctaClass =
    pathname === '/'
      ? 'outline-dark myEventsButton'
      : 'outline-light myEventsButton'

  const onHyperLinkClicked = (evt) => {
    if (!currentUser) analyticsEvent.signupLinkClicked('header-nav')
    if (closeNav) closeNav()
  }

  // ..
  return (
    <StyledHyperlink
      onClick={onHyperLinkClicked}
      href={currentUser ? '/myevents' : '/signup'}
      className={className}>
      <CTA className={ctaClass}>{t(`MyEventsCTA2028308519`, `My Events`)}</CTA>
    </StyledHyperlink>
  )
}
