// Libs
import React, { ReactNodeArray, useEffect } from 'react'
import { useRouter } from 'next/router'

// Defs
interface Props {
  children?: string | React.ReactNode | ReactNodeArray
}

export const OnLoadScrollTop: React.FC<Props> = ({ children }) => {
  // Global State
  const router = useRouter()
  const { pathname, asPath } = router

  // on page change
  useEffect(() => {
    // console.warn('@OnLoadScrollTop->router', router)
    //  Make sure not to move the page if there is a anchor being run
    if (asPath.includes('#') == false) {
      // Re-set the page's scroll
      handleScrollToTop({
        onFailMessage:
          '@OnLoadScrollTop->handleScrollToTop failed to move page to top',
      })
    }
  }, [pathname, asPath])

  // ..
  return <>{children || null}</>
}

export const handleScrollToTop = (params?: { onFailMessage?: string }) => {
  // Params
  const { onFailMessage } = params || {
    onFailMessage:
      '@handleScrollToTop failed to move page to top !Set the Error message!',
  }

  // Re-set the page's scroll
  try {
    document.getElementsByTagName('html')[0].style.scrollBehavior = 'auto'
    document.getElementsByTagName('body')[0].style.scrollBehavior = 'auto'
    document.body.scrollTop = document.documentElement.scrollTop = 0
    document.getElementsByTagName('html')[0].style.scrollBehavior = 'smooth'
    document.getElementsByTagName('body')[0].style.scrollBehavior = 'smooth'
  } catch (e) {
    document.body.scrollTop = document.documentElement.scrollTop = 0
    console.warn(onFailMessage, e)
  }
}
