import { useTranslation } from 'next-i18next'
// Dependencies.
import styled, { css } from 'styled-components'
import { useEffect, useMemo, useState, useContext } from 'react'
import { useRouter } from 'next/router'

import { P } from './P'
import { colors, forScreensGreaterThanMobileWidth, icons } from '../utils'
import { UserContext } from '../context'

// Div.
const Div = styled.div`
  & .container {
    width: 100%;
    height: 100%;
    display: flex;
    box-sizing: border-box;

    & .contentContainer {
      width: 80%;
      max-width: 1018px;
      margin: auto;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    & .crossContainer {
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 50%;
      cursor: pointer;
    }

    & .crossImage {
      width: 22px;
      height: 22px;
    }

    & .discover {
      color: ${colors.white};
      ${forScreensGreaterThanMobileWidth`
        text-align: center;
      `}
    }
  }
`

// Props.
interface Props {}

// Information Banner
export const InformationBanner: React.FC<Props> = (props) => {
  const { t } = useTranslation('common')

  // State
  const [showBanner, setShowBanner] = useState(true)

  const router = useRouter()
  const { pathname } = router

  const getContent = useMemo(() => {
    if (pathname === '/discover') {
      return (
        <P className="detail discover">
          <b>{t(`Build it with us!`, `Build it with us!`)}</b>
          {t(
            `InformationBanner1169196986`,
            ` Thank you for joining us on the Klyk journey. As one of our pioneers, we ask you to help us build this new online community for people sharing online experiences. Please jump in, make yourself comfortable and organise some events (paid or otherwise) to see what it's all about.`,
          )}
        </P>
      )
    }
    return null
  }, [pathname])

  const getColor = useMemo(() => {
    if (pathname === '/discover') {
      return colors.blue
    }
    return colors.purple
  }, [pathname])

  useEffect(() => {
    setShowBanner(true)
  }, [pathname])

  const closeBanner = (ev: React.MouseEvent<HTMLDivElement>) => {
    setShowBanner(false)
  }

  const shouldShow = useMemo(() => {
    if (showBanner) {
      if (pathname === '/discover') {
        return true
      }
    }
    return false
  }, [showBanner, pathname])

  if (!getContent || !shouldShow) return null

  // ..
  return (
    <Div>
      <div className="container">
        <div className="contentContainer">{getContent}</div>
        <div className="crossContainer" onClick={closeBanner}>
          <img src={icons.closeGrey} className="crossImage" />
        </div>
      </div>
      <style jsx>{`
        .container {
          background: ${getColor};
        }
      `}</style>
    </Div>
  )
}
