// Dependencies.
import { css, FlattenSimpleInterpolation } from 'styled-components'
import { ParsedUrlQuery } from 'querystring'
import moment from 'moment'

import { NookEvent } from '../types'

// Colors.
export const colors = {
  blue: '#2F80ED',
  black: '#000000',
  green: '#6FCF97',
  green1: '#219653',
  green2: '#27AE60',
  green3: '#48C97E',
  seaGreen: 'rgba(33, 150, 83, .1)',
  limeGreen: '#27AE60',
  grey: '#A6A6A6',
  gray1: '#333333',
  gray36: '#5c5c5c',
  lightGray: '#E5E6EB',
  lightGrayishBlue: '#F1F2F5',
  veryDarkGray: `#4F4F4F`,
  veryDarkGrayOpacity10: 'rgba(79, 79, 79, .1)',
  veryDarkGrayOpacity20: 'rgba(79, 79, 79, .2)',
  veryDarkGrayOpacity50: 'rgba(79, 79, 79, .5)',
  darkGray: `#828282`,
  veryDarkGrayishBlue: '#2C2D35',
  veryDarkGrayishBlueOpacity30: 'rgba(44, 45, 53, .3)',
  veryDarkGrayishBlueOpacity80: 'rgba(44, 45, 53, .8)',

  offBlack: '#2B2B2B',
  purple: '#6D4BEA',
  purpleOpacity20: 'rgba(109, 75, 234, .2)',
  purpleOpacity40: 'rgba(109, 75, 234, .4)',
  red: '#EB5757',
  redOpacity20: 'rgba(235, 87, 87, .2)',
  redOpacity60: 'rgba(235, 87, 87, .6)',
  washedPurple: '#F0EDFD',
  washedPurpleOpacity40: 'rgba(240, 237, 253, .4)',
  white: '#FFFFFF',
  whiteWithOpacity80: 'rgba(255, 255, 255, .8)',
  backgroundWhite: '#FAFAFE',
  orange: '#EE663A',
  paleOrange: '#FDF0E3',
  pink: '#D48EEE',
  palePink: '#F4EAFF',
  errorRed: 'rgba(235, 87, 87, .6)',
  helperTextGray: 'rgba(44, 45, 53, .6)',
  dashBorderColor: '',
  vibrantOrange: '#FDF0E3',
  vibrantBlue: '#DEF0FB',
  vibrantGreen: 'rgba(228, 246, 235, 1)',
}

// Copy Text To Clipboard.
export const copyTextToClipboard = async (text: string) => {
  // Exec Copy.
  if (!navigator.clipboard) {
    const textArea = document.createElement('textarea')
    textArea.value = text
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      document.execCommand('copy')
    } catch (err) {
      console.log(err)
    }

    document.body.removeChild(textArea)
    return
  }

  // Navigator - Clipboard.
  await navigator.clipboard.writeText(text)
}

// Config.
export const config = {
  name: 'Klyk',
  intercomSupportLink: 'https://intercom.help/ourklyk/',
}

export const fileFromDataURLWithName = (dataURL: string, filename: string) => {
  const data = dataURL.split(',')
  const type = data[0].match(/:(.*?);/)[1] // Mime.
  const bufferString = atob(data[1])
  let n = bufferString.length
  const buffer = new Uint8Array(n)

  while (n--) {
    buffer[n] = bufferString.charCodeAt(n)
  }

  return new File([buffer], filename, { type })
}

// Dimensions.
export const dimensions = {
  navigationBarHeightDesktop: '100px',
}

// Font Weights.
export const fontWeights = {
  /**
    N.B. 
    The current Figma file communicates semi-bold using `font-weight: 600;` and regular bold using `font-weight: bold;`.
  */
  bold: 700,
  medium: 500,
  normal: 'normal',
  semiBold: 600,
}

// For Screens Greater Than Medium Width.
export const forScreensGreaterThanMobileWidth = (
  CSS: FlattenSimpleInterpolation,
) => css`
  @media screen and (min-width: ${screens.mobile}) {
    ${CSS}
  }
`

export const mobileScreen = (CSS: FlattenSimpleInterpolation) => css`
  @media (max-width: ${screens.mobile}) {
    ${CSS}
  }
`

// Icons.
export const icons = {
  arrowDown: '/icons/icon-arrow-down.png',
  arrowRight: '/icons/icon-arrow-right.png',
  calendar: '/icons/calendar.svg',
  close: '/icons/icon-close.png',
  closeGrey: '/icons/icon-close(Grey).png',
  duration: '/icons/icon-duration.png',
  emptyStar: '/icons/emptyStar.png',
  extraterrestrialAlien1: '/icons/extraterrestrial-alien1.png',
  extraterrestrialAlien2: '/icons/extraterrestrial-alien2.png',
  fadedCalendar: '/icons/fadedCalendar.png',
  fadedClock: '/icons/fadedClock.png',
  fadedEye: '/icons/fadedEye.svg',
  fadedGlobe: '/icons/fadedGlobe.png',
  fadedPeople: '/icons/fadedPeople.png',
  fadedTicket: '/icons/fadedTicket.png',
  filledStar: '/icons/filledStar.png',
  group: '/icons/icon-group.png',
  location: '/icons/location.png',
  link: '/icons/link.png',
  photo: '/icons/icon-photo.png',
  plusInsideCircle: '/icons/plusInsideCircle.png',
  private: '/icons/icon-private.png',
  public: '/icons/icon-public.png',
  share: '/icons/icon-share.png',
  smilingFaceWithHeartShapedEyes1:
    '/icons/smiling-face-with-heart-shaped-eyes1.png',
  smilingFaceWithHeartShapedEyes2:
    '/icons/smiling-face-with-heart-shaped-eyes2.png',
  thumbsUpSign1: '/icons/thumbs-up-sign1.png',
  thumbsUpSign2: '/icons/thumbs-up-sign2.png',
  tickInsideCircle: '/icons/tickInsideCircle.png',
  video: '/icons/icon-video.png',
  zoom: '/icons/zoom.png',
  add: '/icons/icon-add.png',
  timezone: '/icons/icon-timezone.png',
  appleLogo: '/icons/appleLogo.png',
  windowsLogo: '/icons/windowsLogo.png',
  yahooLogo: '/icons/yahooLogo.png',
  googleLogo: '/icons/googleLogo.png',
  contacts: '/icons/icon-contacts.png',
  flyingMoney: '/icons/icon-flyingMoney.png',
  exclamationMark: '/icons/icon-exclamationMark.png',
  externalLink: '/icons/icon-externalLink.png',
  purpleGlobe: '/icons/icon-purpleGlobe.png',
  blackGlobe: '/icons/icon-blackGlobe.png',
  ticket: '/icons/icon-ticket.png',
  eyeOpen: '/icons/icon-eyeOpen.svg',
  eyeClose: '/icons/icon-eyeClose.svg',
  checkCircle: '/icons/icon-checkCircle.png',
  checkTick: '/icons/icon-checkCircle.png',
  checkTickPurple: '/icons/icon-checkTick-purple.svg',
  check: '/icons/icon-check.svg',
  xCircle: '/icons/icon-xCircle.png',
  xCircleDarkGrey: '/icons/icon-xCircle-dark-grey.svg',
  fadedMail: '/icons/icon-fadedMail.png',
  solidMail: '/icons/icon-solidMail.png',
  usaFlagEmoji: '/icons/us-flag.svg',
  infoCircle: '/icons/icon-info.svg',
  iconCheckTickWithCirclePurple: '/icons/icon-checkTickWithCircle-purple.svg',
  iconCheckTickWithCircleGray: '/icons/icon-checkTickWithCircle-gray.svg',
  copy: '/icons/icon-copy.svg',
}

// Screens.
export const screens = {
  mobile: '800px',
  mobileAsNumber: 800,
}

// String From Query.
export const stringFromQuery = (query: ParsedUrlQuery) => {
  if (typeof window === 'undefined') return ''

  // Entries.
  const entries = Object.entries(query)

  // !Entries.
  if (!entries.length) return ''

  // Query As String.
  return entries.reduce((acc, [key, value], index) => {
    const param = `${key || 'key'}=${value || 'value'}`
    const ampersand = index < entries.length - 1 ? '&' : ''
    return `${acc}${param}${ampersand}`
  }, '?')
}

// Generate UUID
export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const zeroPad = (num, places) => String(num).padStart(places, '0')
export const tooltipHeader = (content: string) =>
  `<p style="font-weight: 600;font-size:14px;line-height:110%;letter-spacing:-0.5px;">${content}</p>`
export const tooltipContent = (content: string) =>
  `<p style="font-weight:normal;font-size:12px;line-height:110%;letter-spacing:-0.5px;">${content}</p>`

// Check if event is about to start,
// true for 15 minutes before +
// the duration of the event
export const eventIsAboutToStart = (event: Partial<NookEvent>) => {
  const { start_date, duration, is_user_going } = event
  const newStartTime = moment(start_date)
  const currentTime = moment()
  const diff = newStartTime.diff(currentTime)
  const newDuration = duration * 60000

  if (diff < 900000 && diff > -newDuration && is_user_going) return true
  else return false
}

export const eventHasFreeSpaces = (event: Partial<NookEvent>) => {
  const { is_full_booked } = event
  if (is_full_booked === false) {
    return true
  }
  return false
}

export const prependHttpsToUrl = (url) => {
  // in the case this is an empty string do not add it
  if (url == '') {
    return url
    // In the case we have some text
  } else {
    return !/^https?:\/\//i.test(url) ? `https://${url}` : url
  }
}

// some layout helpers
export function show(bool: boolean) {
  return bool
}

export function showFinally(bool: boolean) {
  return bool
}

export function activeWhen(value: any, equalTo: any) {
  return value === equalTo ? 'active' : ''
}

export function when(value: any, equalTo: any) {
  return value === equalTo ? true : false
}

export function touchWhen(value: any, equalTo: any) {
  return value === equalTo ? 'touch' : ''
}

export function cursorWhen(value: any, equalTo: any) {
  return value === equalTo ? 'cursor' : ''
}

export function inactiveWhen(value: any, equalTo: any) {
  return value === equalTo ? 'inactive' : ''
}

// Alow for easy RGBA HEX colours
export function alphaHex(hexColor: string, opacity: number) {
  let alpha = Math.floor(opacity * 255)
    .toString(16)
    .toUpperCase()
  // Pad smaller numbers
  if (alpha.length < 2) {
    alpha = `0${alpha}`
  }
  // Make sure numbers are smaller than 1
  if (opacity > 1) {
    alpha = 'FF'
  }
  return `${hexColor}${alpha}`
}

export const collectionToArray = (list: HTMLCollection) => {
  if (list !== undefined && list !== null && list.length > 0) {
    return Array.from(list)
  }
  return []
}

export const safelyAddEventListner = (
  dom: Element | undefined,
  eventType: string,
  func: any,
  options?: boolean | AddEventListenerOptions,
) => {
  if (dom) {
    dom.addEventListener(eventType, func, options)
    return true
  }
  console.warn('@safelyAddEventListner->failed with', dom)
  return false
}

export const safelyRemoveEventListner = (
  dom: Element | undefined,
  eventType: string,
  func: any,
  options?: boolean | EventListenerOptions,
) => {
  if (dom) {
    dom.removeEventListener(eventType, func, options)
    return true
  }
  console.warn('@removeEventListener->failed', dom)
  return false
}

export const stringContainsLineBreak = (str: string) => {
  // console.log('libe break?', (str.includes('\r\n') || str.includes('\r') || str.includes('\n')  ))
  if (str.includes('\r\n') || str.includes('\r') || str.includes('\n')) {
    return true
  } else {
    return false
  }
}

export const delay = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function stringToJson(str) {
  try {
    return JSON.parse(str)
  } catch (e) {
    console.warn('@stringToJson failed to parse string: ', str, e)
    return undefined
  }
}

export * from './event-utils'
