import { useCallback } from 'react'

import {
  AnalyticsEventHook,
  NookEvent,
  Group,
  Invite,
  AnalyticsEventShareEvent,
} from '../types'
import { amplitudeApi } from '../utils/amplitude'
import { useFacebookPixel } from './facebook'

const amplitude = amplitudeApi

// Use Analytics Event.
export const useAnalyticsEvent: AnalyticsEventHook = () => {
  const facebook = useFacebookPixel()

  const eventPublished = useCallback((nookEvent: NookEvent) => {
    const event = {
      'Private event': nookEvent.event_type === 1,
      'Author type': nookEvent.group_id ? 'group' : 'personal',
      'Call link type': nookEvent.video_host_type === 0 ? 'zoom' : 'custom',
      Invitations:
        nookEvent.invites && nookEvent.invites.length > 0
          ? nookEvent.invites.map((invite) => {
              return invite.email
            })
          : [],
      'Event category': nookEvent.category_id,
      Name: nookEvent.title,
      URL: nookEvent.event_url,
    }
    amplitude.sendEvent({
      name: 'Create Event - New event published',
      props: event,
    })
  }, [])
  const signupLinkClicked = (name: string) => {
    amplitude.sendEvent({
      name: 'Signup - Clicks Register',
      props: { source: name },
    })
  }

  const signupCompleted = (name: string) => {
    amplitude.setUserType(name)
    amplitude.sendEvent({ name: 'Signup - Account created' })
  }

  const profileUpdateButtonClicked = () => {
    amplitude.sendEvent({ name: 'Signup - Profile setup' })
    facebook.track({ name: 'Subscription' })
  }

  const onboardingPromptToCreateFirstEvent = () => {
    amplitude.sendEvent({ name: 'Onboarding - Prompt to create first event' })
  }

  const onboardingPromptToLearnGroup = () => {
    amplitude.sendEvent({
      name: 'Onboarding - Prompt to learn more about groups',
    })
  }

  const myEventsVisitsPage = () => {
    amplitude.sendEvent({ name: 'My Events - visits page' })
  }

  const createEventClicksCTA = (name: string) => {
    amplitude.sendEvent({
      name: 'Create event - Clicks CTA',
      props: { source: name },
    })
  }

  const updateEventButtonClicked = () => {
    amplitude.sendEvent({ name: 'Manage Event - Update event details' })
  }

  const manageEventInvitationsSent = (invites: Invite[]) => {
    const invitations = {
      Invitations:
        invites && invites.length > 0
          ? invites.map((invite) => {
              return invite.email
            })
          : [],
    }
    amplitude.sendEvent({
      name: 'Manage Event - Invitations sent',
      props: invitations,
    })
  }

  const manageEventRemoveParticipant = () => {
    amplitude.sendEvent({ name: 'Manage event - Remove participant' })
  }

  const manageEventCancelEvent = () => {
    amplitude.sendEvent({ name: 'Manage event - Cancel event' })
  }

  const groupExpressionOfInterest = (name: string) => {
    amplitude.sendEvent({
      name: 'Groups - Expression of interest',
      props: { source: name },
    })
  }

  const groupExpressionOfInterestForStripe = (name: string) => {
    amplitude.sendEvent({
      name: 'Groups - Expression of interest for Stripe',
      props: { source: name },
    })
  }

  const groupCreateGroupAccount = (group: Group) => {
    amplitude.sendEvent({
      name: 'Groups - Create group account',
      props: { Public: group.group_type === 0 },
    })
  }

  const groupCreateStripeAccount = () => {
    amplitude.sendEvent({ name: 'Groups - Create Stripe account' })
  }

  const discoverDiscoverHomepage = () => {
    amplitude.sendEvent({ name: 'Discover - Discover homepage' })
    facebook.track({ name: 'Search' })
  }

  const discoverFilterByCategory = (name: string) => {
    amplitude.sendEvent({
      name: 'Discover - Filter by category',
      props: { 'Category type': name },
    })
  }

  const discoverViewEvent = (name: string) => {
    amplitude.sendEvent({
      name: 'Discover - View Event',
      props: { source: name },
    })
  }

  const discoverViewGroup = (name: string) => {
    amplitude.sendEvent({
      name: 'Discover - View group',
      props: { source: name },
    })
  }

  const discoverFollowGroup = (name: string) => {
    amplitude.sendEvent({
      name: 'Discover - Follow group',
      props: { 'Name of group': name, follow: true },
    })
  }

  const discoverUnFollowGroup = (name: string) => {
    amplitude.sendEvent({
      name: 'Discover - Follow group',
      props: { 'Name of group': name, follow: false },
    })
  }

  const eventsRegisterForEvent = (name: string) => {
    amplitude.sendEvent({
      name: 'Events - Register for event',
      props: {
        'Registration type': 'Attending',
        'User type': 'hybrid',
      },
    })
    // Only send AddToCart for paid event
    if (name === '1') {
      facebook.track({ name: 'AddToCart' })
    }
  }

  const expressIntentToAttend = () => {
    amplitude.sendEvent({ name: 'Express Events - Intent to attend' })
  }

  const expressEventsRegisterForEvent = (
    paidType: string,
    userEmail: string,
  ) => {
    amplitude.setUserId(userEmail)
    amplitude.sendEvent({
      name: 'Express Events - Register for event',
      props: { 'Registration type': 'Attending' },
    })
    amplitude.sendEvent({
      name: 'Events - Register for event',
      props: {
        'Registration type': 'Attending',
        'User type': 'express',
      },
    })
    if (paidType === '1') {
      facebook.track({ name: 'AddToCart' })
    }
  }

  const expressParticipantFirstTimeCreated = (userEmail: string) => {
    amplitude.setUserId(userEmail)
    amplitude.sendEvent({ name: 'Express - Participant created' })
  }

  const eventsDeRegisterForEvent = () => {
    amplitude.sendEvent({
      name: 'Events - Register for event',
      props: { 'Registration type': 'Not Attending' },
    })
  }

  const eventsAddToCalendar = (name: string) => {
    amplitude.sendEvent({
      name: 'Events - Add to calendar',
      props: { source: name },
    })
  }

  const eventsShareEvent = (share: AnalyticsEventShareEvent) => {
    amplitude.sendEvent({
      name: 'Events - Share event',
      props: {
        Event: share.eventId,
        Source: share.source,
        'Type of share': share.type,
      },
    })
  }

  const accountDeleted = () => {
    amplitude.sendEvent({ name: 'Account - Deleted' })
  }

  const accountAuthorizeZoom = (name: string) => {
    amplitude.sendEvent({
      name: 'Account - Authorize Zoom',
      props: { source: name },
    })
  }

  const accountSignIn = (name: string) => {
    amplitude.sendEvent({ name: 'Account - Sign in', props: { source: name } })
  }

  const fbAddPaymentInfo = () => {
    facebook.track({ name: 'AddPaymentInfo' })
  }

  const fbContact = (name: string) => {
    //facebook.track({name: 'Contact', props:{'emailAddress':name}})
  }

  const fbCustomizeProduct = (name: string) => {
    //facebook.track({name: 'CustomizeProduct', props:{'value':name}})
  }

  const fbInitiateCheckOut = () => {
    //facebook.track({name: 'InitiateCheckout'})
  }

  const fbPurchase = (name: string) => {
    facebook.track({
      name: 'Purchase',
      props: { value: name, currency: 'USD' },
    })
  }

  const fbViewContent = useCallback(() => {
    facebook.track({ name: 'ViewContent' })
  }, [])
  const fbCompleteRegistration = (name: string) => {
    facebook.track({
      name: 'CompleteRegistration',
      props: { content_name: name },
    })
  }

  const fbLead = () => {
    facebook.track({ name: 'Lead' })
  }

  const fbSubmitApplication = () => {
    facebook.track({ name: 'SubmitApplication' })
  }

  const websiteVisit = (name: string) => {
    amplitude.sendEvent({ name: 'Website Visit', props: { source: name } })
  }

  const functions = {
    eventPublished,
    signupLinkClicked,
    signupCompleted,
    onboardingPromptToCreateFirstEvent,
    onboardingPromptToLearnGroup,
    profileUpdateButtonClicked,
    updateEventButtonClicked,
    groupCreateGroupAccount,
    myEventsVisitsPage,
    groupExpressionOfInterest,
    groupExpressionOfInterestForStripe,
    discoverViewEvent,
    eventsRegisterForEvent,
    eventsAddToCalendar,
    createEventClicksCTA,
    manageEventInvitationsSent,
    manageEventRemoveParticipant,
    manageEventCancelEvent,
    groupCreateStripeAccount,
    discoverDiscoverHomepage,
    discoverFilterByCategory,
    discoverViewGroup,
    discoverFollowGroup,
    discoverUnFollowGroup,
    eventsShareEvent,
    accountDeleted,
    accountAuthorizeZoom,
    accountSignIn,
    fbAddPaymentInfo,
    eventsDeRegisterForEvent,
    fbContact,
    fbCustomizeProduct,
    fbInitiateCheckOut,
    fbPurchase,
    fbViewContent,
    fbLead,
    fbCompleteRegistration,
    fbSubmitApplication,
    websiteVisit,
    express: {
      expressIntentToAttend: expressIntentToAttend,
      expressEventsRegisterForEvent: expressEventsRegisterForEvent,
      expressParticipantFirstTimeCreated: expressParticipantFirstTimeCreated,
    },
  }
  const variables = {}

  return { ...variables, ...functions }
}
