// Globals.
const GOOGLECONTACTS = 'googleContacts'
const DONE_RETRIEVE_GOOGLE_CONTACTS = 'doneRetrieveGoogleContacts'
const UTM_PARAMETERS = 'utmParameters'

const removeItemFromSessionStorage = (key: string) => {
  try {
    if (!window) return
    window.sessionStorage.removeItem(key)
  } catch (error) {
    console.error('sessionStorage.removeItem', error)
  }
}

// Clear Session Storage.
const clearSessionStorage = () => {
  try {
    if (!window) return
    window.sessionStorage.clear()
  } catch (error) {
    console.error('sessionStorage.clear', error)
  }
}

// Commit Item To Session Storage.
const commitItemToSessionStorage = (key: string, value: string): void => {
  try {
    if (!window || !window.sessionStorage) return undefined
    if (!navigator.cookieEnabled) return undefined
    window.sessionStorage.setItem(key, value)
  } catch (error) {
    console.error('sessionStorage.setItem:', error)
    return undefined
  }
}

// Retrieve Item From Session Storage.
const retrieveItemFromSessionStorage = (key: string): string | undefined => {
  try {
    if (!window || !window.sessionStorage) return undefined
    if (!navigator.cookieEnabled) return undefined
    const value = window.sessionStorage.getItem(key)
    return value
  } catch (error) {
    //console.error('sessionStorage.getItem:', error)
    return undefined
  }
}

// Session Storage.
export class SESSIONSTORAGE {
  // Set Item.
  static setItem = commitItemToSessionStorage

  // Clear.
  static clear = clearSessionStorage

  // Get Item.
  static getItem = retrieveItemFromSessionStorage

  // Get Google Contacts.
  static getGoogleContacts = () =>
    retrieveItemFromSessionStorage(GOOGLECONTACTS)

  // Set Google Contacts.
  static setGoogleContacts = (contacts: string) =>
    commitItemToSessionStorage(GOOGLECONTACTS, contacts)

  // Get Done Retrieve Google Contacts
  static getDoneRetrieveGoogleContacts = () =>
    retrieveItemFromSessionStorage(DONE_RETRIEVE_GOOGLE_CONTACTS)

  // Set Done Retrieve Google Contacts
  static setDoneRetrieveGoogleContacts = (attempt: string) =>
    commitItemToSessionStorage(DONE_RETRIEVE_GOOGLE_CONTACTS, attempt)

  // Get UTM Parameters
  static getUTMParameters = () => retrieveItemFromSessionStorage(UTM_PARAMETERS)

  // Set UTM Parameters
  static setUTMParameters = (parameters: string) =>
    commitItemToSessionStorage(UTM_PARAMETERS, parameters)
}
