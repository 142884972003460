// Dependencies.
import styled from 'styled-components'
import { useMemo, useEffect, useContext } from 'react'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'

import { Toast } from '../../types'
import { P } from '../P'
import { CloseIcon } from '../icons'
import { ToastContext } from '../../context'
import { colors, fontWeights } from '../../utils'

interface Props {
  toast: Toast
}

const Container = styled.div`
  background: #fff;
  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  margin-bottom: 15px;
  width: 288px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #000;
  background-position: 15px;
  background-repeat: no-repeat;
  top: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
  &.wide {
    width: 543px;
  }

  &:hover {
    box-shadow: 0 0 12px #fff;
    opacity: 1;
  }

  @keyframes toast-in-right {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  &.success {
    background-color: #e9fff2;
    & .notificationTitle,
    & .notificationMessage {
      color: #48c97e;
    }

    & .close {
      color: #80daa5;
    }
  }

  &.error {
    background-color: #ffe0e0;
    & .notificationTitle,
    & .notificationMessage {
      color: #eb5757;
    }
    & .close {
      color: #eb5757;
    }
  }

  &.information {
    background-color: #f0e5f4;
    & .notificationTitle,
    & .notificationMessage {
      color: #6d4bea;
    }

    & .close {
      color: #6d4bea;
    }
  }
  &.warning {
    background-color: #f5e8fa;
    & .notificationTitle,
    & .notificationMessage {
      color: #ca7ae8;
    }

    & .close {
      color: #d48eee;
    }
  }

  & .close {
    width: 10px;
    height: 10px;
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
  }

  & .notificationContentContainer {
    padding: 10px 30px 10px 14px;
    gap: 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    & .notificationIcon {
      float: left;
      margin-right: 15px;
      width: 20px;
      height: 20px;
    }

    & .notificationIcon img {
      width: 100%;
      height: 100%;
    }
  }

  .notificationTitle {
    text-align: left;
    margin-top: 0;
    margin-bottom: 6px;
    width: 100%;
    line-height: 130%;
    font-size: 14px;
    font-weight: 500;
  }

  .notificationMessage {
    font-size: 12px;
    font-weight: ${fontWeights.normal};
    letter-spacing: -0.5px;

    a {
      color: ${colors.white};
      font-weight: ${fontWeights.bold};
    }
  }
  .action {
    white-space: nowrap;
    margin-right: 20px;
  }
`

export const ToastItem: React.FC<Props> = ({ toast }) => {
  const { id, title, icon, toastType, dismissTime, content, action, onClose } =
    toast

  const { removeToast } = useContext(ToastContext)

  const { t } = useTranslation('common')

  const backgroundColor = useMemo(() => {
    if (toastType) {
      return toastType as string
    }
    return ''
  }, [toastType])

  useEffect(() => {
    if (dismissTime) {
      const interval = setTimeout(() => {
        removeToast(id)
      }, dismissTime)
      return () => clearTimeout(interval)
    }
  }, [dismissTime, id, removeToast])

  const handleClose = () => {
    removeToast(id)
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  return (
    <Container className={`${backgroundColor} ${action ? 'wide' : ''}`}>
      <div className="close" onClick={handleClose}>
        <CloseIcon aria-label="close icon" />
      </div>
      <div className="notificationContentContainer">
        {icon && (
          <div className="notificationIcon">
            <Image
              src={icon ? icon : ''}
              alt={t('notification icon', 'notification icon')}
              width="20px"
              height="20px"
            />
          </div>
        )}
        <div className="notificationContent">
          <P className="notificationTitle">{title}</P>
          {content && <div className="notificationMessage">{content}</div>}
        </div>
        {action && <div className="action">{action}</div>}
      </div>
    </Container>
  )
}
