import { useTranslation } from 'next-i18next'

// Dependencies.
import { colors } from '../../utils'
import { P } from '../P'

// Copyright.
export const Copyright: React.FC = () => {
  const { t } = useTranslation('common')
  const year = new Date().getFullYear()
  const copyrightSign = '©'

  return (
    <P className="detail" style={{ color: colors.grey }}>
      {copyrightSign} {year}{' '}
      {t(
        `Copyright2036364419`,
        `Klyk LTD, Inc. In partnership with PT. Swarna Langit Inspirasi Digital. All rights reserved.`,
      )}
    </P>
  )
}
