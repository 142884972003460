// Libs
import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react'
import MOMENT from 'moment'
import { useAsyncSetState } from 'use-async-setstate'

// Comps
import { AxiosError } from 'axios'

import {
  CreateEventFunc,
  FetchEventsFunc,
  FetchEventsResult,
  InsertEventFunc,
  NookEvent,
  GetEventDetailFunc,
  AddCurrentUserToEventFunc,
  RemoveCurrentUserFromEventFunc,
  RemoveParticipantFromEventFunc,
  FetchEventsParams,
  ResetEventPageFunc,
  CurrentEventPage,
  UploadImageFunc,
  FetchMyEventFunc,
  FetchInvitesFunc,
  RecursiveFetchMyEventParams,
  RecursiveFetchMyEventFunc,
  DeleteEventFunc,
  SubmitNewInvitesFunc,
  RemoveImageFromEventFunc,
  UpdateEventFunc,
  CreateInvitesForEventFunc,
  Invite,
  Invites,
  InvitesResponse,
  UnsplashImage,
  FetchUnsplashFunc,
  UnsplashResponse,
  FetchUnsplashImageFunc,
  GetDraftEventFunc,
  ResetDraftEventFunc,
  EventCalendar,
  FetchEventCalendarFunc,
  AddCurrentUseerToEventParams,
  UpdateEventDetailFunc,
  FetchEventEmailReminderStatusFunc,
  EventEmailReminderStatus,
  EventsList,
  FetchEventListFunc,
  FetchEventListPaginatedFunc,
  DeferredEventsList,
  User,
  GenericFunctionUsingEventIdReturningPromisVoid,
  FetchPastEventListFunc,
  FetchAttendedEventListFunc,
} from '../types'
import { API } from '../API'
import { LOCALSTORAGE } from '../utils/localStorage'
import { useAnalyticsEvent } from '../hooks/analyticsEvent'
import { FORMATTER } from '../utils/formatter'
import { useIsAuthorized } from '../hooks/useIsAuthorized'
import { EventFrequency } from '../entities'

import { UserContext } from '.'

export const EmptyEventsList: EventsList = {
  count: 0,
  next: null,
  results: [],
}

// Context.
export const Context = createContext({
  // Values.
  events: [] as NookEvent[],
  eventDetail: {} as NookEvent,
  currentEventPage: {} as FetchEventsParams,
  hasNextPage: {} as boolean,
  myEvents: [] as NookEvent[],
  draftEvent: {} as NookEvent,
  invites: [] as Invite[],
  emailReminderStatus: {} as EventEmailReminderStatus,
  attendingEvents: { ...EmptyEventsList },
  invitedEvents: { ...EmptyEventsList },
  hostingEvents: { ...EmptyEventsList },
  pastEvents: { ...EmptyEventsList },
  waitingForEvents: { ...EmptyEventsList },
  hostingPastEvents: { ...EmptyEventsList },
  attendedEvents: { ...EmptyEventsList },

  // Functions.
  addCurrentUserToEvent: (async (params) =>
    ({} as NookEvent)) as AddCurrentUserToEventFunc,
  addCurrentUserToEventWaitingList:
    (async () => {}) as GenericFunctionUsingEventIdReturningPromisVoid,
  createEvent: (async (params) => params) as CreateEventFunc,
  insertEvent: (() => ({})) as InsertEventFunc,
  fetchEvents: (async () => ({} as NookEvent[])) as FetchEventsFunc,
  fetchEvent: (async () => ({} as NookEvent)) as GetEventDetailFunc,
  fetchDuplicateEvent: (async () => ({} as NookEvent)) as GetEventDetailFunc,
  removeCurrentUserFromEvent:
    (async () => {}) as RemoveCurrentUserFromEventFunc,
  removeParticipantFromEvent:
    (async () => {}) as RemoveParticipantFromEventFunc,
  removeCurrentWaitingUserFromEvent:
    (async () => {}) as GenericFunctionUsingEventIdReturningPromisVoid,
  resetCurrentEventPage: (() => {}) as ResetEventPageFunc,
  setEvents: (() => {}) as React.Dispatch<React.SetStateAction<NookEvent[]>>,
  uploadImageToEvent: (async () => {}) as UploadImageFunc,
  removeImageFromEvent: (async () => {}) as RemoveImageFromEventFunc,
  fetchMyEvents: (async () => ({} as NookEvent[])) as FetchMyEventFunc,
  fetchInvites: (async (eventId: number) =>
    ({} as Invite[])) as FetchInvitesFunc,
  submitNewInvites: (async (invites: Invite[], eventId) =>
    ({} as InvitesResponse[])) as SubmitNewInvitesFunc,
  updateEvent: (async (params) => params) as UpdateEventFunc,
  deleteEvent: (async () => {}) as DeleteEventFunc,
  createInvitesForEvent: (async () =>
    ({} as Invite[])) as CreateInvitesForEventFunc,
  getDraftEvent: (() => ({})) as GetDraftEventFunc,
  resetDraftEvent: (() => ({})) as ResetDraftEventFunc,
  fetchCalendar: (async (params) => ({})) as FetchEventCalendarFunc,
  updateEventDetail: ((params) => {}) as UpdateEventDetailFunc,

  fetchEmailReminderStatus: (async (
    params,
  ) => ({})) as FetchEventEmailReminderStatusFunc,
  fetchWaitingForEvents: (async () => ({})) as FetchEventListPaginatedFunc,
  fetchAttendingEvents: (async () => ({})) as FetchEventListPaginatedFunc,
  fetchInvitedEvents: (async () => ({})) as FetchEventListPaginatedFunc,
  fetchHostingEvents: (async () => ({})) as FetchEventListPaginatedFunc,
  fetchPastHostingEvents: (async () => ({})) as FetchEventListPaginatedFunc,
  fetchPastEvents: (async (
    param: Parameters<FetchPastEventListFunc>[0],
  ) => ({})) as FetchPastEventListFunc,
  fetchAttendedEventList: (async () => ({})) as FetchAttendedEventListFunc,

  setWaitingForEvents: (eventList: EventsList) => {},
  setAttendingEvents: (eventList: EventsList) => {},
  setInvitedEvents: (eventList: EventsList) => {},
  setHostingEvents: (eventList: EventsList) => {},
  setHostingPastEvents: (eventList: EventsList) => {},
  setPastEvents: (eventList: EventsList) => {},
  setAttendedEvents: (eventList: EventsList) => {},

  fetchEventList: ((
    getFunction: FetchEventListPaginatedFunc,
    stopAt?: number,
  ) => ({})) as FetchEventListFunc,
})

// Provider.
export const Provider: React.FC = ({ children }) => {
  // This is used for discover events.
  const [events, setEvents] = useAsyncSetState<NookEvent[]>([])
  const [invites, setInvites] = useAsyncSetState<Invite[]>([])
  const [eventDetail, setEventDetail] = useState<NookEvent>()
  const [currentEventPage, setCurrentEventPage] =
    useAsyncSetState<CurrentEventPage>({
      page: 0,
      category: '',
      shouldFetchNewData: false,
    })
  const [hasNextPage, setHasNextPage] = useAsyncSetState(false)

  // This is used for myEvents
  const [myEvents, setMyEvents] = useAsyncSetState<NookEvent[]>([])
  const [draftEvent, setDraftEvent] = useState<NookEvent>()
  const [attendingEvents, setAttendingEvents] =
    useAsyncSetState<EventsList>(undefined)
  const [invitedEvents, setInvitedEvents] =
    useAsyncSetState<EventsList>(undefined)
  const [hostingEvents, setHostingEvents] =
    useAsyncSetState<EventsList>(undefined)
  const [hostingPastEvents, setHostingPastEvents] =
    useAsyncSetState<EventsList>(undefined)
  const [pastEvents, setPastEvents] = useAsyncSetState<EventsList>(undefined)
  const [attendedEvents, setAttendedEvents] =
    useAsyncSetState<EventsList>(undefined)
  const [waitingForEvents, setWaitingForEvents] =
    useAsyncSetState<EventsList>(undefined)

  const { currentUser } = useContext(UserContext)
  const [emailReminderStatus, setEmailReminderStatus] =
    useState<EventEmailReminderStatus>()

  const analyticsEvent = useAnalyticsEvent()
  const isAuthorized = useIsAuthorized()
  useEffect(() => {
    if (currentUser === null) {
      setMyEvents([])
    }
  }, [currentUser, setMyEvents])

  useEffect(() => {
    if (
      currentEventPage.category === '' &&
      currentEventPage.shouldFetchNewData
    ) {
      const params: FetchEventsParams = { category: 'allCategory' }
      fetchEvents(params)
      setCurrentEventPage({
        ...currentEventPage,
        ...{ shouldFetchNewData: false },
      })
    }
  }, [currentEventPage])

  const authorizedFetchEvents = useCallback(
    async (run: () => Promise<EventsList>) => {
      if (isAuthorized) {
        return run()
      } else {
        return {
          count: 0,
          next: '',
          results: [],
        }
      }
    },
    [isAuthorized],
  )
  /**
   * Fetch Events
   */

  // Fetch the attending events for the user
  const fetchWaitingForEvents: FetchEventListPaginatedFunc = async (
    page?: number,
  ) => {
    const response = await API.get<EventsList>(
      `/waitingforevents/?page=${page || 1}`,
    )
    return response.data
  }

  // Fetch the attending events for the user
  const fetchAttendingEvents: FetchEventListPaginatedFunc = async (
    page?: number,
  ) => {
    const response = await API.get<EventsList>(
      `/attendingevents/?page=${page || 1}`,
    )
    return response.data
  }

  // Fetch the invited events for the user
  const fetchInvitedEvents: FetchEventListPaginatedFunc = async (
    page?: number,
  ) => {
    const response = await API.get<EventsList>(
      `/invitedevents/?page=${page || 1}`,
    )
    return response.data
  }

  // Fetch Hosted events
  const fetchHostingEvents: FetchEventListPaginatedFunc = useCallback(
    async (page?: number) => {
      return authorizedFetchEvents(async () => {
        const response = await API.get<EventsList>(
          `/hostingevents/?page=${page || 1}`,
        )
        return response.data
      })
    },
    [authorizedFetchEvents],
  )

  // Fetch Past hosted events
  const fetchPastHostingEvents: FetchEventListPaginatedFunc = useCallback(
    async (page?: number) => {
      return authorizedFetchEvents(async () => {
        const response = await API.get<EventsList>(
          `/pasthostingevents/?page=${page || 1}`,
        )
        return response.data
      })
    },
    [authorizedFetchEvents],
  )
  // Should be publicly available
  const fetchPastEvents: FetchPastEventListFunc = useCallback(
    async ({ monthsAfter = 3, page = 1 }) => {
      try {
        const response = await API.get<EventsList>(
          `/events/pastevents/?&months_after=${monthsAfter}&page=${page}`,
        )
        return response.data
      } catch (e) {
        const {
          response: { status, statusText, data },
        } = e as AxiosError
        throw new Error(`/events/pastevents throws error:
        {
          status=${status},
          statusText=${statusText},
          data=${JSON.stringify(data)},
        }
      `)
      }
    },
    [],
  )

  const fetchAttendedEventList: FetchAttendedEventListFunc = async (
    page = 1,
  ) => {
    const response = await API.get<EventsList>(
      `/events/userpastevents/?page=${page}`,
    )
    return response.data
  }

  // Fetch events until there are no more events to fetch
  const fetchEventList: FetchEventListFunc = async (
    getFunction: FetchEventListPaginatedFunc,
    stopAt?: number,
  ) => {
    // Stop at a reasonable number so we don't end up in an infinite  loop and request bomb the server
    const theStopAt = stopAt || 10
    let counter = 0

    // Set up some vars
    const allData = await getFunction()
    const initialResultData = { ...allData }
    initialResultData.results = [...allData.results]
    // get all remaining API calls as a Promise all

    /* eslint-disable-next-line no-async-promise-executor */
    const retPromise: Promise<EventsList> = new Promise(async (resolve) => {
      while (allData?.next !== null && counter < theStopAt) {
        // get the next page id by stripping the URL for anything that not a number
        const nextParam = allData.next.split('?')[1] || '-1'
        const nextPageNumber: number = parseInt(nextParam.replace(/\D/g, ''))
        const newData = await getFunction(nextPageNumber)
        newData.results.forEach((event, index) => {
          allData.results.push(event)
        })
        allData.next = newData.next
        counter++
      }

      // When done resolve with all the data
      resolve(allData)
    })

    //
    const deferred: DeferredEventsList = {
      initial: initialResultData,
      deferred: retPromise,
    }

    return deferred
  }

  const resetCurrentEventPage = (shouldFetchNewData: boolean) => {
    setCurrentEventPage({
      page: 0,
      category: '',
      shouldFetchNewData: shouldFetchNewData,
    })
    setEvents([])
  }

  // Add User To Event.
  const addCurrentUserToEvent: AddCurrentUserToEventFunc = async (
    params: AddCurrentUseerToEventParams,
  ) => {
    const { eventId, payment_id } = params
    if (!eventId) return undefined

    const response = await API.post<NookEvent>(`/events/${eventId}/going/`, {})
    const event = response.data
    insertEvent(event)
    setEventDetail(event)
    return event
  }

  const addCurrentUserToEventWaitingList: RemoveCurrentUserFromEventFunc =
    async (eventId) => {
      if (!eventId) return undefined

      const response = await API.post<NookEvent>(
        `/events/${eventId}/waiting_list/`,
        {},
      )
      const event = response.data
      insertEvent(event)
      setEventDetail(event)
    }

  const isValidDate = (date: Date) => {
    return date instanceof Date && !isNaN(date.getTime())
  }

  // Convert To ISO Date Time.
  const convertToISODateTime = (date: Date, time: Date) => {
    if (!isValidDate(date) || !isValidDate(time)) return null
    return MOMENT(
      new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        time.getHours(),
        time.getMinutes(),
      ),
    ).toISOString(true)
  }

  // Parse Server URL
  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href
    name = name.replace(/[[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  // Insert Event.
  const insertEvent: InsertEventFunc = (event: NookEvent) =>
    setEvents((EVENTS) => {
      const targetIndex = EVENTS.findIndex((EVENT) => EVENT.id === event.id)
      const EVENT = EVENTS[targetIndex]
      if (!EVENT) {
        return [...EVENTS, event] // Push Clone With Event Appended.
      }
      EVENTS[targetIndex] = { ...EVENT, ...event } as NookEvent // Update Existing Event.
      return [...EVENTS]
    })

  // Fetch Events
  const fetchEvents: FetchEventsFunc = async (params?: {
    category
    search
    page
    ordering
    recommended
    event_not_in
    paid_type
    reset
  }) => {
    // Named Params
    const {
      category,
      search,
      page,
      ordering,
      recommended,
      event_not_in,
      paid_type,
      reset,
    } = params || {}

    // Vars
    let shouldResetCollection = false
    const defaultCategory = 'allCategory'
    const defaultPage = 1
    const requestCategory = category === defaultCategory ? undefined : category
    let requestPage = page || defaultPage

    // Oddity - assuming that there some jank going on with mixing concerns
    if (currentEventPage.page > 0) {
      requestPage = currentEventPage.page + 1
    }

    // Oddity - assuming that there some jank going on with mixing concerns
    if (currentEventPage.category !== params.category) {
      // User filter new category
      currentEventPage.page = 0
      shouldResetCollection = true
    }

    // Build Get Request
    const querySet = {
      category: requestCategory,
      search: search || undefined,
      page: requestPage,
      recommended: recommended || false,
      event_not_in: event_not_in || undefined,
      paid_type: paid_type || undefined,
    }
    const path = `/events${FORMATTER.objectToQueryString({
      ...querySet,
    })}`

    // Get and handle response
    const response = await API.get<FetchEventsResult>(path)
    const { results = null, next = null, previous = null } = response.data

    if (results) {
      if (shouldResetCollection === true) {
        if (results == null) {
          setEvents([])
        } else {
          setEvents(results)
        }
      } else {
        // Append page or reset if first page
        if (previous === null || reset === true) {
          // console.log('new events', querySet, results)
          setEvents(results)
        } else {
          // Filter out duplicates
          const newEvents = []
          results.forEach((e) => {
            if (!events.some((f) => f.id === e.id)) {
              newEvents.push(e)
            }
          })
          // console.log('events with new page', querySet, [...events, ...newEvents])
          setEvents([...events, ...newEvents])
        }
      }

      if (next) {
        const nextPage = getParameterByName('page', next)
        const nextCategory = getParameterByName('category', next)
        setCurrentEventPage({
          ...currentEventPage,
          ...{
            category: nextCategory ? nextCategory : defaultCategory,
            page: parseInt(nextPage) - 1,
          },
        })
        setHasNextPage(true)
      } else {
        setCurrentEventPage({
          ...currentEventPage,
          ...{ category: params.category, page: -1 },
        })
        setHasNextPage(false)
      }
    }

    return results
  }

  const convertToFormData = (event: Partial<NookEvent>): FormData => {
    const formData = new FormData()
    if (event.imageFile) formData.append('image', event.imageFile)
    if (event.descr) formData.append('descr', event.descr)
    if (event.host_bio) formData.append('host_bio', event.host_bio)
    if (event.participation_descr)
      formData.append('participation_descr', event.participation_descr)
    if (event.title) formData.append('title', event.title)
    if (event.category_id)
      formData.append('category_id', event.category_id.toString())
    if (event.start_date) formData.append('start_date', event.start_date)
    if (event.duration) formData.append('duration', event.duration.toString())
    if (event.group_size)
      formData.append('group_size', event.group_size.toString())
    if (event.event_type)
      formData.append('event_type', event.event_type.toString())
    if (event.location) formData.append('location', event.location)
    return formData
  }

  // Create Event.
  const createEvent: CreateEventFunc = async (params) => {
    // Recurring event for 1 year as default
    if (params.is_recurring && !params.until) {
      const byYear = new Date()
      byYear.setFullYear(byYear.getFullYear() + 1)
      params.until = byYear
    }

    delete params.image
    if (params.invites)
      params.invites = params.invites.filter((i) => i.email !== '')
    if (params.equipments)
      params.equipments = params.equipments.filter((e) => e.descr !== '')

    return await API.post<NookEvent>('/events/', params)
      .then(async (res) => {
        const event = res.data
        const { id } = event
        if (id) {
          analyticsEvent.eventPublished({
            ...params,
            ...{ id: event.id, event_url: event.event_url },
          } as NookEvent)
          if (params.imageFile) {
            await uploadImageToEvent(id, params.imageFile)
          } else {
            setEventDetail(event)
          }
        }
        return event
      })
      .catch((error) => {
        throw error
      })
  }

  //Get Event Detail
  const fetchEvent: GetEventDetailFunc = useCallback(async (id) => {
    // !Id.
    if (!id) return null

    return await API.get<NookEvent>(`/events/${id}/`)
      .then((res) => {
        const event = res.data
        if (event) setEventDetail(event)
        return event
      })
      .catch((error) => {
        throw error
      })
  }, [])
  const fetchDuplicateEvent: GetEventDetailFunc = async (id) => {
    return await API.get<NookEvent>(`/events/${id}/`)
      .then((res) => {
        const event = res.data
        if (event) {
          // event.invites = event.participants as User
          delete event.id
          delete event.participants
          delete event.participants_number
          console.log('@EventContext->fetchDuplicateEvent', event)
          setEventDetail(event)
        }
        return event
      })
      .catch((error) => {
        throw error
      })
  }

  //Fetch My Events
  const fetchMyEvents: FetchMyEventFunc = async () => {
    const allMyEvents = []
    recursiveFetchMyEvents({ result: allMyEvents, page: 0 })
    return myEvents
  }

  // Fetch invites
  const fetchInvites: FetchInvitesFunc = async (eventId: number) => {
    return await API.get<Invite[]>(`/events/${eventId}/invites/`)
      .then((res) => {
        setInvites(res.data)
        return res.data
      })
      .catch((error) => {
        return null
      })
  }

  const recursiveFetchMyEvents: RecursiveFetchMyEventFunc = async (
    params: RecursiveFetchMyEventParams,
  ) => {
    const page = params.page !== 0 ? `?page=${params.page}` : ''
    const response = await API.get<FetchEventsResult>(`/myevents/${page}`)
    const { next, results } = response.data

    //update store
    if (results) {
      results.forEach((e) => {
        if (!params.result.some((f) => f.id === e.id)) params.result.push(e)
      })
    }

    if (next) {
      params.page++
      recursiveFetchMyEvents(params)
    } else {
      setMyEvents(params.result)
    }
  }

  // Remove User From Event.
  const removeCurrentUserFromEvent: RemoveCurrentUserFromEventFunc = async (
    eventId,
  ) => {
    if (!eventId) return undefined
    const response = await API.delete<NookEvent>(`/events/${eventId}/going/`)
    insertEvent(response.data)
    setEventDetail(response.data)
  }

  const removeParticipantFromEvent: RemoveParticipantFromEventFunc = async (
    eventId,
    participantId,
  ) => {
    if (!eventId || !participantId) return undefined
    const response = await API.delete<NookEvent>(
      `/events/${eventId}/remove_participant/${participantId}/`,
    )
    insertEvent(response.data)
    setEventDetail(response.data)
  }

  const removeCurrentWaitingUserFromEvent: RemoveCurrentUserFromEventFunc =
    async (eventId) => {
      if (!eventId) return undefined
      const response = await API.delete<NookEvent>(
        `/events/${eventId}/waiting_list/`,
      )
      insertEvent(response.data)
      setEventDetail(response.data)
    }

  // Upload Image To Event.
  const uploadImageToEvent: UploadImageFunc = async (id, image) => {
    if (!id) return
    if (!image) return
    const formData = new FormData()
    formData.append('image', image)

    // Response.
    const response = await API.postFormData<NookEvent>(
      `/events/${id}/image/`,
      formData,
    )
    const event = response.data
    insertEvent(event)
    setEventDetail(event)
  }

  // Remove Image from Event
  const removeImageFromEvent: RemoveImageFromEventFunc = async (eventId) => {
    return await API.delete<NookEvent>(`/events/${eventId}/image/`)
      .then((res) => {
        insertEvent(res.data)
        setEventDetail(res.data)
      })
      .catch((error) => {
        //throw error
      })
  }

  // Delete Event
  const deleteEvent: DeleteEventFunc = async (
    eventId: number,
    deleteNote?: string,
  ) => {
    return await API.delete(
      `/events/${eventId}/`,
      deleteNote ? { message: deleteNote } : {},
    )
      .then((res) => {})
      .catch((error) => {
        throw error
      })
  }

  const updateEvent: UpdateEventFunc = async (params) => {
    delete params.image
    if (params.invites)
      params.invites = params.invites.filter((i) => i.email !== '')
    if (params.equipments)
      params.equipments = params.equipments.filter((e) => e.descr !== '')
    //params.end_date = convertToISODateTime(date, endTime)
    //params.image = null // we need to make another call to upload/remove the image
    //const formData = convertToFormData(params);
    if (params.is_recurring) {
      if (
        params.frequency === EventFrequency.DAILY ||
        params.frequency === EventFrequency.YEARLY
      ) {
        delete params.by_week_days
        delete params.month_week_nos
      }
      if (params.frequency === EventFrequency.WEEKLY) {
        delete params.month_week_nos
      }
    }
    // Response.
    return await API.put<NookEvent>(`/events/${params.id}/`, params)
      .then((res) => {
        const event = res.data
        insertEvent(event)
        setEventDetail(event)
        return event
      })
      .catch((error) => {
        throw error
      })
  }

  const createInvitesForEvent: CreateInvitesForEventFunc = async (
    eventId,
    emails,
  ) => {
    if (!eventId || eventId === 0 || !emails) return
    const newInvites = [] as Invite[]

    for (const email of emails) {
      if (email && email !== '') {
        const resp = await API.post<Invite>(`/events/${eventId}/invites/`, {
          email: email,
        })
        const invite = resp.data
        newInvites.push(invite)
      }
    }
    return newInvites
  }

  const submitNewInvites: SubmitNewInvitesFunc = async (emails, eventId) => {
    const resp = await API.post<InvitesResponse[]>(
      `/events/${eventId}/bulk_invites/`,
      { invites: emails },
    )
    return resp.data
  }

  // Retrieve Draft Event from local storage
  const getDraftEvent: GetDraftEventFunc = () => {
    const draftEvent = LOCALSTORAGE.getDraftEvent()
    if (draftEvent) {
      LOCALSTORAGE.removeDraftEvent()
      const eventObject = JSON.parse(draftEvent)
      setDraftEvent(eventObject)
      return eventObject
    }
  }

  // Retrieve Event Calendar
  const fetchCalendar: FetchEventCalendarFunc = async (id: number) => {
    if (!id) return null
    return await API.get<EventCalendar>(`/events/${id}/calendar/`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        return null
      })
  }

  const resetDraftEvent: ResetDraftEventFunc = () => {
    setDraftEvent(null)
  }

  const updateEventDetail: UpdateEventDetailFunc = useCallback(
    (detail: NookEvent) => {
      if (detail) setEventDetail(detail)
    },
    [],
  )
  const fetchEmailReminderStatus: FetchEventEmailReminderStatusFunc = async (
    id: number,
  ) => {
    if (!id) return null
    return await API.get<EventEmailReminderStatus>(
      `/events/${id}/email_status/`,
    )
      .then((res) => {
        setEmailReminderStatus(res.data)
        return res.data
      })
      .catch((error) => {
        return null
      })
  }

  // Variables.
  const variables = {
    draftEvent,
    events,
    eventDetail,
    invites,
    myEvents,
    currentEventPage,
    hasNextPage,
    resetCurrentEventPage,
    emailReminderStatus,
    attendingEvents,
    invitedEvents,
    hostingEvents,
    hostingPastEvents,
    waitingForEvents,
    pastEvents,
    attendedEvents,
  }

  // Functions.
  const functions = {
    addCurrentUserToEvent,
    createEvent,
    submitNewInvites,
    fetchEvents,
    insertEvent,
    fetchEvent,
    setEvents,
    removeCurrentUserFromEvent,
    removeParticipantFromEvent,
    uploadImageToEvent,
    fetchMyEvents,
    removeImageFromEvent,
    deleteEvent,
    updateEvent,
    createInvitesForEvent,
    fetchInvites,
    getDraftEvent,
    resetDraftEvent,
    fetchCalendar,
    updateEventDetail,
    fetchEmailReminderStatus,
    fetchAttendingEvents,
    fetchInvitedEvents,
    setAttendingEvents,
    setInvitedEvents,
    fetchHostingEvents,
    fetchPastHostingEvents,
    fetchPastEvents,
    fetchAttendedEventList,
    setHostingEvents,
    setHostingPastEvents,
    setPastEvents,
    fetchEventList,
    fetchDuplicateEvent,
    removeCurrentWaitingUserFromEvent,
    addCurrentUserToEventWaitingList,
    fetchWaitingForEvents,
    setWaitingForEvents,
    setAttendedEvents,
  }

  // Value.
  const value = { ...variables, ...functions }

  // ..
  return <Context.Provider value={value}>{children}</Context.Provider>
}
