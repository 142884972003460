import { useEffect } from 'react'
import { useRouter } from 'next/router'

// Set APP_ID
export const APP_ID = process.env.NEXT_PUBLIC_INTERCOM_ID || 'njyynhvt'

// Loads Intercom with the snippet
// This must be run before boot, it initializes window.Intercom
type ExtendedWindow = Window &
  typeof globalThis & {
    Intercom: any
    intercomSettings: any
    attachEvent: any
    useIntercom: any
  }

export const load = () => {
  const w = window as ExtendedWindow
  const ic = w.Intercom
  if (typeof ic === 'function') {
    ic('reattach_activator')
    ic('update', w.intercomSettings)
  } else {
    const d = document
    const i = function (...args) {
      i.c(args)
    }
    i.q = []
    i.c = function (args) {
      i.q.push(args)
    }
    w.Intercom = i
    const l = function () {
      const s = d.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://widget.intercom.io/widget/' + APP_ID
      const x = d.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
    }
    if (w.attachEvent) {
      w.attachEvent('onload', l)
    } else {
      w.addEventListener('load', l, false)
    }
  }
}

// Initializes Intercom
export const boot = (options = {}) => {
  window &&
    (window as ExtendedWindow).Intercom &&
    (window as ExtendedWindow).Intercom('boot', { app_id: APP_ID, ...options })
}

// Partial Update user data
export const update = (options = {}) => {
  window &&
    (window as ExtendedWindow).Intercom &&
    (window as ExtendedWindow).Intercom('update', options)
}

export const trackEvent = (key: string) => {
  window &&
    (window as ExtendedWindow).Intercom &&
    (window as ExtendedWindow).Intercom('trackEvent', key)
}

// fn for when the page changes update the URL
export const updateUrl = () => {
  update()
}

export const shutdown = () => {
  window &&
    (window as ExtendedWindow).Intercom &&
    (window as ExtendedWindow).Intercom('shutdown')
}

// Open the intercom widget for the user
export const show = () => {
  window &&
    (window as ExtendedWindow).Intercom &&
    (window as ExtendedWindow).Intercom('show')
}

export const showMessages = () => {
  window &&
    (window as ExtendedWindow).Intercom &&
    (window as ExtendedWindow).Intercom('showMessages')
}

// CLose the intercom widget for the user
export const hide = () => {
  window &&
    (window as ExtendedWindow).Intercom &&
    (window as ExtendedWindow).Intercom('hide')
}

export const showMoreEmailInvite = () => {
  update({
    more_invites_request: true, // trip the message
  })
  // trackEvent('more_invites_request')
  showMessages()
}

export const noteMissingStripeCountry = (props: {
  email?: string
  country?: string
}) => {
  update({
    stripe_missing_country_email: props?.email || undefined,
    stripe_missing_country_code: props?.country || undefined,
  })
}

// Use Intercom.
export const useIntercom = (capturePageChanges?: boolean) => {
  // Props
  const theCapturePageChanges = capturePageChanges || false

  // Global State
  const router = useRouter()

  // On Mount
  useEffect(() => {
    // Get Intercom and Boot + avoid double init when included in more than 1 Comp
    if (window && (window as ExtendedWindow).Intercom === undefined) {
      load()
      boot()
      ;(window as ExtendedWindow).useIntercom = {
        update: update,
        show: show,
        hide: hide,
        showMoreEmailInvite: showMoreEmailInvite,
      }
      console.log('@useIntercom->mounted: OK')
    } else {
      console.warn('@useIntercom->mounted: OK but Already booted')
    }
  }, [])

  // On Page Change
  useEffect(() => {
    if (theCapturePageChanges === true) {
      // When Page changes run
      const routeChangeComplete = (url) => {
        updateUrl() // update page URL
        // hideMoreEmailInvite() // Toggle page var
      }

      router.events.on('routeChangeComplete', routeChangeComplete)
      return () => {
        router.events.off('routeChangeComplete', routeChangeComplete)
      }
    } else {
      console.warn('@useIntercom->on page change: OK but page change disabled')
    }
  }, [router.asPath, router.events])

  // Return Page Events
  return {
    update: update,
    show: show,
    hide: hide,
    trackEvent: trackEvent,
    showMoreEmailInvite: showMoreEmailInvite,
    noteMissingStripeCountry: noteMissingStripeCountry,
  }
}
