import { AxiosResponse } from 'axios'

import { EventFrequency, EEventMonthlyFrequency, Weekday } from '../entities'
import { XOR } from '../utils/typescript-utils'

export type AddCurrentUseerToEventParams = {
  eventId: number
  payment_id?: string
}

// Add User To Event Func.
export type AddCurrentUserToEventFunc = (
  params: AddCurrentUseerToEventParams,
) => Promise<NookEvent>

// Add more invites
export type SubmitNewInvitesFunc = (
  invites: Invite[],
  eventId,
) => Promise<InvitesResponse[]>

// Add User To Event Hook Func.
export type AddCurrentUserToEventHookFunc = (
  payment_id?: string,
) => Promise<NookEvent>

// Create Event Func.
export type CreateEventFunc = (params: Partial<NookEvent>) => Promise<NookEvent>

// Create Event with Event Hook Func
export type CreateEventWithEventHookFunc = () => Promise<number>

// Calculate whether event still in active state
export type IsEventActiveEventHookFunc = () => boolean

// Calculate whether event is going to start within 24 hrs
export type IsEventInside24hrsEventHookFunc = () => boolean

// Calculate whether event is going to start within 15 mins
export type IsEventInside15minsEventHookFunc = () => boolean

// Calculate whether event already started
export type IsEventAlreadyStartedEventHookFunc = () => boolean

// Calculate whether event is already finished
export type NotYetFinishedEventHookFunc = () => boolean

// Fetch events Params.
export type FetchEventsParams = {
  category?: string
  search?: string
  page?: number
  ordering?: string
  recommended?: boolean
  event_not_in?: number[]
  paid_type?: string
  reset?: boolean
  sticky?: boolean
  participating?: boolean
}

export type FetchEventsResult = {
  count: number
  next: number
  previous: number
  results: NookEvent[]
}

export type CurrentEventPage = {
  category: string
  page: number
  shouldFetchNewData: boolean
}

// Fetch Unsplash Images
export type UnsplashImage = {
  thumb: string
  image: string
  user: string
  userUrl: string
  descr: string
  download: string
}

export type UnsplashResponse = {
  total: number
  total_page: number
  photos: UnsplashPhoto[]
}

export type UnsplashPhoto = {
  user: string
  user_url: string
  descr?: string
  raw: string
  regular: string
  full: string
  small: string
  thumb: string
  id: string
  download_location: string
}

export type FetchUnsplashFunc = (params: string) => Promise<UnsplashImage[]>

export type FetchUnsplashImageFunc = (url: string) => Promise<string>

// Reset Events Page Func
export type ResetEventPageFunc = (shouldFetchNewData: boolean) => void

//Insert Event Func.
export type InsertEventFunc = (event: Partial<NookEvent>) => void

// Fetch Events Func.
export type FetchEventsFunc = (
  params?: FetchEventsParams,
) => Promise<NookEvent[]>

// Fetch My Event Func.
export type FetchMyEventFunc = () => Promise<NookEvent[]>

export type RecursiveFetchMyEventParams = {
  result: NookEvent[]
  page: number
}
// Recursive Fetch My Event Func.
export type RecursiveFetchMyEventFunc = (
  params: RecursiveFetchMyEventParams,
) => Promise<void>

//Get Event Detail Func.
export type GetEventDetailFunc = (id: number) => Promise<Partial<NookEvent>>

//Get Draft Event
export type GetDraftEventFunc = () => void

//Reset Draft Event
export type ResetDraftEventFunc = () => void

//Update Event Detail
export type UpdateEventDetailFunc = (detail: NookEvent) => void

//Update Show Zoom Auth Modal
export type UpdateShowZoomAuthoriseModalFunc = (show: boolean) => void

//Show hide DropDownCreateGroupModal
export type UpdateShowDropDownCreateGroupModalFunc = (show: boolean) => void

//Show hide DropDownCreateStripeModal
export type UpdateShowDropDownCreateStripeModalFunc = (show: boolean) => void

//Save current event to draft local storage
export type SaveDraftEventHookFunc = () => void

// Event Calendar
export interface EventCalendar {
  google: string | null
  yahoo: string | null
  ics: string | null
}

// Fetch Event Calendar
export type FetchEventCalendarFunc = (id: number) => Promise<EventCalendar>

// Event Email Reminder Status
export interface EmailReminderStatus {
  mins: number
  status: number
}

export interface EventEmailReminderStatus {
  hostDay: EmailReminderStatus
  hostNow: EmailReminderStatus
  participantDay: EmailReminderStatus
  participantNow: EmailReminderStatus
}

export type FetchEventEmailReminderStatusFunc = (
  id: number,
) => Promise<EventEmailReminderStatus>

// Dropdown option
export interface Option<Value = string> {
  label: string // The name of the option (appears when the option is presented).
  value: Value // A unique value used to identify whether this option as been selected.
}

// Event Host.
export interface EventHost {
  id: number
  name: string
  url: string
  image: string
  follower_number?: number
}

// Event Hook.
export type EventHook = (id?: number) => EventHookInterface

export interface EventHookInterface {
  addCurrentUserToEvent: AddCurrentUserToEventHookFunc
  fetch: () => Promise<void>
  isEditable: boolean
  removeCurrentUserFromEvent: RemoveCurrentUserFromEventHookFunc
  removeParticipantFromEvent: RemoveParticipantFromEventHookFunc
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>
  updateFieldsWithPartial: UpdateEventFieldsWithPartialFunc
  uploadImage: UploadImageWithEventHookFunc
  removeImage: RemoveImageWithEventHookFunc
  deleteE: DeleteEventWithEventHookFunc
  updateE: UpdateEventWithEventHookFunc
  setImagePendingUpdate: React.Dispatch<React.SetStateAction<boolean>>
  createInvites: CreateInvitesWithEventHookFunc
  value: Partial<NookEvent>
  error: Partial<NookEventError>
  updateErrorFieldsWithPartial: UpdateEventErrorFieldsWithPartialFunc
  resetAllErrors: ResetEventAllErrorFunc
  validateEvent: ValidateEventFunc
  errorMessage: string
  getDraftNookEvent: GetDraftEventFunc
  createE: CreateEventWithEventHookFunc
  isActive: IsEventActiveEventHookFunc
  isInside24hrs: IsEventInside24hrsEventHookFunc
  isInside15mins: IsEventInside15minsEventHookFunc
  alreadyStarted: IsEventAlreadyStartedEventHookFunc
  notYetFinished: NotYetFinishedEventHookFunc
  showZoomReAuthLink: boolean
  showZoomAuthoriseModal: boolean
  updateShowZoomAuthoriseModal: UpdateShowZoomAuthoriseModalFunc
  showDropDownCreateGroupModal: boolean
  updateShowDropDownCreateGroupModal: UpdateShowDropDownCreateGroupModalFunc
  showDropDownCreateStripeModal: boolean
  updateShowDropDownCreateStripeModal: UpdateShowDropDownCreateStripeModalFunc
  saveDraft: SaveDraftEventHookFunc
  makeDuplicate: (duplicateEventId: number) => void
  removeCurrentWaitingUserFromEvent: () => Promise<void>
  addCurrentUserToEventWaitingList: () => Promise<void>
  alreadyEnded: () => boolean
  isPaidEvent: boolean
}

//Upload Profile Image func.
export type UploadProfileImageFunc = (file: File) => Promise<User>

export interface UpdateUserDetailParams {
  full_name?: string | null
  email?: string | null
  nickname?: string | null
  host_bio?: string | null
  time_zone?: string | null
}
//Update Profile Detail func.
export type UpdateProfileDetailFunc = (
  profile: UpdateUserDetailParams,
) => Promise<boolean>

//Update User Password Params
export interface UpdateUserPasswordParams {
  new_password1: string
  new_password2: string
  old_password: string
}

//Update User Password func.
export type UpdateUserPasswordFunc = (
  password: UpdateUserPasswordParams,
) => Promise<string>

//Delete User func.
export type DeleteMeFunc = () => Promise<void>

//Export Data func.
export type ExportDataFunc = () => Promise<void>

// Fetch Current User Func.
export type FetchCurrentUserFunc = () => Promise<User>

// Fetch groups user is following func
export type FetchFollowedGroupsFunc = () => Promise<Group[]>

// A list of paginated events returned by Django
export type EventsList =
  | {
      count: number | null
      next: string | null
      // previous: number | null
      results: NookEvent[]
    }
  | undefined

// A deferred list - returns the first page as initial and any remaining as async deferred
export type DeferredEventsList = {
  initial: EventsList
  deferred: Promise<EventsList>
}

// Fetch Current user's events using the fetch event
export type FetchEventListFunc = (
  getFunction: FetchEventListPaginatedFunc,
  stopAt?: number,
) => Promise<DeferredEventsList>

export type FetchPastEventListFunc = (params: {
  attendees?: number
  monthsAfter?: number
  page?: number
}) => Promise<EventsList>

export type FetchAttendedEventListFunc = (page?: number) => Promise<EventsList>

// Fetch a list of events on a page - generic used for multiple event fetches
export type FetchEventListPaginatedFunc = (page?: number) => Promise<EventsList>

// Log In Params.
type LogInParams = {
  email: string
  password: string
}

// Log In Func.
export type LogInFunc = (params: LogInParams) => Promise<void>

type GoogleLoginParams = {
  access_token: string
}

// Log In Func.
export type GoogleLoginFunc = (params: GoogleLoginParams) => Promise<void>

// FB Login Params
type FacebookLoginParams = {
  access_token: string
}
// FB Login Function
export type FacebookLoginFunc = (params: FacebookLoginParams) => Promise<void>

// Log Out Func.
export type LogOutFunc = () => void

export interface RecurringEventPayload {
  until?: Date
  interval: number
  is_recurring: true
}

export interface DailyRecurringEventPayload extends RecurringEventPayload {
  frequency: EventFrequency.DAILY
  by_week_days?: Array<Weekday>
  month_week_nos?: Array<EEventMonthlyFrequency>
}

export interface WeeklyRecurringEventPayload extends RecurringEventPayload {
  frequency: EventFrequency.WEEKLY
  by_week_days: Array<Weekday>
  month_week_nos?: Array<EEventMonthlyFrequency>
}

export interface MonthlyRecurringEventPayload extends RecurringEventPayload {
  frequency: EventFrequency.MONTHLY
  by_week_days: Array<Weekday>
  month_week_nos?: Array<EEventMonthlyFrequency>
}

export interface YearlyRecurringEventPayload extends RecurringEventPayload {
  frequency: EventFrequency.YEARLY
  by_week_days?: Array<Weekday>
  month_week_nos?: Array<EEventMonthlyFrequency>
  // by_month_days: Array<number>
  // by_months: Array<number>
}

// Klyk Event.
export interface NookEventBase {
  id: number
  category: Category
  status: number
  duration: number
  participants: User[]
  waiting_list: User[]
  is_user_waiting: boolean
  host: EventHost
  is_full_booked: boolean
  participants_number: number
  is_user_host: boolean
  is_user_going: boolean
  is_user_decline: boolean
  title: string
  location: string
  group_id: number | null
  group_size: number | null
  video_url: string
  descr: string
  participation_descr: string
  start_date: string
  event_type: number
  video_host_type: number
  equipments: Equipment[]
  image: string
  image_url: string
  category_id: number
  host_bio: string
  endTime: Date
  startTime: Date
  selectedDate: Date
  imageFile: File | null
  invites: Invite[]
  hosted_group: EventHost
  event_paid_type: number //Free: 0, Paid: 1
  price: number
  currency: string
  event_url: string
  is_limit_attendees_ticked: boolean
  is_set_duration_ticked: boolean
  notify_participant: boolean
  updated: string
  created_at: string
  end_date?: string
}

type RegularEvent = {
  is_recurring?: false
}
type RecurringEvent =
  | DailyRecurringEventPayload
  | WeeklyRecurringEventPayload
  | MonthlyRecurringEventPayload
  | YearlyRecurringEventPayload

export type NookEvent = NookEventBase & XOR<RegularEvent, RecurringEvent>

export interface NookEventError {
  category_id: string[]
  duration: string[]
  title: string[]
  selectedDate: string[]
  startTime: string[]
  group_size: string[]
  invites: string[][]
  video_url: string[]
  video_host_type: string[]
  event_paid_type: string[]
  currency: string[]
  price: string[]
  until: string[]
}

export interface Invite {
  id?: number
  event_id: number
  email: string
  status?: number
}

export interface Invites {
  invites: Invite[]
}

export interface InvitesResponse {
  id: number
  email: string
  event_id: number
  status: number
}

export interface InviteError {
  errors: string[][]
  hasError: boolean
}

export interface ValidationError {
  errors: string[]
  hasError: boolean
}

export interface Equipment {
  id?: number
  descr: string
}

// Recover Password Func.
export type RecoverPasswordWithEmailFunc = (email: string) => Promise<void>

// Remove User From Event Func.
export type RemoveCurrentUserFromEventFunc = (eventId: number) => Promise<void>

export type GenericFunctionUsingEventIdReturningPromisVoid = (
  eventId: number,
) => Promise<void>

// Remove Participant From Event Hook Func.
export type RemoveParticipantFromEventFunc = (
  eventId: number,
  participantId: number,
) => Promise<void>

// Remove User From Event Hook Func.
export type RemoveCurrentUserFromEventHookFunc = () => Promise<void>

// Remove Participant From Event Hook Func.
export type RemoveParticipantFromEventHookFunc = (
  participantId: number,
) => Promise<void>

// Reset Password Func.
export type ResetPasswordFunc = (params: {
  password: string
  token: string
}) => Promise<void>

// Sign Up Func.
type SignUpParams = {
  email: string
  password1: string
  password2: string
  full_name?: string
  time_zone?: string
}
export type SignUpFunc = (params: SignUpParams) => Promise<void>

// Upload Image With Event Hook Func.
export type UploadImageWithEventHookFunc = (image: File) => Promise<void>

// Upload Image To Event Func.
export type UploadImageFunc = (id: number, image: File) => Promise<void>

// Remove Image With Event Hook Func.
export type RemoveImageWithEventHookFunc = () => Promise<void>

// Remove Image to Event Func
export type RemoveImageFromEventFunc = (eventId: number) => Promise<void>

// Delete Event With Event Hook Func.
export type DeleteEventWithEventHookFunc = (note?: string) => Promise<void>

//Delete Event
export type DeleteEventFunc = (
  eventId: number,
  deleteNote?: string,
) => Promise<void>

// Update Event Func.
export type UpdateEventFunc = (
  partial: Partial<NookEvent>,
) => Promise<NookEvent>

// Update Event with Event Hook Func
export type UpdateEventWithEventHookFunc = () => Promise<void>

//Create New Invite for Event
export type CreateInvitesForEventFunc = (
  eventId: number,
  emails: string[],
) => Promise<Invite[]>

//Create New Invite With Event Hook Func
export type CreateInvitesWithEventHookFunc = (emails: string[]) => Promise<void>

// Fetch Invites
export type FetchInvitesFunc = (eventId: number) => Promise<Invite[]>

// Update Event Field Func
export type UpdateEventFieldsWithPartialFunc = (
  partial: Partial<NookEvent>,
) => void

// Update Event Error Field Func
export type UpdateEventErrorFieldsWithPartialFunc = (
  partial: Partial<NookEventError>,
) => void

// Reset Event All Error Func
export type ResetEventAllErrorFunc = () => void

// Validate Event Func
export type ValidateEventFunc = () => boolean

// UnAuth Zoom
export type UnAuthZoomFunc = () => Promise<void>

/*    G R O U P S    */

export enum GroupModalType {
  NormalGroup = 'NORMAL_GROUP',
  NormalStripe = 'NORMAL_STRIPE',
  DropDownGroup = 'DROP_DOWN_GROUP',
  DropDownStripe = 'DROP_DOWN_STRIPE',
}

export enum CurrencyType {
  IDR = 'idr',
  USD = 'usd',
}

export type XenditStatus =
  | 'INVITED'
  | 'REGISTERED'
  | 'AWAITING DOCS'
  | 'KYC SUBMITTED'
  | 'LIVE'

// Group
export interface Group {
  id: number
  name: string
  hero: string | null
  hero_url: string | null
  heroFile: File | null
  avatar: string | null
  avatar_url: string | null
  avatarFile: File | null
  url: string
  bio: string | null
  website: string | null
  time_zone: string
  group_type: number
  follower_number: number
  is_user_following: boolean
  has_stripe_account: boolean
  xendit_account_status?: XenditStatus
  demo_event_url?: string
  has_xendit_account: boolean
}

export interface MyGroup extends Group {
  current_balance: number
  stripe_dashboard: string
  has_xendit_account: boolean
  xendit_account_status?: XenditStatus
}

// Get group slug response
export type GetGroupSlugResponse = {
  slug: string
}

// Create Group Stripe Account Params
export interface CreateGroupStripeAccountParams {
  authorizationCode: string
  groupId: string
}

// Fetch My Group
export type FetchMyGroupFunc = (id: any) => Promise<Partial<MyGroup>>

// Fetch Group
export type FetchGroupFunc = (id: any) => Promise<Partial<Group>>
export type FetchGroupBySlugFunc = (slug: string) => Promise<Group>

// Follow Group
export type FollowGroupFunc = (id: number) => Promise<Group>
export type UnFollowGroupFunc = (id: number) => Promise<Group>

//Insert Group Func.
export type InsertGroupFunc = (group: Partial<Group>) => void

// Create group
export type CreateGroupFunc = (params: Partial<Group>) => Promise<Group>

// Edit group
export type EditGroupFunc = (params: Partial<Group>) => Promise<Group>

// Delete group
export type DeleteGroupFunc = (id: number) => Promise<boolean>

// Fetch group events
export type FetchGroupEventsFunc = (id: number) => Promise<NookEvent[]>

// Get group slug suggestion
export type GetGroupSlugSuggestionFunc = (name: string) => Promise<string>

// Create Group Stripe Account
export type CreateGroupStripeAccountFunc = (
  params: CreateGroupStripeAccountParams,
) => Promise<Group>

// User.
export interface User {
  email: string
  first_name: string
  host_bio: string | null
  id: number
  image: string | null
  last_name: string
  full_name: string
  is_social_account: boolean
  location: string | null
  nickname: string | null
  time_zone: string
  has_zoom_access_token: boolean
  intercom_hmac: string
  has_created_first_event: boolean
  managed_groups: MyGroup[]
  zoom_email: string | null
  invite_email_limit: number
  is_express_account?: boolean
  date_joined: string
  last_login: string
  xendit_redirect_url?: string
}

export interface SocialContact {
  email: string
  name: string
}

export interface GoogleContactsParams {
  peopleApi: gapi.client.people.PeopleResource
  current: gapi.client.people.ListContactGroupsResponse[]
  pageToken: string | null
}

export type RetrieveGoogleContactsFunc = (
  params: GoogleContactsParams,
) => Promise<SocialContact[]>

export enum AuthType {
  UsernamePassword = 'USERNAMEPASSWORD',
  Google = 'GOOGLE',
  Facebook = 'FACEBOOK',
}

// Retrieve Contacts From Google Client Hook Func.
export type RetrieveContactsFromGoogleClientHookFunc = () => void

export type ResetErrorFromGoogleClientHookFunc = () => void

// Init State Func.
export type InitSGoogleClientStateHookFunc = () => void

export type GoogleClientHook = () => GoogleClientHookInterface
export interface GoogleClientHookInterface {
  retrieveContactsFromGoogle: RetrieveContactsFromGoogleClientHookFunc
  enableGoogleContacts: boolean
  error: string
  resetError: ResetErrorFromGoogleClientHookFunc
  syncing: boolean
  totalSynced: number
}

export interface SendAmplitudeEventParams {
  type: string
}
export type SendAmplitudeEventFunc = (
  params: SendAmplitudeEventParams,
) => Promise<void>

export type UpdateSocialContactsFunc = (sc: SocialContact[]) => void

/***********************************************************************************/
/**************                   Analytics Event                     **************/

export interface AnalyticsEventShareEvent {
  eventId: number
  source: string
  type: string
}

export type SendEventAnalyticsEventHookFunc = () => void
export type SendEventWithStringAnalyticsEventHookFunc = (name: string) => void
export type SendEventWithNookEventAnalyticsEventHookFunc = (
  nookEvent: NookEvent,
) => void
export type SendEventWithGroupAnalyticsEventHookFunc = (group: Group) => void
export type SendEventWithInvitationsAnalyticsEventHookFunc = (
  invites: Invite[],
) => void
export type SendEventWithShareAnalyticsEventHookFunc = (
  share: AnalyticsEventShareEvent,
) => void

export interface AnalyticsEventHookInterface {
  eventPublished: SendEventWithNookEventAnalyticsEventHookFunc
  signupLinkClicked: SendEventWithStringAnalyticsEventHookFunc
  signupCompleted: SendEventWithStringAnalyticsEventHookFunc
  profileUpdateButtonClicked: SendEventAnalyticsEventHookFunc
  updateEventButtonClicked: SendEventAnalyticsEventHookFunc
  onboardingPromptToCreateFirstEvent: SendEventAnalyticsEventHookFunc
  onboardingPromptToLearnGroup: SendEventAnalyticsEventHookFunc
  groupExpressionOfInterest: SendEventWithStringAnalyticsEventHookFunc
  groupExpressionOfInterestForStripe: SendEventWithStringAnalyticsEventHookFunc
  groupCreateGroupAccount: SendEventWithGroupAnalyticsEventHookFunc
  discoverViewEvent: SendEventWithStringAnalyticsEventHookFunc
  eventsRegisterForEvent: SendEventWithStringAnalyticsEventHookFunc
  eventsDeRegisterForEvent: SendEventAnalyticsEventHookFunc
  eventsAddToCalendar: SendEventWithStringAnalyticsEventHookFunc
  myEventsVisitsPage: SendEventAnalyticsEventHookFunc
  createEventClicksCTA: SendEventWithStringAnalyticsEventHookFunc
  manageEventInvitationsSent: SendEventWithInvitationsAnalyticsEventHookFunc
  manageEventRemoveParticipant: SendEventAnalyticsEventHookFunc
  manageEventCancelEvent: SendEventAnalyticsEventHookFunc
  groupCreateStripeAccount: SendEventAnalyticsEventHookFunc
  discoverDiscoverHomepage: SendEventAnalyticsEventHookFunc
  discoverFilterByCategory: SendEventWithStringAnalyticsEventHookFunc
  discoverViewGroup: SendEventWithStringAnalyticsEventHookFunc
  discoverFollowGroup: SendEventWithStringAnalyticsEventHookFunc
  discoverUnFollowGroup: SendEventWithStringAnalyticsEventHookFunc
  eventsShareEvent: SendEventWithShareAnalyticsEventHookFunc
  accountDeleted: SendEventAnalyticsEventHookFunc
  accountAuthorizeZoom: SendEventWithStringAnalyticsEventHookFunc
  accountSignIn: SendEventWithStringAnalyticsEventHookFunc
  fbAddPaymentInfo: SendEventAnalyticsEventHookFunc
  fbContact: SendEventWithStringAnalyticsEventHookFunc
  fbCustomizeProduct: SendEventWithStringAnalyticsEventHookFunc
  fbPurchase: SendEventWithStringAnalyticsEventHookFunc
  fbInitiateCheckOut: SendEventAnalyticsEventHookFunc
  fbViewContent: SendEventAnalyticsEventHookFunc
  fbLead: SendEventAnalyticsEventHookFunc
  fbCompleteRegistration: SendEventWithStringAnalyticsEventHookFunc
  fbSubmitApplication: SendEventAnalyticsEventHookFunc
  websiteVisit: SendEventWithStringAnalyticsEventHookFunc
  express: {
    expressIntentToAttend: () => void
    expressEventsRegisterForEvent: (paidType: string, userEmail: string) => void
    expressParticipantFirstTimeCreated: (userEmail: string) => void
  }
}

export type AnalyticsEventHook = () => AnalyticsEventHookInterface

/***********************************************************************************/
/**************                   Facebook Pixel                      **************/

export interface FacebookPixelEvent {
  name: string
  props?: object
}
export type FacebookPixelHookFunc = () => void
export type FacebookPixelTrackEventHookFunc = (
  event: FacebookPixelEvent,
) => void

export interface FacebookPixelHookInterface {
  pageView: FacebookPixelHookFunc
  track: FacebookPixelTrackEventHookFunc
}

export type FacebookPixelHook = () => FacebookPixelHookInterface

/***********************************************************************************/
/**************                   Timezone                            **************/

export interface Timezone {
  name: string
  display: string
  alternative_name: string
}

export type retrieveTimezonesFunc = () => Promise<Timezone[]>

/***********************************************************************************/
/***********************************Categories**************************************/

export interface Category {
  id: number
  title: string
  emoji: string
  background_colour: string
}

// Fetch Current User Func.
export type FetchCategoriesFunc = () => Promise<Category[]>

export enum XenditPaymentInvoiceStatus {
  CREATED = 'CREATED',
  PAID = 'PAID',
  SUCCESSFUL = 'SUCCESSFUL',
  PROCESSING = 'PROCESSING',
  REFUND_PENDING = 'REFUND_PENDING',
  REFUNDED = 'REFUNDED',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}

/***********************************************************************************/
/*************************              Stripe         *****************************/
export interface PaymentIntent {
  status: string
  user_id: number
  payment_intent_id: string
  event_id: number
  amount: number
  client_secret: string
}

// Create PaymentIntent Parameters
export interface CreatePaymentIntentParams {
  event_id: number
  group_id: number
}

export interface ExpressCreatePaymentIntentParams {
  event_id: number
  group_id: number
  email: string
}

// Create PaymentIntent
export type CreatePaymentIntentFunc = (
  params: CreatePaymentIntentParams,
) => Promise<PaymentIntent>
export type ExpressPaymentIntentFunc = (
  params: ExpressCreatePaymentIntentParams,
) => Promise<PaymentIntent>

/***********************************************************************************/
/*************                   OAuthState                            *************/

export interface OAuthState {
  user: EventHost
  id: number
  redirect_uri: string
  created: string
  expired: string | null
}

export type createOAuthStateFunc = (redirectUri: string) => Promise<OAuthState>

/***********************************************************************************/
/*************                   Toast                                 *************/

export enum ToastType {
  Success = 'success',
  Info = 'information',
  Error = 'error',
  Warning = 'warning',
}

export interface Toast {
  id: string
  icon?: string
  toastType: ToastType
  title: React.ReactNode
  dismissTime?: number
  content?: React.ReactNode
  action?: React.ReactNode
  onClose?: () => void
}

export type AddToastFunc = (toast: Toast) => void
export type RemoveToastFunc = (toastId: string) => void

/***********************************************************************************/
/*************                     UTM                                 *************/

export interface UtmParas {
  utm_source: string
  utm_medium: string
  utm_campaign: string
  utm_content: string
  utm_term: string
}

export interface FilterParams {
  paid?: '0' | '1' | 'Reset/all'
  category?: string | 'Reset/all'
  sort?: string
}
