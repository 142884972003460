// Dependencies.
import { keyframes } from 'styled-components'

// Fade In.
export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

// Fade In Out
export const fadeInOut = keyframes`
  0%, 100% {
    opacity: 0 
  }

  15%, 85% {
    opacity: 1 
  }
`

// Fade Down.
export const fadeDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

// Menu Fade Down.
export const menuFadeDown = keyframes`
  0% {
    opacity: 0;
    transform: translate(0, -10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0, 0);
  }
`

// Fade Up.
export const fadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`
