export const FORMATTER = {
  numberPlace: function (num: number) {
    switch (num) {
      case 1:
        return '1st'
      case 2:
        return '2nd'
      case 3:
        return '3rd'
    }
    return `${num}th`
  },

  objectToQueryString: function (
    obj: Record<string, string | number | boolean | number[]>,
  ) {
    const params = []
    Object.keys(obj).forEach((key, index) => {
      const value = obj[key]
      if (value !== undefined && key !== undefined) {
        // encodeURIComponent doesn't support arrays, but for some reason, it was used here and implemented support on the server-side. To support backward compatibility here explicit convertation happens
        const valueToEncode = Array.isArray(value) ? value.join(',') : value
        params.push(
          `${encodeURIComponent(key.trim())}=${encodeURIComponent(
            valueToEncode,
          )}`,
        )
      }
    })
    return '?' + params.join('&')
  },
}
