// Libs
import React, { useEffect, useContext, useState } from 'react'
import Router from 'next/router'

// Comps
import { AuthType, SocialContact, GoogleClientHook } from '../types'
import { SESSIONSTORAGE } from '../utils/sessionStorage'
import { LOCALSTORAGE } from '../utils/localStorage'
import { UserContext } from '../context'
import { show, showFinally } from '../utils'

// Google tag manager

// If we need more events consider using
// https://www.npmjs.com/package/@analytics/google-tag-manager
// interface GtmProps {
//   gtmID: String
// }

// interface PageEventProps {
//   event: string;
//   page: string;
// }

// export const GTM: React.FC<GtmProps> = ({
//   gtmID = ''
// }) => {

//   const {trackPageChange} = useAnalytics(gtmID);
//   // // console.log(pcs);
//   // // Calc
//   // const gmtEnabled = (gtmID !== undefined && gtmID !== '')
//   //   ? true
//   //   : false;

//   // const gtmPageView = (url: string) => {
//   //   if (gmtEnabled === true) {
//   //     const pageEvent: PageEventProps = {
//   //       event: 'pageview',
//   //       page: url,
//   //     };
//   //     //@ts-ignore
//   //     window && window.dataLayer && window.dataLayer.push(pageEvent);
//   //     return pageEvent;
//   //   }
//   // }

//   // On router change ping GTM
//   useEffect(() => {
//     trackPageChange();
//     const handleRouteChange = () => trackPageChange();
//     Router.events.on('routeChangeComplete', handleRouteChange);
//     return () => {
//         Router.events.off('routeChangeComplete', handleRouteChange);
//     };
//   }, []);

//   // ..
//   return <>
//     {/* {show(gmtEnabled === true) && <> */}
//       {/* Google Tag Manager */}
//       {/* <script */}
//         {/* dangerouslySetInnerHTML={{ */}
//             {/* __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': */}
//             {/* new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], */}
//             {/* j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= */}
//             {/* 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); */}
//             {/* })(window,document,'script','dataLayer','${gtmID}');`, */}
//         {/* }} */}
//       {/* /> */}
//       {/* End Google Tag Manager */}
//       {/* Google Tag Manager (noscript) */}
//       {/* <noscript */}
//           {/* dangerouslySetInnerHTML={{ */}
//               {/* __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`, */}
//           {/* }} */}
//       {/* /> */}
//       {/* End Google Tag Manager (noscript) */}
//     {/* </>} */}
//   </>
// }

// import Analytics, {AnalyticsInstance} from 'analytics'
// import googleTagManager from '@analytics/google-tag-manager'

// type GtmFunctionParams = {
//   eventTitle?: String
//   eventId?: String
//   groupTitle?: String
//   groupID?: String
// }

// export const useAnalytics = (gtmId?: String) => {
//   // Global State
//   const { currentUser, fetchCurrentUser, sendAmplitudeEvent } = useContext(UserContext)

//   // Local State
//   const [analytics, setAnalytics] = useState<AnalyticsInstance>(null);

//   // On Client Side
//   useEffect(() => {

//     if (gtmId !== null && gtmId !== undefined) {
//       // console.log(gtmId);
//       setAnalytics(Analytics({
//         // app: 'our-klyk',
//         plugins: [
//           googleTagManager({
//             containerId: gtmId
//           })
//         ]
//       }));
//     }
//   },[]);

//   const trackEventPublished = (params: GtmFunctionParams) => {

//     if (analytics !== null) {
//       analytics.track('eventPublished', {
//         firstName: currentUser?.first_name,
//         lastName: currentUser?.last_name,
//         email: currentUser?.email,
//         userID: currentUser?.id,
//         eventTitle: params.eventTitle,
//         eventID: params.eventId
//       })
//     } else {
//       console.log('@useTGM->trackEventPublished analytics not set. Try running function after init. Try running on client and not on SSR');
//     }
//   }

//   const trackPageChange = () => {
//     console.log(analytics);
//     if (analytics !== null) {
//       analytics.page()
//     } else {
//       console.log('@useTGM->trackPageChange analytics not set. Try running function after init. Try running on client and not on SSR');
//     }
//   }

//   return {
//     trackEventPublished: trackEventPublished,
//     trackPageChange: trackPageChange
//   };

// }

// Use Google Analytics.
export const useGoogleAnalytics = (id: string) => {
  useEffect(() => {
    const WINDOW = window as any
    WINDOW.dataLayer = WINDOW.dataLayer || []
    function gtag(...args: any) {
      WINDOW.dataLayer.push(args)
    }
    gtag('js', new Date())
    gtag('config', id)
  }, [])
}

export const useGoogleClient: GoogleClientHook = () => {
  const client_id =
    process.env.NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID ||
    '487379500128-liuuln3mle6ellfsbg6mvtnbv0doseds.apps.googleusercontent.com'
  const api_key =
    process.env.NEXT_PUBLIC_GOOGLE_CLIENT_API_KEY ||
    'AIzaSyBUtMX2VdzGyqcpOwzMVTRMQnYnNQ2FTig'
  const read_contact_scope = 'https://www.googleapis.com/auth/contacts.readonly'
  const googleConfig: gapi.auth2.ClientConfig = {
    client_id:
      process.env.NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID ||
      '487379500128-liuuln3mle6ellfsbg6mvtnbv0doseds.apps.googleusercontent.com',
    cookie_policy: 'single_host_origin',
    scope: 'profile email openid',
  }

  let auth2: gapi.auth2.GoogleAuth = null

  const GOOGLE_POPUP_ERROR_MESSAGE =
    'Your browser blocked a popup required to continue any further. Please enable popups and try again.'
  const GOOGLE_ACCESS_DENIED_ERROR_MESSAGE = 'Access denied.'

  // Context
  const { currentUser, retrieveGoogleContacts, updateSocialContacts } =
    useContext(UserContext)

  const [enableGoogleContacts, setEnableGoogleContacts] = useState(false)
  const [error, setError] = useState('')
  const [syncing, setSyncing] = useState(false)
  const [totalSynced, setTotalSynced] = useState(-1)

  const googleContacts = SESSIONSTORAGE.getGoogleContacts()
  const doneRetrieveGoogleContacts =
    SESSIONSTORAGE.getDoneRetrieveGoogleContacts()

  const variables = { enableGoogleContacts, error, syncing, totalSynced }

  useEffect(() => {
    if (!doneRetrieveGoogleContacts) {
      setEnableGoogleContacts(true)
    }
    initGoogleContactsState()
  }, [])

  const initGoogleContactsState = () => {
    if (googleContacts) {
      updateSocialContacts(JSON.parse(googleContacts))
      setEnableGoogleContacts(false)
    }
  }

  const resetError = () => {
    setError('')
  }

  const retrieveContactsFromGoogle = () => {
    //if(authType && authType === AuthType.Google){
    // if(googleContacts){
    //   setSocialContacts(JSON.parse(googleContacts))
    //   setEnableGoogleContacts(false)
    // }
    // else{
    if (!doneRetrieveGoogleContacts) {
      const aScript = document.createElement('script')
      aScript.type = 'text/javascript'
      aScript.src = 'https://apis.google.com/js/api.js'

      document.head.appendChild(aScript)
      aScript.onload = () => {
        const getGoogleContacts = () => {
          window.gapi.load('client:auth2', () => {
            window.gapi.client.load('people', 'v1', () => {
              window.gapi.client
                .init({
                  apiKey: api_key,
                })
                .then(() => {
                  setSyncing(true)
                  retrieveGoogleContacts({
                    peopleApi: window.gapi.client.people.people,
                    current: [],
                    pageToken: undefined,
                  })
                    .then((res) => {
                      SESSIONSTORAGE.setGoogleContacts(JSON.stringify(res))
                      updateSocialContacts(res)
                      SESSIONSTORAGE.setDoneRetrieveGoogleContacts('TRUE')
                      setEnableGoogleContacts(false)
                      setTotalSynced(res.length)
                    })
                    .catch((error) => {
                      console.log(
                        'erroor retrieveGoogleContacts: ' +
                          JSON.stringify(error),
                      )
                      setError(JSON.stringify(error))
                    })
                    .finally(() => {
                      //SESSIONSTORAGE.setDoneRetrieveGoogleContacts("TRUE")
                      //setEnableGoogleContacts(false)
                      setSyncing(false)
                    })
                })
                .catch((error) => {
                  console.log('erroor innit client: ' + JSON.stringify(error))
                })
            })
          })
        }
        const grantAdditionalScope = (googleUser: gapi.auth2.GoogleUser) => {
          const grantedScopes = googleUser.getGrantedScopes().split(' ')
          if (grantedScopes.includes(read_contact_scope)) {
            getGoogleContacts()
          } else {
            const option = new gapi.auth2.SigninOptionsBuilder()
            option.setScope(read_contact_scope)
            googleUser
              .grant(option)
              .then(
                function (success) {
                  getGoogleContacts()
                },
                function (fail) {
                  console.log(
                    'fail grant additional permission: ' + JSON.stringify(fail),
                  )
                  let errorMessage: string = JSON.stringify(fail)
                  if (fail.error) {
                    const googleError = fail.error
                    if (googleError.includes('popup_blocked_by_browser')) {
                      errorMessage = GOOGLE_POPUP_ERROR_MESSAGE
                    } else if (googleError.includes('access_denied')) {
                      errorMessage = GOOGLE_ACCESS_DENIED_ERROR_MESSAGE
                    }
                  }
                  setError(errorMessage)
                  //SESSIONSTORAGE.setDoneRetrieveGoogleContacts("TRUE")
                  //setEnableGoogleContacts(false)
                },
              )
              .catch((error) => {
                setError(JSON.stringify(error))
                console.log('error grant: ' + JSON.stringify(error))
              })
          }
        }
        window.gapi.load('auth2', function () {
          auth2 = window.gapi.auth2.init(googleConfig)
          if (!auth2.isSignedIn.get()) {
            auth2
              .signIn({
                ...(currentUser ? { login_hint: currentUser.email } : {}),
              })
              .then((googleUser) => {
                grantAdditionalScope(googleUser)
              })
              .catch((reason) => {
                let errorMessage: string = JSON.stringify(reason)
                if (
                  reason.error &&
                  reason.error.includes('popup_closed_by_user')
                ) {
                  errorMessage = GOOGLE_POPUP_ERROR_MESSAGE
                }
                setError(errorMessage)
                console.log('error signin: ' + JSON.stringify(reason))
              })
          } else {
            const googleUser = auth2.currentUser.get()
            grantAdditionalScope(googleUser)
          }
        })
      }
    }
    //}
    //}
  }

  const functions = {
    retrieveContactsFromGoogle,
    initGoogleContactsState,
    resetError,
  }

  // ..
  return { ...variables, ...functions }
}
