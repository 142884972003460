// Dependencies.
import styled from 'styled-components'
import Link, { LinkProps } from 'next/link'

// A.
const A = styled.a`
  &.buttonLink {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.5px;
    line-height: 110%;
    text-decoration: none;
  }

  &.active {
    text-decoration: underline;
  }

  &:hover {
    text-decoration: underline;
  }
`

// Props.
interface Props extends LinkProps {
  className?: string
  style?: React.CSSProperties
  newTab?: boolean
  onClick?: (evt) => void
}

// Hyperlink.
export const Hyperlink: React.FC<Props> = ({
  children,
  className,
  style,
  newTab,
  onClick,
  ...props
}) => {
  const CLASSNAME = `hyperlink ${className || ''}`

  // Outbound Link.
  if (typeof props.href === 'string' && props.href.substr(0, 1) !== '/')
    return (
      <A
        className={CLASSNAME}
        href={props.href}
        style={style}
        target={!newTab ? '' : '_blank'}
        rel="noopener">
        {children}
      </A>
    )

  // ..
  return (
    <Link {...props} passHref>
      {newTab ? (
        <A
          className={CLASSNAME}
          onClick={onClick}
          style={style}
          target="_blank"
          rel="noopener">
          {children}
        </A>
      ) : (
        <A className={CLASSNAME} onClick={onClick} style={style}>
          {children}
        </A>
      )}
    </Link>
  )
}
