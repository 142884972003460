import { useRouter } from 'next/router'
import { useCallback, useContext, useMemo, useState } from 'react'

import { API } from '../API'
import { Context as UserContext } from '../context/User'
import { GroupId } from '../entities/GroupId'
import { MyGroup } from '../types'

export function useXendit() {
  const { currentUser, loaded } = useContext(UserContext)

  const [xenditStatus, setXenditStatus] = useState(null)

  const router = useRouter()

  // NOTE: different accounts for different groups is not supported
  // i.e. groupId is passing for the future possible implementation
  const connect = useCallback(
    (groupId: GroupId, redirectUrl = '') => {
      if (currentUser !== null) {
        const { email } = currentUser
        return API.post(`/xendit/create_account/${groupId}/`, {
          email,
          xendit_redirect_url: redirectUrl,
          type: 'OWNED',
          public_profile: {
            business_name:
              currentUser.managed_groups[0].name || currentUser.full_name,
          },
        }).then((res: any) => {
          const status = res.data.event_url

          setXenditStatus(status)
          //   router.replace(status)
        })
      }
      return Promise.reject('user is not authorized')
    },
    [currentUser],
  )

  const createInvoice = useCallback(
    (
      {
        eventId: event_id,
        groupId: group_id,
      }: { eventId: number; groupId: number },
      params: {
        success_redirect_url: string
        failure_redirect_url: string
      },
    ) => {
      return API.post(`/xendit/create_invoice/${event_id}/${group_id}/`, params)
    },
    [],
  )

  const clearRedirectUrl = useCallback(() => {
    return API.post(`/xendit/clear_xendit_redirect_url/`, {})
  }, [])

  const getPaymentInvoice = useCallback((eventId) => {
    return API.get(`/xendit/payment_status/${eventId}`)
  }, [])

  const getGroupBalance = useCallback(async (groupId) => {
    const response = await API.get<MyGroup>(
      `/xendit/retrieve_balance/${groupId}`,
    )
    return response.data
  }, [])

  const queryIsSetupInProgress = useCallback(async (): Promise<boolean> => {
    if (xenditStatus) {
      return Promise.resolve(xenditStatus)
    }

    return Promise.resolve(false)
  }, [])

  const isSuggestionToRedirectToCreateEventRequired = useMemo(() => {
    // currentUser
    if (!loaded) {
      return false
    }
    return (
      typeof currentUser?.xendit_redirect_url === 'string' &&
      currentUser?.xendit_redirect_url.length > 0
    )
  }, [currentUser, loaded])

  return {
    connect,
    createInvoice,
    clearRedirectUrl,
    queryIsSetupInProgress,
    isSuggestionToRedirectToCreateEventRequired,
    getPaymentInvoice,
    getGroupBalance,
  }
}
