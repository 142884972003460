import { useTranslation } from 'next-i18next'
// Dependencies.
import { useContext } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import { UserContext } from '../../context'
import { SignUpCTA } from '../SignUpCTA'
import { LogInCTA } from '../LogInCTA'
import { CreateEventCTA } from '../CreateEventCTA'
import { Hyperlink } from '../Hyperlink'
import { CloseIcon } from '../CloseIcon'
import { P } from '../P'

const SIGNUPCTA = styled(SignUpCTA)`
  &.signup {
    margin-left: 30px;
  }
`

// Props.
interface Props {
  className?: string
  closeNav?: () => void
}

// CTA.
export const NavigationBarCTA: React.FC<Props> = ({ className, closeNav }) => {
  const { t } = useTranslation('common')

  // Router.
  const { pathname } = useRouter()

  // Current User
  const { currentUser } = useContext(UserContext)

  // Event Creation Page.
  // if (pathname === '/events/create') return <Hyperlink className={className} href="/myevents">
  //   <CloseIcon />
  // </Hyperlink>

  // this case is now handled one level up in NavigationBar.tsx
  // if (pathname === '/events/create') return null

  // Log In Page.
  if (pathname === '/login')
    return (
      <>
        <P className={className} style={{ marginRight: '30px' }}>
          {t(`CTA2090188829`, `Don't have an account?`)}
        </P>{' '}
        <SignUpCTA />
      </>
    )

  // Sign Up Page.
  if (pathname === '/signup')
    return (
      <>
        <P className={className} style={{ marginRight: '30px' }}>
          {t(`CTA-1993313675`, `Already have an account?`)}
        </P>{' '}
        <LogInCTA />
      </>
    )

  if (!currentUser && className === 'desktopOnly')
    return (
      <SIGNUPCTA
        className={`signup ${className}`}
        buttonText={t(`Get started`, `Get started`)}
      />
    )

  if (currentUser)
    return (
      <CreateEventCTA
        closeNav={closeNav}
        className={className}
        userLoggedIn={currentUser ? true : false}
      />
    )

  return null
}
