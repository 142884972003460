// Dependencies.
import styled, { css } from 'styled-components'

import { forScreensGreaterThanMobileWidth, mobileScreen } from '../utils'

// Section.
export const SECTION = styled.section``

// Div.
export const Div = styled.div`
  box-sizing: border-box;
  margin: auto;
  width: 100%;
  padding: 0 44px;
  max-width: 1366px;

  ${mobileScreen(css`
    padding: 0 20px;
  `)}
  &.unlockWidthConstraint {
    padding: 0;
    width: 100%;
  }

  ${forScreensGreaterThanMobileWidth(css`
    padding: 0 40px;
  `)}
`

// Props.
interface Props {
  className?: string
  unlockWidthConstraint?: boolean
}

// Section.
export const Section: React.FC<Props> = (props) => {
  // Props.
  const { className, unlockWidthConstraint } = props

  // ..
  return (
    <SECTION className={`section ${className || ''}`}>
      <Div
        className={`content ${
          (unlockWidthConstraint && 'unlockWidthConstraint') || ''
        }`}>
        {props.children}
      </Div>
    </SECTION>
  )
}
