import { useTranslation } from 'next-i18next'
import React, { useState, useContext } from 'react'
import { useRouter } from 'next/router'
import styled, { css } from 'styled-components'

import { forScreensGreaterThanMobileWidth } from '../../utils/styles'
import { UserDropdownMenu } from './UserDropdownMenu'
import { Hyperlink } from '../Hyperlink'
import { MyEventsCTA } from '../MyEventsCTA'
import { NavigationBarCTA } from './CTA'
import { colors } from '../../utils'
import { UserContext } from '../../context'
import { SignUpCTA } from '../SignUpCTA'
import { useAnalyticsEvent } from '../../hooks/analyticsEvent'

const SIGNUPCTA = styled(SignUpCTA)`
  &.signup {
    margin-left: 30px;
  }
`

export const MobileNavigation = (props) => {
  const { t } = useTranslation('common')
  const [navOpen, setNavOpen] = useState(false)

  // Router
  const router = useRouter()

  const { currentUser } = useContext(UserContext)

  const analyticsEvent = useAnalyticsEvent()

  const toggleNav = () => setNavOpen(!navOpen)
  const handleLinkClick = (evt, href) => {
    evt.preventDefault()
    toggleNav()
    router.push(href)
  }

  const closeNav = () => setNavOpen(false)

  const {
    links,
    isHomePage,
    isLogInPage,
    isSignUpPage,
    isAuthPage,
    isEventCreationPage,
    isGroupCreationPage,
    isProfilePage,
    mobileOnly,
    isDashboardEditPage,
  } = props

  return (
    <Div isHomePage={isHomePage}>
      <button
        className={navOpen ? 'navButton open' : 'navButton'}
        onClick={toggleNav}>
        {navOpen ? (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
              x="1.63672"
              y="0.22168"
              width="20"
              height="2"
              rx="1"
              transform="rotate(45 1.63672 0.22168)"
              fill={isHomePage ? '#fff' : '#2C2D35'}
            />
            <rect
              x="0.222656"
              y="14.3638"
              width="20"
              height="2"
              rx="1"
              transform="rotate(-45 0.222656 14.3638)"
              fill={isHomePage ? '#fff' : '#2C2D35'}
            />
          </svg>
        ) : (
          <svg
            width="20"
            height="14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
              width="20"
              height="2"
              rx="1"
              fill={isHomePage ? '#fff' : '#2C2D35'}
            />
            <rect
              y="12"
              width="20"
              height="2"
              rx="1"
              fill={isHomePage ? '#fff' : '#2C2D35'}
            />
            <rect
              y="6"
              width="20"
              height="2"
              rx="1"
              fill={isHomePage ? '#fff' : '#2C2D35'}
            />
          </svg>
        )}
      </button>

      <div className={navOpen ? 'mobileNav navOpen' : 'mobileNav navClosed'}>
        {!isEventCreationPage && !isDashboardEditPage && !isGroupCreationPage && (
          <button onClick={closeNav} className="mobileNavCloseButton">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect
                x="1.63672"
                y="0.22168"
                width="20"
                height="2"
                rx="1"
                transform="rotate(45 1.63672 0.22168)"
                fill={isHomePage ? '#fff' : '#2C2D35'}
              />
              <rect
                x="0.222656"
                y="14.3638"
                width="20"
                height="2"
                rx="1"
                transform="rotate(-45 0.222656 14.3638)"
                fill={isHomePage ? '#fff' : '#2C2D35'}
              />
            </svg>
          </button>
        )}

        <div className="navLogo">
          <Hyperlink href="/" style={{ marginRight: 'auto' }}>
            <button onClick={toggleNav}>
              <img
                className="logo"
                src={
                  isHomePage
                    ? '/images/klykWhite.svg'
                    : '/images/klykRegular.svg'
                }
              />
            </button>
          </Hyperlink>
        </div>

        {/* User Dropdown Menu. */}
        {!isAuthPage &&
          !isEventCreationPage &&
          !isDashboardEditPage &&
          !isGroupCreationPage &&
          !isProfilePage && (
            <UserDropdownMenu closeNav={closeNav} className="" />
          )}

        {/* Links. */}
        {!isAuthPage &&
          !isEventCreationPage &&
          !isDashboardEditPage &&
          !isGroupCreationPage &&
          !isProfilePage && (
            <div className="links">
              {/*  */}
              {links.map(({ href, name }) => (
                <Hyperlink
                  onClick={(evt) => handleLinkClick(evt, href)}
                  className="buttonLink link"
                  href={href}
                  key={href}>
                  {/* <button onClick={toggleNav}> */}
                  {name}
                  {/* </button> */}
                </Hyperlink>
              ))}

              {!currentUser && mobileOnly && (
                <Hyperlink
                  onClick={(evt) => {
                    analyticsEvent.signupLinkClicked('header-nav')
                    handleLinkClick(evt, '/signup')
                  }}
                  className="buttonLink link"
                  href={'/signup'}
                  key={'/signup'}>
                  {/* <button onClick={toggleNav}> */}
                  {t(`Get started`, `Get started`)}
                  {/* </button> */}
                </Hyperlink>
              )}
            </div>
          )}

        {/* My Events. */}
        {currentUser &&
          !isAuthPage &&
          !isEventCreationPage &&
          !isDashboardEditPage &&
          !isGroupCreationPage &&
          !isProfilePage && <MyEventsCTA closeNav={closeNav} className={``} />}

        {/* CTA. */}
        {!isProfilePage && (
          <NavigationBarCTA closeNav={closeNav} className={``} />
        )}
      </div>
    </Div>
  )
}

// Styled Components
const Div = styled.div<{ isHomePage: boolean }>`
  ${forScreensGreaterThanMobileWidth(css`
    display: none;
    visibility: hidden;
  `)}

  .navButton {
    background: none;
    border: none;
    outline: none;
  }

  .mobileNav {
    z-index: 99;
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    ${(props) =>
      props.isHomePage
        ? `background: ${colors.purple};`
        : `background: ${colors.white};`}

    .navLogo {
      position: absolute;
      top: 20px;
      left: 20px;
    }

    .mobileNavCloseButton {
      background: none;
      border: none;
      position: absolute;
      top: 25px;
      right: 20px;
    }

    .links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .links .link,
    .buttonLinkLogin {
      margin: 6px 0;
      margin-right: 0;
      font-size: 27px;
      font-weight: bold;
      letter-spacing: -0.5px;
      line-height: 110%;
      text-align: center;
    }
    .buttonLinkLogin {
    }
    .userPaneDropdownMenu {
      margin: 0;
    }
    .myEventsButton {
      margin-top: 40px;
    }
    .createEventCTA {
      margin-top: 20px;
    }
  }
  .navOpen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .links a button,
  .navLogo button {
    background: none;
    border: none;
    color: inherit;
    font-weight: inherit;
    font: inherit;
  }
`
