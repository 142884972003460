import styled, { css } from 'styled-components'
import { useRouter } from 'next/router'

import { Hyperlink } from '../Hyperlink'
import { Section } from '../Section'
import { Logo } from '../Logo'
import { SiteLinks } from './SiteLinks'
import { Copyright } from './Copyright'
import { Socials } from './Socials'
import { forScreensGreaterThanMobileWidth } from '../../utils'

// Footer.
const FOOTER = styled.footer`
  background: #fafafe;
  padding-top: 60px;
  padding-bottom: 66px;

  .section:first-child {
    padding-bottom: 42px;
  }

  .section .content {
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
  }

  .logo {
    margin: auto;
    margin-bottom: 60px;
  }

  .copyrightAndSocialsSection .content {
    align-items: center;
    flex-direction: column-reverse;
    column-gap: 80px;
    flex-wrap: nowrap;
  }

  /* Desktop. */
  ${forScreensGreaterThanMobileWidth(css`
    .section:first-child {
      padding-bottom: 46px;
    }

    .logo {
      margin: 0;
    }

    .section .content {
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
    }

    .copyrightAndSocialsSection .content {
      align-items: center;
    }
  `)}
`

export const Footer: React.FC = () => {
  const { pathname } = useRouter()
  const isLogInPage = pathname === '/login'
  const isAuthRoute = isLogInPage

  // Is Auth Route.
  if (isAuthRoute) return null // Hide Footer For Auth Routes.

  // ..
  return (
    <FOOTER>
      <Section className="logoAndSiteLinksSection">
        {/* Logo. */}
        <Hyperlink href="/">
          <Logo />
        </Hyperlink>

        {/* Site Links. */}
        <SiteLinks />
      </Section>

      <Section className="copyrightAndSocialsSection">
        {/* Copright. */}
        <Copyright />

        {/* Socials. */}
        <Socials />
      </Section>
    </FOOTER>
  )
}
