import { EEventMonthlyFrequency } from '../../entities'

type WeekLabel = 'first' | 'second' | 'third' | 'fourth' | 'last'
export function getWeekNumberLabel(
  weekNumber: EEventMonthlyFrequency,
): WeekLabel {
  switch (weekNumber) {
    case EEventMonthlyFrequency.FIRST:
      return 'first'
    case EEventMonthlyFrequency.SECOND:
      return 'second'
    case EEventMonthlyFrequency.THIRD:
      return 'third'
    case EEventMonthlyFrequency.FOURTH:
      return 'fourth'
    case EEventMonthlyFrequency.LAST:
      return 'last'
    default:
      throw new Error(`week with number ${weekNumber} is not supported`)
  }
}
