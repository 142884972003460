//Libs

import {
  createContext,
  useEffect,
  useState,
  useContext,
  ReactNode,
  useCallback,
} from 'react'
import Analytics, { AnalyticsInstance } from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'
import Router from 'next/router'

// Libs
import { UserContext } from '.'

// Deffs
export type AnalyticsFunction = (params: AnalyticsFunctionParams) => void
export type AnalyticsFunctionNoParams = () => void
export type AnalyticsTackGroupCreatedFunction = (params: {
  groupTitle?: string
  groupID?: string
}) => void

export type AnalyticsTrackEventPublishedFunction = (params: {
  eventTitle?: string
  eventId?: string
}) => void

type AnalyticsFunctionParams = {
  eventTitle?: string
  eventId?: string
  groupTitle?: string
  groupID?: string
  userId?: string
  email?: string
  paidType?: '0' | '1'
}

export type AnalyticsTrackExpress = (params: AnalyticsFunctionParams) => void

export type ContextType = {
  trackEventPublished: AnalyticsTrackEventPublishedFunction
  trackAccountRegistration: AnalyticsFunctionNoParams
  trackProfileCreated: AnalyticsFunctionNoParams
  trackGroupCreated: AnalyticsTackGroupCreatedFunction

  express: {
    expressIntentToAttend: AnalyticsTrackExpress
    expressEventsRegisterForEvent: AnalyticsTrackExpress
    expressParticipantFirstTimeCreated: AnalyticsTrackExpress
  }
  onboarding: {
    profileComplete: () => void
    groupCreated: () => void
    paymentSetupSkip: () => void
    groupsAndPaymentsComplete: () => void
    eventCreationSkip: () => void
    eventCreated: () => void
  }
}

// Context.
export const Context = createContext<ContextType>(null)

interface Props {
  gtmId?: string
  children: ReactNode
}

// Provider.
export const Provider: React.FC<Props> = ({ gtmId, children }) => {
  // Global State
  const { currentUser, fetchCurrentUser } = useContext(UserContext)

  // Local State
  const [analytics, setAnalytics] = useState<AnalyticsInstance>(null)

  // On Mount - Client Only
  useEffect(() => {
    try {
      // console.log('Analytics Boot');
      // Init Analytics Connection
      const newAnalytics = Analytics({
        // app: 'our-klyk',
        plugins: [
          googleTagManager({
            containerId: 'GTM-K97DW39', //gtmId
          }),
        ],
      })
      setAnalytics(newAnalytics)

      // Set up  Page Tracking on Router Change
      // Trigger initial page track
      const trackPageChange = () => {
        newAnalytics.page()
      }

      trackPageChange()
      const handleRouteChange = () => trackPageChange()
      Router.events.on('routeChangeComplete', handleRouteChange)
      return () => {
        Router.events.off('routeChangeComplete', handleRouteChange)
      }
    } catch (e) {
      console.log('@Context->On Mount failed with error ', e)
    }
  }, [])

  // Funcs
  //
  // For express Users - ported from analyticsEvent.tsx
  //
  const expressIntentToAttend: AnalyticsTrackExpress = (params) => {
    try {
      if (analytics !== null) {
        analytics.track('ExpressEvents-IntentToAttend', {})
      } else {
        console.log(
          '@Context->expressIntentToAttend analytics not set. Try running function after init. Try running on client and not on SSR',
        )
      }
    } catch (e) {
      console.log('@Context->expressIntentToAttend failed with error ', e)
    }
  }

  const expressEventsRegisterForEvent: AnalyticsTrackExpress = (params) => {
    try {
      if (analytics !== null) {
        analytics.track('Events-RegisterForEvent', {
          email: params?.email, // Required
          eventID: params.eventId, // Required
          userType: 'express',
          paidType: params?.paidType, // Required
        })
      } else {
        console.log(
          '@Context->expressEventsRegisterForEvent analytics not set. Try running function after init. Try running on client and not on SSR',
        )
      }
    } catch (e) {
      console.log(
        '@Context->expressEventsRegisterForEvent failed with error ',
        e,
      )
    }
  }

  const expressParticipantFirstTimeCreated: AnalyticsTrackExpress = (
    params,
  ) => {
    try {
      if (analytics !== null) {
        analytics.track('Express-ParticipantCreated', {
          email: params?.email,
          userType: 'express',
        })
      } else {
        console.log(
          '@Context->expressParticipantFirstTimeCreated analytics not set. Try running function after init. Try running on client and not on SSR',
        )
      }
    } catch (e) {
      console.log(
        '@Context->expressParticipantFirstTimeCreated failed with error ',
        e,
      )
    }
  }

  // Funcs
  //
  // General Events
  //

  // fired when someone creates an event on Klyk
  const trackEventPublished: AnalyticsTrackEventPublishedFunction = (
    params,
  ) => {
    try {
      if (analytics !== null) {
        const nameParts = currentUser?.full_name?.split(' ') || []
        const lastNameSplit =
          currentUser?.full_name?.split(' ')?.slice(1)?.join(' ') || ''

        analytics.track('eventPublished', {
          firstName: nameParts[0] || '',
          lastName: lastNameSplit,
          email: currentUser?.email,
          userID: currentUser?.id,
          eventTitle: params.eventTitle,
          eventID: params.eventId,
        })
      } else {
        console.log(
          '@Context->trackEventPublished analytics not set. Try running function after init. Try running on client and not on SSR',
        )
      }
    } catch (e) {
      console.log('@Context->trackEventPublished failed with error ', e)
    }
  }

  // fired after someone registers for an account
  const trackAccountRegistration = async () => {
    try {
      if (analytics !== null) {
        // Fetch as the state hasn't settled correctly yet.
        // IDK why through - it should have
        const theCurrentUser = await fetchCurrentUser()

        analytics.track('accountRegistration', {
          // We can't set the name because the user has none at this point in time
          email: theCurrentUser?.email,
          userID: theCurrentUser?.id,
        })
      } else {
        console.log(
          '@Context->trackAccountRegistration analytics not set. Try running function after init. Try running on client and not on SSR',
        )
      }
    } catch (e) {
      console.log('@Context->trackAccountRegistration failed with error ', e)
    }
  }

  // fired after someone saves their profile information after creating an account
  const trackProfileCreated = () => {
    try {
      if (analytics !== null) {
        const nameParts = currentUser?.full_name?.split(' ') || []
        const lastNameSplit =
          currentUser?.full_name?.split(' ')?.slice(1)?.join(' ') || ''

        analytics.track('profileCreated', {
          firstName: nameParts[0] || '',
          lastName: lastNameSplit,
          email: currentUser?.email,
          userID: currentUser?.id,
        })
      } else {
        console.log(
          '@Context->trackProfileCreated analytics not set. Try running function after init. Try running on client and not on SSR',
        )
      }
    } catch (e) {
      console.log('@Context->trackProfileCreated failed with error ', e)
    }
  }

  // fired after some creates a group
  const trackGroupCreated: AnalyticsTackGroupCreatedFunction = (params) => {
    try {
      if (analytics !== null) {
        const nameParts = currentUser?.full_name?.split(' ') || []
        const lastNameSplit =
          currentUser?.full_name?.split(' ')?.slice(1)?.join(' ') || ''

        analytics.track('groupCreated', {
          firstName: nameParts[0] || '',
          lastName: lastNameSplit,
          email: currentUser?.email,
          userID: currentUser?.id,
          groupTitle: params.groupTitle,
          groupID: params.groupID,
        })
      } else {
        console.log(
          '@Context->trackGroupCreated analytics not set. Try running function after init. Try running on client and not on SSR',
        )
      }
    } catch (e) {
      console.log('@Context->trackGroupCreated failed with error ', e)
    }
  }

  const onboarding = {
    profileComplete: () => {
      try {
        if (analytics !== null) {
          analytics.track('onboardingProfileComplete')
        } else {
          console.log(
            '@Context->onboardingProfileComplete analytics not set. Try running function after init. Try running on client and not on SSR',
          )
        }
      } catch (e) {
        console.log('@Context->onboardingProfileComplete failed with error ', e)
      }
    },
    groupCreated: () => {
      try {
        if (analytics !== null) {
          analytics.track('onboardingGroupCreated')
        } else {
          console.log(
            '@Context->onboardingGroupCreated analytics not set. Try running function after init. Try running on client and not on SSR',
          )
        }
      } catch (e) {
        console.log('@Context->onboardingGroupCreated failed with error ', e)
      }
    },

    paymentSetupSkip: () => {
      try {
        if (analytics !== null) {
          analytics.track('onboardingPaymentSetupSkip')
        } else {
          console.log(
            '@Context->onboardingPaymentSetupSkip analytics not set. Try running function after init. Try running on client and not on SSR',
          )
        }
      } catch (e) {
        console.log(
          '@Context->onboardingPaymentSetupSkip failed with error ',
          e,
        )
      }
    },
    groupsAndPaymentsComplete: useCallback(() => {
      try {
        if (analytics !== null) {
          analytics.track('onboardingGroupsAndPaymentsComplete')
        } else {
          console.log(
            '@Context->onboardingGroupsAndPaymentsComplete analytics not set. Try running function after init. Try running on client and not on SSR',
          )
        }
      } catch (e) {
        console.log(
          '@Context->onboardingGroupsAndPaymentsComplete failed with error ',
          e,
        )
      }
    }, [analytics]),

    eventCreationSkip: () => {
      try {
        if (analytics !== null) {
          analytics.track('onboardingEventCreationSkip')
        } else {
          console.log(
            '@Context->onboardingEventCreationSkip analytics not set. Try running function after init. Try running on client and not on SSR',
          )
        }
      } catch (e) {
        console.log(
          '@Context->onboardingEventCreationSkip failed with error ',
          e,
        )
      }
    },
    eventCreated: () => {
      try {
        if (analytics !== null) {
          analytics.track('onboardingEventCreated')
        } else {
          console.log(
            '@Context->onboardingEventCreated analytics not set. Try running function after init. Try running on client and not on SSR',
          )
        }
      } catch (e) {
        console.log('@Context->onboardingEventCreated failed with error ', e)
      }
    },
  }

  // Functions.
  const functions = {
    trackEventPublished: trackEventPublished,
    trackAccountRegistration: trackAccountRegistration,
    trackProfileCreated: trackProfileCreated,
    trackGroupCreated: trackGroupCreated,

    express: {
      expressIntentToAttend: expressIntentToAttend,
      expressEventsRegisterForEvent: expressEventsRegisterForEvent,
      expressParticipantFirstTimeCreated: expressParticipantFirstTimeCreated,
    },
    onboarding: onboarding,
  }

  // Values
  const values = {}

  const value = { ...values, ...functions }

  // ..
  return <Context.Provider value={value}>{children || <></>}</Context.Provider>
}
