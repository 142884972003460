import { useTranslation } from 'next-i18next'
// Libs
import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import useScrollPosition from '@react-hook/window-scroll'

import {
  boot as intercomBoot,
  shutdown as intercomShutdown,
} from '../../hooks/intercom'

// Comps
import { Hyperlink } from '../Hyperlink'
import { MyEventsCTA } from '../MyEventsCTA'
import { MobileNavigation } from './MobileNavigation'
import { NavigationBarCTA } from './CTA'
import { UserDropdownMenu } from './UserDropdownMenu'
import { UserContext } from '../../context'
import { InformationBanner } from '../InformationBanner'
// Styles
import { navigationBarStyles } from './navigationBarStyles'

const Nav = styled.nav`
  ${navigationBarStyles}
`

// Navigation Bar.
export const NavigationBar: React.FC = () => {
  const { t } = useTranslation('common')
  // Global State
  const scrollY = useScrollPosition(30) // used to power hide show of the shadow
  const router = useRouter()
  const { currentUser } = useContext(UserContext)
  const { pathname } = router

  // Calc
  const isHomePage = pathname === '/'
  const isLogInPage = pathname === '/login'
  const isSignUpPage = pathname === '/signup'
  const isProfilePage = pathname === '/profile'
  const isAuthPage = isLogInPage || isSignUpPage
  const isEventCreationPage =
    pathname === '/events/create' || pathname === '/events/duplicate/[eventId]'
  const isDashboardEditPage =
    pathname === '/dashboard/setup-profile' ||
    pathname === '/dashboard/create-group' ||
    pathname === '/dashboard/create-event'
  const isGroupCreationPage = pathname === '/creategroup'
  const isMyEvents =
    pathname === '/myevents' ||
    pathname === '/myevents/attending' ||
    pathname === '/myevents/hosting' ||
    pathname == '/myevents/following'

  const [navClass, setNavClass] = useState('')
  useEffect(() => {
    setNavClass('')
    if (isHomePage === true) {
      setNavClass((prevClass) => `${prevClass} light-button background-purple`)
    }
    if (isMyEvents == true) {
      setNavClass((prevClass) => `${prevClass} background-my-events`)
    }
    if (scrollY > 0) {
      setNavClass((prevClass) => `${prevClass} background-with-shadow`)
    }
    if (scrollY > 0 && isMyEvents === true) {
      setNavClass((prevClass) => `${prevClass} background-white`)
    }
  }, [isHomePage, isMyEvents, scrollY])

  // Const
  const links = [
    // { href: '/discover', name: 'Discover' },
    { href: '/about', name: t('About', 'About') },
    { href: '/how-to-earn', name: t('How to Earn', 'How to Earn') },
    { href: '/featured', name: t('Featured', 'Featured') },
    { href: '/all-events', name: t('All Events', 'All Events') },
    { href: 'https://blog.ourklyk.com/', name: t('Blog', 'Blog') },
    // { href: '/safety', name: 'Safety' },
  ]

  useEffect(() => {
    intercomShutdown()
    if (currentUser) {
      intercomBoot({
        name: currentUser.full_name,
        email: currentUser.email,
        user_id: currentUser.id,
        user_hash: currentUser.intercom_hmac,
      })
    } else {
      intercomBoot()
    }
  }, [currentUser])

  return (
    <>
      <InformationBanner></InformationBanner>
      <Nav className={`Nav ${navClass}`}>
        {/* an experiment with having the nav at 1300px max */}
        {/* <div className="box-constraint"> */}

        {/* Logo. */}
        <Hyperlink href="/" style={{ marginRight: 'auto' }}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            className="logo"
            src={
              isHomePage ? '/images/klykWhite.svg' : '/images/klykRegular.svg'
            }
            alt=""
          />
        </Hyperlink>

        {/* Links. */}
        {!isAuthPage &&
          !isEventCreationPage &&
          !isDashboardEditPage &&
          !isProfilePage && (
            <div className="links desktopOnly">
              {/*  */}
              {links.map(({ href, name }) => (
                <Hyperlink
                  className={`buttonLink link ${
                    pathname === href ? 'active' : ''
                  }`}
                  href={href}
                  key={href}>
                  {name}
                </Hyperlink>
              ))}
            </div>
          )}

        {/* Divider. */}
        {!isAuthPage &&
          !isEventCreationPage &&
          !isDashboardEditPage &&
          !isGroupCreationPage &&
          !isProfilePage && <div className="divider desktopOnly" />}

        {/* User Dropdown Menu. */}
        {!isAuthPage &&
          !isEventCreationPage &&
          !isDashboardEditPage &&
          !isGroupCreationPage &&
          !isProfilePage && <UserDropdownMenu className="desktopOnly" />}

        {/* My Events. */}
        {currentUser &&
          !isAuthPage &&
          !isEventCreationPage &&
          !isDashboardEditPage &&
          !isGroupCreationPage &&
          !isProfilePage && <MyEventsCTA className={`desktopOnly`} />}

        {/* CTA. */}
        {!isProfilePage && !isEventCreationPage && !isDashboardEditPage && (
          <NavigationBarCTA className={`desktopOnly`} />
        )}

        {/* Mobile Nav Button */}
        {!isLogInPage &&
          !isSignUpPage &&
          !isEventCreationPage &&
          !isDashboardEditPage &&
          !isGroupCreationPage &&
          !isProfilePage && (
            <MobileNavigation
              links={links}
              isHomePage={isHomePage}
              isLogInPage={isLogInPage}
              isSignUpPage={isSignUpPage}
              isAuthPage={isAuthPage}
              isEventCreationPage={isEventCreationPage}
              isDashboardEditPage={isDashboardEditPage}
              isGroupCreationPage={isGroupCreationPage}
              isProfilePage={isProfilePage}
              mobileOnly={true}
            />
          )}
        {/* </div> */}
      </Nav>
    </>
  )
}
