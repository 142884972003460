import { useTranslation } from 'next-i18next'
// Dependencies.
import { useState, useContext, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Router from 'next/router'

import { UserPane } from './UserPane'
import { Menu } from './Menu'
import { UserContext } from '../../../context'
import { Hyperlink } from '../../Hyperlink'
import { forScreensGreaterThanMobileWidth } from '../../../utils/styles'

// Div.
const Div = styled.div`
  position: relative;

  .userPane {
    margin-right: 5px;
  }

  .arrow {
    width: 14px;
  }
  .buttonLinkLogin {
    ${forScreensGreaterThanMobileWidth(css`
      margin-right: 37px;
    `)}
  }
`

// Props.
interface Props {
  className?: string
  closeNav?: () => void
}

// User Dropdown Menu.
export const UserDropdownMenu: React.FC<Props> = ({ className, closeNav }) => {
  const { t } = useTranslation('common')

  // Menu Ref — Ref For Area That.
  const menuRef = useRef<HTMLDivElement>(null)

  // User.
  const { currentUser } = useContext(UserContext)

  // State.
  const [showMenu, setShowMenu] = useState(false)

  // Did Mount.
  useEffect(() => {
    // On Route Change Start.
    Router.events.on('routeChangeStart', () => {
      if (showMenu) setShowMenu(false)
    })

    // On Click (Anywhere).
    const onClick = (e: MouseEvent) => {
      const menuOrDescendantWasClicked =
        e.target === menuRef.current ||
        (menuRef.current && menuRef.current.contains(e.target as Node))
      if (!menuOrDescendantWasClicked) setShowMenu(false)
    }

    // Add Event Listener — Click.
    window && window.addEventListener('click', onClick)

    // Tear Down.
    return () => window && window.removeEventListener('click', onClick)
  }, [])

  // Current User.
  if (currentUser)
    return (
      <Div className={`noSelect userPaneDropdownMenu ${className || ''}`}>
        <div
          onClick={() => setShowMenu((currentState) => !currentState)}
          ref={menuRef}
          style={{
            alignItems: 'center',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
          }}
          data-cy="userDropdownMenu">
          {/* User Pane. */}
          <UserPane image={currentUser.image} />

          {/* Arrow. */}
          <img className="arrow" src="/images/downArrow.png" />
        </div>

        {/* Menu. */}
        {showMenu && <Menu closeNav={closeNav} />}
      </Div>
    )

  // !User.
  return (
    <Hyperlink
      className={`buttonLink ${className || ''} buttonLinkLogin`}
      href="/login">
      {t(`Log in`, `Log in`)}
    </Hyperlink>
  )
}
