import { useRouter } from 'next/router'

import { EPaymentProvider } from '../entities'

const defaultIsXenditEnabled = true

export function usePaymentProvider() {
  const { locale } = useRouter()

  // TODO xendit for indonesia feature turned off here
  const isXenditEnabled = false
  // const isXenditEnabled =
    // typeof process.env.NEXT_PUBLIC_XENDIT === 'undefined'
    //   ? defaultIsXenditEnabled
    //   : Boolean(Number(process.env.NEXT_PUBLIC_XENDIT))

  let provider = EPaymentProvider.stripe

  if (isXenditEnabled) {
    provider =
      locale === 'en'
        ? EPaymentProvider.stripe
        : locale === 'id'
        ? EPaymentProvider.xendit
        : null
  }

  if (provider === null) {
    throw new Error(
      `Unsupported payment provider. Details: { env: ${process.env.NEXT_PUBLIC_XENDIT}, isXenditEnabled:${isXenditEnabled}}, locale: ${locale}`,
    )
  }

  return {
    provider,
  }
}
