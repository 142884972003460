let isPixelInited = false

// Use Facebook Pixel.
export function useFacebookPixel() {
  if (typeof window === `undefined`) return

  const pixelId = '3336665056413929'

  const initPixel = () => {
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js',
    )
    window.fbq('init', pixelId)
    window.fbq('track', 'PageView')
    isPixelInited = true
  }

  const pageView = () => {
    window.fbq('track', 'PageView')
  }

  const track = (event) => {
    if (event.props) {
      window.fbq('track', event.name, event.props)
    } else {
      window.fbq('track', event.name)
    }
  }

  if (!isPixelInited) initPixel()

  const functions = { track, pageView }
  const variables = {}

  return { ...variables, ...functions }
}
