// Dependencies.
import styled from 'styled-components'
import { useTranslation } from 'next-i18next'

import { CTA } from './CTA'
import { Hyperlink } from './Hyperlink'
import { useAnalyticsEvent } from '../hooks/analyticsEvent'

// Props.
interface Props {
  className?: string
  purpleBackground?: boolean
  userLoggedIn?: boolean
  closeNav?: () => void
}

// Log In CTA.
export const CreateEventCTA: React.FC<Props> = (props) => {
  const { t } = useTranslation('common')
  const { className, purpleBackground, userLoggedIn, closeNav } = props

  const analyticsEvent = useAnalyticsEvent()

  const onCreateEventClicked = () => {
    analyticsEvent.createEventClicksCTA('header-cta')
    if (closeNav) closeNav()
  }

  // ..
  return (
    <Hyperlink
      className={className}
      href="/events/create"
      onClick={() => {
        onCreateEventClicked()
      }}>
      <CTA className={`createEventCTA ${purpleBackground ? 'purple' : ''}`}>
        {userLoggedIn
          ? t('Create event', 'Create event')
          : t('Create your Klyk', 'Create your Klyk')}
      </CTA>
    </Hyperlink>
  )
}
