import { useTranslation } from 'next-i18next'
import styled, { css } from 'styled-components'
import { useRouter } from 'next/router'

import { Hyperlink } from '../Hyperlink'
import {
  config,
  forScreensGreaterThanMobileWidth,
  show,
  showFinally,
} from '../../utils'

// Div.
const Div = styled.div`
  display: flex;
  width: 100%;
  max-width: 316px;
  flex-wrap: wrap;

  .buttonLink {
    text-align: center;
    margin-bottom: 1em;
    width: 50%;
  }

  /* Desktop. */
  ${forScreensGreaterThanMobileWidth(css`
    .buttonLink {
      text-align: left;
    }
  `)}
`

// Props.
interface Props {}

// Site Links.
export const SiteLinks: React.FC<Props> = (props) => {
  const { t } = useTranslation('common')
  const { route } = useRouter()

  const linksAsColumns = [
    [
      { href: '/about', label: t('About', 'About'), key: 'C11' },
      { href: '/featured', label: t('Featured', 'Featured'), key: 'C12' },
      {
        href: 'https://blog.ourklyk.com/',
        label: t('Blog', 'Blog'),
        key: 'C13',
      },
      { href: '/safety', label: t('Safety', 'Safety'), key: 'C14' },
    ],
    [
      {
        href: '/privacy',
        label: t('Privacy Policy', 'Privacy Policy'),
        key: 'C21',
      },
      {
        href: '/terms',
        label: t('Terms & Conditions', 'Terms & Conditions'),
        key: 'C22',
      },
      {
        href: config.intercomSupportLink,
        label: t('Support', 'Support'),
        key: 'C23',
      },
    ],
  ]
  // Intercom link - removed to stop people asking dumb questions
  // or pre-sales questions
  // {href: config.intercomSupportLink, label: 'Support'},

  const column1 = linksAsColumns[0]
  const column2 = linksAsColumns[1]

  const linksAsList = [
    column1[0],
    column2[0],
    column1[1],
    column2[1],
    column1[2],
    column2[2],
    column1[3],
  ]

  // ..
  return (
    <Div>
      {/* Links. */}
      {linksAsList.map(({ href, label, key }, index) => {
        if (href !== null) {
          return (
            <Hyperlink
              className={`buttonLink ${route === href ? 'active' : ''}`}
              href={href}
              key={key}>
              {label}
            </Hyperlink>
          )
        } else {
          return <div className="buttonLink" key={key} />
        }
      })}
    </Div>
  )
}
