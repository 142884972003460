// Dependencies.
import styled from 'styled-components'

import { colors, fontWeights } from '../utils'

// P.
export const P = styled.p`
  color: ${colors.offBlack};
  font-size: 17px;
  font-weight: normal;
  line-height: 150%;
  letter-spacing: -0.6px;
  margin: 0;

  &.callout {
    color: ${colors.offBlack};
    font-size: 16px;
    font-weight: ${fontWeights.semiBold};
    letter-spacing: -0.5px;
    line-height: 110%;
  }

  &.detail {
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 140%;
  }

  &.warning {
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 140%;
    color: ${colors.red};
  }

  &.large-explanation {
    font-size: 26px;
  }

  a {
    color: ${colors.purple};
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`
