import { EEventMonthlyFrequency, EventFrequency, Weekday } from '../../entities'
import { NookEvent, MonthlyRecurringEventPayload } from '../../types'

export function addMonthlyRecurringDetails(
  event: Partial<NookEvent>,
): Partial<NookEvent> & MonthlyRecurringEventPayload {
  return {
    ...event,
    interval: 1,
    frequency: EventFrequency.MONTHLY,
    is_recurring: true,
    by_week_days: [Weekday.MONDAY],
    month_week_nos: [EEventMonthlyFrequency.FIRST],
  }
}
