// Dependencies.
import styled from 'styled-components'

import { colors, fontWeights, alphaHex } from '../utils'

// CTA.
export const CTA = styled.button`
  background: ${colors.washedPurple};
  border: 2px solid transparent;
  border-radius: 50px;
  box-sizing: border-box;
  color: ${colors.purple};
  cursor: pointer;
  font-weight: ${fontWeights.semiBold};
  font-size: 16px;
  height: 45px;
  letter-spacing: -0.5px;
  line-height: 110%;
  outline: none;
  text-align: center;

  &.inline-pill {
    padding: 0px;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 65px;
    margin-left: 20px;
    height: 24px;
    font-size: 12px;
    font-weight: ${fontWeights.medium};
  }

  &:disabled {
    cursor: auto;
    opacity: 0.7;

    &.disabled-hidden {
      visibility: hidden;
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.purple {
    background: ${colors.purple};
    color: ${colors.white};
  }

  &.green {
    background: ${colors.limeGreen};
    color: ${colors.white};
  }

  &.light-gray {
    background: ${colors.lightGray};
    color: ${colors.veryDarkGray};
  }

  &.gray {
    background: ${colors.darkGray};
    color: ${colors.white};
  }

  &.red {
    background: ${colors.red};
    color: ${colors.white};
  }

  &.clear {
    background: transparent;
  }

  &.clear-black {
    background: transparent;
    color: ${colors.black};
  }

  &.outline-clear {
    border-color: transparent;
    color: ${colors.black};
  }

  &.outline-clear-grey {
    border-color: transparent;
    color: ${alphaHex(colors.black, 0.5)};
  }

  &.outline-very-dark-grey {
    background: transparent;
    border-color: ${colors.veryDarkGray};
  }

  &.outline-very-dark-grey {
    background: transparent;
    border-color: ${colors.veryDarkGray};
  }

  &.outline-very-dark-grey-02 {
    background: transparent;
    border-color: ${colors.veryDarkGrayOpacity20};
  }

  &.white-dirty {
    background: ${colors.white};
    color: ${colors.darkGray};
  }

  &.outline-dark {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.3);
    color: ${colors.white};
  }

  &.outline-light {
    background: transparent;
    border-color: ${colors.washedPurple};
    color: ${colors.purple};
  }

  &.outline-purple {
    background: transparent;
    border-color: ${colors.purple};
    color: ${colors.purple};
  }

  &.outline-purple-light {
    background: '#F0EDFD';
    color: ${colors.purple};
  }

  &.outline-light-grey {
    border-color: ${colors.lightGray};
  }

  &.outline-white {
    background: transparent;
    border-color: white;
    color: white;
  }

  &.outline-red {
    background: transparent;
    border-color: ${colors.red};
    color: ${colors.red};
  }

  img {
    height: 1em;
    margin-right: 0.5em;
    vertical-align: bottom;
  }

  svg {
    height: 1em;
    margin-right: 0.5em;
    vertical-align: bottom;
  }

  &.fixed-min-width {
    min-width: 115px;
    text-align: center;
  }

  &.extra-padding {
    padding-left: 45px;
    padding-right: 45px;
  }

  &.extra-padding-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  &.extra-padding-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  &.extra-padding-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.no-left-right-padding {
    padding-left: 0px;
    padding-right: 0px;
  }

  &.with-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      height: 1em;
      width: auto;
    }
    &.centered {
      justify-content: center;
    }
  }

  &.social-login {
    width: 100%;
    background: rgb(240, 237, 253);
    border: 2px solid transparent;
    border-radius: 50px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    height: 45px;
    letter-spacing: -0.5px;
    line-height: 110%;
    outline: none;
    padding: 11px 14px 12px;
    text-align: center;
    align-items: center;
    color: rgb(43, 43, 43);
    display: flex;
    justify-content: center;
    width: 100%;

    img {
      margin-right: 12px;
      margin-top: -1em;
      margin-bottom: -1em;
      height: 2em;
    }
  }

  &.modal-cta {
    padding-top: 9px;
    padding-bottom: 11px;
    padding-left: 28px;
    padding-right: 28px;
  }

  &.camera-button {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 50px;
    height: 50px;

    svg {
      height: unset;
      margin-right: unset;
      vertical-align: unset;
    }
  }

  &.block {
    display: block !important;
  }

  &.inline {
    display: inline !important;
  }

  /*  For situations where it needs to be inline with text and match styles */
  &.contents {
    padding: 0px !important;
    margin: 0px !important;
    display: contents !important;
    font-size: inherit !important;
    letter-spacing: inherit !important;
    color: inherit !important;
    font-weight: inherit !important;
  }

  &.anchor {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &.anchor-obvious {
    text-decoration: underline;
  }
`
