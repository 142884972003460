// FetchInvitedEvents, FetchAttendingEvents,
// Dependencies.
import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react'
import { useAsyncSetState } from 'use-async-setstate'

import {
  Group,
  FetchGroupFunc,
  EditGroupFunc,
  FollowGroupFunc,
  UnFollowGroupFunc,
  FetchGroupBySlugFunc,
  CreateGroupFunc,
  UploadImageFunc,
  InsertGroupFunc,
  DeleteGroupFunc,
  FetchGroupEventsFunc,
  NookEvent,
  FetchEventsResult,
  GetGroupSlugResponse,
  GetGroupSlugSuggestionFunc,
  CreateGroupStripeAccountFunc,
  CreateGroupStripeAccountParams,
  MyGroup,
  FetchMyGroupFunc,
  EventsList,
  FetchEventListPaginatedFunc,
} from '../types'
import { API } from '../API'
import { prependHttpsToUrl } from '../utils'

export const Context = createContext({
  // Vars
  myGroup: {} as MyGroup,
  group: {} as Group,
  groupEvents: [] as NookEvent[],
  attendingEvents: {
    count: 0,
    next: null,
    results: [],
  },
  invitedEvents: {
    count: 0,
    next: null,
    results: [],
  },

  // Funcs
  fetchGroup: (async () => ({} as Group)) as FetchGroupFunc,
  fetchGroupBySlug: (async () => ({} as Group)) as FetchGroupBySlugFunc,
  followGroup: (async () => ({} as Group)) as FollowGroupFunc,
  unFollowGroup: (async () => ({} as Group)) as UnFollowGroupFunc,
  fetchGroupEvents: (async () => ({} as NookEvent[])) as FetchGroupEventsFunc,
  createGroup: (async (params) => params) as CreateGroupFunc,
  getSlugSuggestion: (async () => ({} as string)) as GetGroupSlugSuggestionFunc,
  editGroup: (async (params) => params) as EditGroupFunc,
  deleteGroup: (async (params) => ({})) as DeleteGroupFunc,
  uploadHeroToGroup: (async () => {}) as UploadImageFunc,
  uploadAvatarToGroup: (async () => {}) as UploadImageFunc,
  createStripeAccount: (async (params) =>
    ({} as Group)) as CreateGroupStripeAccountFunc,
  fetchMyGroup: (async () => ({} as MyGroup)) as FetchMyGroupFunc,
  fetchAttendingEvents: (async () => ({})) as FetchEventListPaginatedFunc,
  fetchInvitedEvents: (async () => ({})) as FetchEventListPaginatedFunc,
})

// Provider.
export const Provider: React.FC = ({ children }) => {
  // const [groups, setGroups] = useState<Group[]>([])
  const [group, setGroup] = useAsyncSetState<Group>(undefined)
  const [groupEvents, setGroupEvents] = useAsyncSetState<NookEvent[]>([])
  const [myGroup, setMyGroup] = useAsyncSetState<MyGroup>(undefined)
  const [attendingEvents, setAttendingEvents] =
    useAsyncSetState<EventsList>(undefined)
  const [invitedEvents, setInvitedEvents] =
    useAsyncSetState<EventsList>(undefined)

  // Fetch Events
  const fetchAttendingEvents: FetchEventListPaginatedFunc = async (
    page?: number,
  ) => {
    const response = await API.get<EventsList>(
      `/attendingevents/?page=${page || 1}`,
    )
    setAttendingEvents(response.data)
    return response.data
  }

  const fetchInvitedEvents: FetchEventListPaginatedFunc = async () => {
    const response = await API.get<EventsList>(`/invitedevents/`)
    setInvitedEvents(response.data)
    return response.data
  }

  // fetch group
  const fetchGroup: FetchGroupFunc = async (id) => {
    const path = `/groups/${id}/`

    const response = await API.get<Group>(path)
    setGroup(response.data)
    return response.data
  }

  // fetch my group
  const fetchMyGroup: FetchGroupFunc = async (id) => {
    const path = `/mygroup/${id}/`

    const response = await API.get<MyGroup>(path)
    setMyGroup(response.data)
    return response.data
  }

  const fetchGroupBySlug: FetchGroupBySlugFunc = async (slug) => {
    if (slug) {
      const response = await API.get<Group>(`/groups/${slug}/`)
      const group = response.data
      setGroup(group)
      return group
    }
  }

  // follow group
  const followGroup: FollowGroupFunc = useCallback(
    async (id) => {
      const path = `/groups/${id}/follow/`

      const response = await API.post<Group>(path, {})
      const group = response.data
      setGroup(group)
      return group
    },
    [setGroup],
  )
  // unfollow group
  const unFollowGroup: UnFollowGroupFunc = useCallback(
    async (id) => {
      const path = `/groups/${id}/follow/`

      const response = await API.delete<Group>(path, {})
      const group = response.data
      setGroup(group)
      return group
    },
    [setGroup],
  )
  // delete group
  const deleteGroup: DeleteGroupFunc = async (id) => {
    const path = `/mygroup/${id}/`

    try {
      const response = await API.delete(path, {})
      return true
    } catch {
      return false
    }
  }

  // Upload Hero To Group.
  const uploadHeroToGroup: UploadImageFunc = async (id, image) => {
    if (!id) return
    if (!image) return
    const formData = new FormData()
    formData.append('image', image)

    // Response.
    const response = await API.postFormData<MyGroup>(
      `/groups/${id}/hero/`,
      formData,
    )
    const group = response.data
    setMyGroup(group)
  }

  // Upload Avatar To Group.
  const uploadAvatarToGroup: UploadImageFunc = async (id, image) => {
    if (!id) return
    if (!image) return
    const formData = new FormData()
    formData.append('image', image)

    // Response.
    const response = await API.postFormData<MyGroup>(
      `/groups/${id}/avatar/`,
      formData,
    )
    const group = response.data
    setMyGroup(group)
  }

  // create group
  const createGroup: CreateGroupFunc = async (params) => {
    //params.hero = null // TO DO - we need to make another call to upload the image once we receive event id from backend
    //params.avatar = null // TO DO - we need to make another call to upload the image once we receive event id from backend
    //const formData = convertToFormData(params);
    delete params.hero
    delete params.avatar

    // prepend schema for group web if it's missing
    if (params.website && params.website !== '') {
      params.website = prependHttpsToUrl(params.website)
    }

    const response = await API.post<MyGroup>('/groups/', params)
    // Response.
    const group = response.data
    const { id } = group
    if (id) {
      setMyGroup(group)

      if (params.heroFile) {
        await uploadHeroToGroup(id, params.heroFile)
      }

      if (params.avatarFile) {
        await uploadAvatarToGroup(id, params.avatarFile)
      }
    }
    return group
  }
  // Edit group
  const editGroup: EditGroupFunc = async (params) => {
    //params.hero = null // TO DO - we need to make another call to upload the image once we receive event id from backend
    //params.avatar = null // TO DO - we need to make another call to upload the image once we receive event id from backend
    //const formData = convertToFormData(params);
    delete params.hero
    delete params.avatar

    // prepend schema for group web if it's missing
    params.website = prependHttpsToUrl(params.website)

    // Response.
    const response = await API.put<MyGroup>(`/mygroup/${params.id}/`, params)
    const group = response.data
    const { id } = group
    if (id) {
      setMyGroup(group)

      if (params.heroFile) {
        await uploadHeroToGroup(id, params.heroFile)
      }

      if (params.avatarFile) {
        await uploadAvatarToGroup(id, params.avatarFile)
      }
    }
    return group
  }

  // fetch group event
  const fetchGroupEvents: FetchGroupEventsFunc = async (id) => {
    if (id) {
      const response = await API.get<FetchEventsResult>(`/groups/${id}/events/`)
      const events = response.data.results
      setGroupEvents(events)
      return events
    }
  }

  const getSlugSuggestion: GetGroupSlugSuggestionFunc = async (name) => {
    if (name) {
      const response = await API.post<GetGroupSlugResponse>(
        `/groups/slug-suggestion`,
        { name: name },
      )
      const { slug } = response.data
      return slug
    }
    return ''
  }

  const createStripeAccount: CreateGroupStripeAccountFunc = useCallback(
    async (params: CreateGroupStripeAccountParams) => {
      if (!params.authorizationCode || !params.groupId) return null
      const url = `/stripe/oauth/${params.groupId}/?code=${params.authorizationCode}`
      const response = await API.get<Group>(url).catch((error) => {
        console.log('error')
      })
      if (response) {
        const { data } = response
        setGroup(data)
        return data
      }
      return null
    },
    [setGroup],
  )

  const variables = {
    group,
    groupEvents,
    myGroup,
    attendingEvents,
    invitedEvents,
  }

  const functions = {
    fetchGroup,
    fetchGroupBySlug,
    followGroup,
    unFollowGroup,
    createGroup,
    fetchGroupEvents,
    getSlugSuggestion,
    editGroup,
    deleteGroup,
    uploadAvatarToGroup,
    uploadHeroToGroup,
    createStripeAccount,
    fetchMyGroup,
    fetchAttendingEvents,
    fetchInvitedEvents,
  }

  const value = { ...variables, ...functions }

  return <Context.Provider value={value}>{children}</Context.Provider>
}
