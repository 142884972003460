import { useTranslation } from 'next-i18next'
// Dependencies.
import styled, { css } from 'styled-components'

import { CTA as BaseCTA } from './CTA'
import { Hyperlink } from './Hyperlink'
import { mobileScreen, forScreensGreaterThanMobileWidth } from '../utils'

// Props.
interface Props {}

const CTA = styled(BaseCTA)`
  ${mobileScreen(css`
    background: transparent;
    padding-left: 0px;
    padding-right: 0px;
  `)}
  ${forScreensGreaterThanMobileWidth(css`
    padding-left: 40px;
    padding-right: 40px;
  `)}
`
// Log In CTA.
export const LogInCTA: React.FC<Props> = (props) => {
  const { t } = useTranslation('common')
  // ..
  return (
    <Hyperlink href="/login">
      <CTA>{t(`Log in`, `Log in`)}</CTA>
    </Hyperlink>
  )
}
