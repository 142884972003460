// Libs
import styled, { css } from 'styled-components'

// Comps
import { forScreensGreaterThanMobileWidth } from '../../utils/styles'
import { colors } from '../../utils'

// Nav.
export const navigationBarStyles = css`
  position: sticky;
  /* position: absolute; */
  top: 0px;
  left: 0;
  right: 0;
  height: 80px;

  width: 100%;
  /* max-width: 1300px; */
  /* overflow: hidden; */

  margin-left: auto;
  margin-right: auto;

  padding: 0 20px;
  padding-bottom: 0;

  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  z-index: 3;
  margin-bottom: -80px;
  background: ${colors.white};
  transition: box-shadow 0.3s;

  /* .box-constraint {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: auto;
    width: 100%;
    max-width: calc(1300px - 60px)
  } */

  &.background-purple {
    background: ${colors.purple};
  }

  &.background-my-events {
    background: linear-gradient(180deg, #fefefe, #fafafe 100%);
  }

  &.background-with-shadow {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  }

  &.background-white {
    background: ${colors.white};
  }

  /* Desktop. */
  ${forScreensGreaterThanMobileWidth(css`
    height: 100px;
    padding: 0 38px;
    margin-bottom: -100px;
    .mobileOnly {
      display: none;
    }
  `)}

  .links .link {
    margin-right: 30px;
  }

  .link {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.5px;
  }

  .divider {
    background: #f1f2f5;
    height: 40px;
    margin-right: 30px;
    opacity: 0.5;
    width: 1px;
  }

  .userPaneDropdownMenu {
    margin-right: 20px;
  }

  &.light-button .link,
  &.light-button .buttonLink {
    color: ${colors.white};
  }

  &.light-button .createEventCTA {
    background: ${colors.white};
    color: ${colors.purple};
  }
`
