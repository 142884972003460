// Dependencies.
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'

import { CTA } from './CTA'
import { Hyperlink } from './Hyperlink'
import { useAnalyticsEvent } from '../hooks/analyticsEvent'

// Props.
interface Props {
  className?: string
  buttonText?: string
}

// Sign Up CTA.
export const SignUpCTA: React.FC<Props> = (props) => {
  const { t } = useTranslation('common')
  const { className, buttonText } = props

  const analyticsEvent = useAnalyticsEvent()

  // ..
  return (
    <Hyperlink
      href="/signup"
      onClick={() => {
        analyticsEvent.signupLinkClicked('login-form')
      }}>
      <CTA className={className}>
        {buttonText ? buttonText : t('Sign up', 'Sign up')}
      </CTA>
    </Hyperlink>
  )
}
