// Dependencies.
import styled from 'styled-components'

import { Link } from '../Link'
import { colors } from '../../../utils'

// Div.
const Div = styled.div`
  align-items: center;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin: 13px 10px;
  padding: 8px 10px;
  width: 180px;

  &:hover {
    background: #f0edfd;

    ${Link} {
      color: ${colors.purple} !important;
    }

    svg path {
      stroke: ${colors.purple};
    }
  }
`

const IconDiv = styled.div`
  display: flex;
  margin-right: 9px;
`

// Props.
interface Props {
  Icon: React.ReactNode
  iconURL?: string
  label: string
  onClick?: () => void
}

// ..
export const MenuItem: React.FC<Props> = (props) => {
  // Props.
  const { Icon, iconURL, label, onClick } = props

  // ..
  return (
    <Div onClick={onClick}>
      {/* Icon. */}
      <IconDiv>{Icon}</IconDiv>

      {/* Text. */}
      <Link>{label}</Link>
    </Div>
  )
}
