import { AmplitudeClient } from 'amplitude-js'

import { UtmParas } from '../types'

const Amplitude = typeof window !== `undefined` ? require('amplitude-js') : null

const amplitudeInstance: AmplitudeClient = Amplitude
  ? Amplitude.getInstance()
  : null

if (amplitudeInstance !== null) {
  const apiKey =
    process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY ||
    '9082fe869c35728bc9dee03a16ffd0e4'

  amplitudeInstance.init(apiKey, null, {
    logLevel: process.env.NODE_ENV === 'production' ? 'DISABLE' : 'WARN',
    includeGclid: true,
    includeReferrer: true, // https://help.amplitude.com/hc/en-us/articles/215131888#track-referrers
    includeUtm: true,
  })
}

const setUserId = (userId: string) => {
  if (amplitudeInstance !== null) {
    amplitudeInstance.setUserId(userId)
  } else {
    console.warn('amplitude is not setup')
  }
}

const setUserType = (type: string) => {
  if (amplitudeInstance !== null) {
    const identify = new Amplitude.Identify().setOnce('type', type)
    amplitudeInstance.identify(identify)
  } else {
    console.warn('amplitude is not setup')
  }
}

export interface AmplitudeEvent {
  name: string
  props?: object
}
const sendEvent = (event: AmplitudeEvent) => {
  if (amplitudeInstance !== null) {
    amplitudeInstance.logEvent(event.name, event.props, new Date().getTime())
  } else {
    console.warn('amplitude is not setup')
  }
}

const sendUtm = (utm: UtmParas) => {
  if (amplitudeInstance !== null) {
    amplitudeInstance.setUserProperties(utm)
  } else {
    console.warn('amplitude is not setup')
  }
}

const getAmplitudeInstance = (): AmplitudeClient | null => {
  if (amplitudeInstance !== null) {
    return amplitudeInstance
  } else {
    console.warn('amplitude is not setup')

    return null
  }
}

export const amplitudeApi = {
  getAmplitudeInstance,
  setUserId,
  sendEvent,
  setUserType,
  sendUtm,
}
