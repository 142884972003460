// Dependencies.
import { createContext, useState } from 'react'

import { API } from '../API'
import {
  PaymentIntent,
  CreatePaymentIntentFunc,
  CreatePaymentIntentParams,
  ExpressPaymentIntentFunc,
  ExpressCreatePaymentIntentParams,
} from '../types'

export const Context = createContext({
  paymentIntents: [] as PaymentIntent[],

  createPaymentIntent: (async () =>
    ({} as PaymentIntent)) as CreatePaymentIntentFunc,
  expressCreatePaymentIntent: (async () =>
    ({} as PaymentIntent)) as ExpressPaymentIntentFunc,
})

// Provider.
export const Provider: React.FC = ({ children }) => {
  const [paymentIntents, setPaymentIntents] = useState<PaymentIntent[]>([])

  // Update PaymentIntents State
  const updatePaymentIntents = (pi: PaymentIntent) => {
    const index = paymentIntents.findIndex(
      (pi) => pi.payment_intent_id === pi.payment_intent_id,
    )
    const currentPIs = paymentIntents
    if (index === -1) {
      currentPIs.push(pi)
    } else {
      currentPIs[index] = pi
    }
    setPaymentIntents(currentPIs)
  }
  // Create PaymentIntent
  const createPaymentIntent: CreatePaymentIntentFunc = async (
    params: CreatePaymentIntentParams,
  ) => {
    const url = `/stripe/event/${params.event_id}/charge/${params.group_id}/`
    const response = await API.post<PaymentIntent>(url, {}).catch((error) => {
      console.log('error')
      return null
    })
    const newIntent: PaymentIntent = response.data
    if (newIntent) updatePaymentIntents(newIntent)
    return newIntent
  }

  const expressCreatePaymentIntent: CreatePaymentIntentFunc = async (
    params: ExpressCreatePaymentIntentParams,
  ) => {
    const url = `/express/stripe/event/${params.event_id}/charge/${params.group_id}/`
    const response = await API.unAuthPost<PaymentIntent>(url, {
      email: params.email,
    }).catch((error) => {
      console.log('error')
      return null
    })
    const newIntent: PaymentIntent = response.data
    // if(newIntent) updatePaymentIntents(newIntent)
    return newIntent
  }

  const variables = { paymentIntents }

  const functions = {
    createPaymentIntent,
    expressCreatePaymentIntent,
  }

  const value = { ...variables, ...functions }

  return <Context.Provider value={value}>{children}</Context.Provider>
}
