// Globals.
const USERTOKEN = 'userToken'
const DRAFTEVENT = 'draftEvent'
const AUTHTYPE = 'authType'

const removeItemFromLocalStorage = (key: string) => {
  try {
    if (!window) return
    window.localStorage.removeItem(key)
  } catch (error) {
    console.error('localStorage.removeItem', error)
  }
}

// Clear Local Storage.
const clearLocalStorage = () => {
  try {
    if (!window) return
    window.localStorage.clear()
  } catch (error) {
    console.error('localStorage.clear', error)
  }
}

// Commit Item To Local Storage.
const commitItemToLocalStorage = (key: string, value: string): void => {
  try {
    if (!window || !window.localStorage) return undefined
    if (!navigator.cookieEnabled) return undefined
    window.localStorage.setItem(key, value)
  } catch (error) {
    // console.error('localStorage.setItem:', error)
    return undefined
  }
}

// Retrieve Item From Local Storage.
const retrieveItemFromLocalStorage = (key: string): string | undefined => {
  try {
    if (!window || !window.localStorage) return undefined
    if (!navigator.cookieEnabled) return undefined
    const value = window.localStorage.getItem(key)
    return value
  } catch (error) {
    // console.error('localStorage.getItem:', error)
    return undefined
  }
}

// Local Storage.
export class LOCALSTORAGE {
  // Set Item.
  static setItem = commitItemToLocalStorage

  // Clear.
  static clear = clearLocalStorage

  // Get Item.
  static getItem = retrieveItemFromLocalStorage

  // Get User Token.
  static getUserToken = () => retrieveItemFromLocalStorage(USERTOKEN)

  // Set User Token.
  static setUserToken = (token: string) =>
    commitItemToLocalStorage(USERTOKEN, token)

  // Set Draft Event.
  static setDraftEvent = (event: string) =>
    commitItemToLocalStorage(DRAFTEVENT, event)

  // Get Draft Event.
  static getDraftEvent = () => retrieveItemFromLocalStorage(DRAFTEVENT)

  //Remove Draft Event.
  static removeDraftEvent = () => removeItemFromLocalStorage(DRAFTEVENT)

  // Set Auth Type
  static setAuthType = (type: string) =>
    commitItemToLocalStorage(AUTHTYPE, type)

  // Get Auth Type
  static getAuthType = () => retrieveItemFromLocalStorage(AUTHTYPE)

  // Remove Auth Type
  static removeAuthType = () => removeItemFromLocalStorage(AUTHTYPE)
}
