import { EventFrequency } from '../../entities'
import { NookEvent } from '../../types'

export function cleanRecurringDetails(
  event: Partial<NookEvent>,
): Partial<NookEvent> {
  const eventClone = { ...event }

  if (eventClone.frequency === EventFrequency.WEEKLY) {
    delete eventClone.by_week_days
  }
  if (eventClone.frequency === EventFrequency.MONTHLY) {
    delete eventClone.month_week_nos
    delete eventClone.by_week_days
  }
  delete eventClone.until
  delete eventClone.interval
  delete eventClone.frequency

  return eventClone
}
