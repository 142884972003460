import { EEventMonthlyFrequency, EventFrequency, Weekday } from '../../entities'
import { DailyRecurringEventPayload, NookEvent } from '../../types'

export function addDailyRecurringDetails(
  event: Partial<NookEvent>,
): Partial<NookEvent> & DailyRecurringEventPayload {
  return {
    ...event,
    interval: 1,
    frequency: EventFrequency.DAILY,
    is_recurring: true,
  }
}
