// Dependencies.
import { createContext, useState, useCallback, useMemo } from 'react'

import { Toast, AddToastFunc, RemoveToastFunc } from '../types'

// Context.
export const Context = createContext({
  // Values.
  toasts: [] as Toast[],
  addToast: (() => ({} as boolean)) as AddToastFunc,
  removeToast: (() => ({} as boolean)) as RemoveToastFunc,
})

// Provider.
export const Provider: React.FC = (props) => {
  const [toasts, setToasts] = useState<Toast[]>([])

  const addToast = useCallback((toast) => {
    setToasts((toasts) => [...toasts, toast])
  }, [])

  const removeToast = useCallback((id) => {
    setToasts((toasts) => toasts.filter((t) => t.id !== id))
  }, [])

  const value = useMemo(
    () => ({
      toasts,
      addToast,
      removeToast,
    }),
    [addToast, removeToast, toasts],
  )

  // ..
  return <Context.Provider value={value}>{props.children}</Context.Provider>
}
